import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';

class WiFiModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: '',
      password: ''
    };
  }

  clearState() {
    this.setState({
      network: '',
      password: ''
    });
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async generateConfig() {
    const settings = `ctrl_interface=DIR=/var/run/wpa_supplicant GROUP=netdev\nupdate_config=1\ncountry=US\n\n`;
    const fileText =
      settings +
      `network={\n\tssid="${this.state.network}"\n\tpsk="${this.state.password}"\n\tscan_ssid=1\n}\n`;

    this.download('wifi.conf', fileText);
  }

  render() {
    return (
      <div id="wifiModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Generate WiFi Config</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Network Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.network}
                  placeholder="Name"
                  onChange={(e) => this.setState({ network: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Password
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.password}
                  placeholder="Password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.generateConfig();
                }}
                disabled={!(this.state.network !== '' && this.state.password !== '')}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(WiFiModal);
