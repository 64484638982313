import React from 'react';
import logo from '../../images/small-black-.png';
import {
  initializeLogin,
  updateUser,
  updatePass,
  updateErrorMessage
} from '../../actions/loginActions';
import { login } from '../../actions/authActions';
// import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './login.css';
import Page from '../utils/page/page.jsx';
import classNames from 'classnames';
import { systemAuth } from '../../apis/backend';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.props.initializeLogin();
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  async login() {
    await systemAuth
      .post('/login', {
        username: this.props.username,
        password: this.props.password
      })
      .then((res) => {
        try {
          this.props.login(res.data);
        } catch (error) {
          this.props.updateErrorMessage('Sorry, it appears you are not connected to the internet.');
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.updateErrorMessage('Sorry, that login is not correct.');
      });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  render() {
    return (
      <Page>
        <div className="login-container">
          <div className="login">
            <div className="bay-logo">
              <img src={logo} alt="The Bay Logo"></img>
            </div>
            <div className="inputs">
              <input
                value={this.props.username}
                className="username"
                placeholder="Username"
                onChange={(e) => this.props.updateUser(e.target.value)}
              />
              <input
                value={this.props.password}
                className="password"
                type="password"
                placeholder="Password"
                onChange={(e) => this.props.updatePass(e.target.value)}
              />
            </div>
            <div className="login-bottom-section">
              <button className="login-button" id="login-events" onClick={() => this.login()}>
                Login
              </button>
            </div>
            <div className="error-container">
              <div
                className={classNames({
                  'error-text': true,
                  'show-error': this.props.errorMessage !== '',
                  'hide-error': this.props.errorMessage === ''
                })}
              >
                {this.props.errorMessage}
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.login.username,
  password: state.login.password,
  errorMessage: state.login.errorMessage,
  isLoggedIn: state.auth.isLoggedIn,
  systemToken: state.auth.systemToken,
  sysLocation: state.auth.sysLocation
});

export default connect(mapStateToProps, {
  initializeLogin,
  updateUser,
  updatePass,
  updateErrorMessage,
  login
})(Login);
