import React from 'react';
import './page.css';

export default class Page extends React.Component {
  render() {
    return (
      <div className="page-contain">
        <div className="page-content">{this.props.children}</div>
      </div>
    );
  }
}
