import React from 'react';
import './selectActivity.css';
import classNames from 'classnames';
import {
  updateProgram,
  updateProgramSelected,
  updateActivityName,
  updateActivitySelected,
  updateActivityCity,
  updateActivityCitySelected,
  updateActivitySite,
  updateActivitySiteSelected,
  updateActivityDetails,
  updateActivityDetailsSelected,
  updateActivityPartner,
  updateActivityPartnerSelected,
  updateActivityProvider,
  updateActivityProviderSelected,
  clearSelection
} from '../../../actions/activitiesPageActions/selectActivityActions.js';
import { updateUuid, lock } from '../../../actions/authActions.js';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import Loading from '../../loading/loading.jsx';
import { v4 as uuidv4 } from 'uuid';

class SelectActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backButtonText: 'Cancel',
      submitButtonText: 'Set Activity',
      filteredCities: [],
      selectedCity: null,
      showCities: false,
      filteredPrograms: [],
      selectedProgram: null,
      showPrograms: false,
      filteredActivities: [],
      selectedActivity: null,
      showActivities: false,
      filteredSites: [],
      selectedSite: null,
      showSites: false,
      filteredDetails: [],
      selectedDetails: [],
      showDetails: false,
      filteredPartners: [],
      selectedPartner: null,
      showPartners: false,
      filteredProviders: [],
      selectedProvider: null,
      showProviders: false
    };
  }

  async componentDidMount() {
    await this.props.io.get('/cities', (resData) => {
      if (resData.length) {
        this.setState({
          filteredCities: resData,
          showCities: true
        });
      }
    });
  }

  renderCityOptions() {
    if (this.state.filteredCities.length > 0) {
      return (
        <div
          className="col-md-3"
          style={{
            padding: '0 15px',
            transition: 'all 0.2s ease-in-out',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>City:</label>
          <br />
          {this.state.filteredCities.map((city, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedCity !== null && city.id === this.state.selectedCity.id
              })}
              onClick={() => this.handleCitySelect(city)}
              key={city.id}
              style={{
                fontSize: `${this.calculateFontSize(city.name)}pt`
              }}
            >
              {city.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-3"
        style={{
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No city options available
      </div>
    );
  }

  renderProgramOptions() {
    if (this.state.filteredPrograms.length > 0) {
      return (
        <div
          className="col-md-3"
          style={{
            borderLeft: '3px solid gray',
            padding: '0 15px',
            transition: 'all 0.2s ease-in-out',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>Program:</label>
          <br />
          {this.state.filteredPrograms.map((program, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedProgram !== null &&
                  program.id === this.state.selectedProgram.id
              })}
              onClick={() => this.handleProgramSelect(program)}
              key={program.id}
              style={{
                fontSize: `${this.calculateFontSize(program.name)}pt`
              }}
            >
              {program.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-3"
        style={{
          borderLeft: '3px solid gray',
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No program options available
      </div>
    );
  }

  renderActivityOptions() {
    if (this.state.filteredActivities.length > 0) {
      return (
        <div
          className="col-md-3"
          style={{
            borderLeft: '3px solid gray',
            padding: '0 15px',
            transition: 'all 0.2s ease-in-out',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>Activity:</label>
          <br />
          {this.state.filteredActivities.map((activity, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedActivity !== null &&
                  activity.id === this.state.selectedActivity.id
              })}
              onClick={() => this.handleActivitySelect(activity)}
              key={activity.id}
              style={{
                fontSize: `${this.calculateFontSize(activity.name)}pt`
              }}
            >
              {activity.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-3"
        style={{
          borderLeft: '3px solid gray',
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No activity options available
      </div>
    );
  }

  renderSiteOptions() {
    if (this.state.filteredSites.length > 0) {
      return (
        <div
          className="col-md-3"
          style={{
            borderLeft: '3px solid gray',
            padding: '0 15px',
            transition: 'all 0.2s ease-in-out',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>Site:</label>
          <br />
          {this.state.filteredSites.map((site, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedSite !== null && site.id === this.state.selectedSite.id
              })}
              onClick={() => this.handleSiteSelect(site)}
              key={site.id}
              style={{
                fontSize: `${this.calculateFontSize(site.name)}pt`
              }}
            >
              {site.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-3"
        style={{
          borderLeft: '3px solid gray',
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No site options available
      </div>
    );
  }

  renderDetailOptions() {
    if (this.state.filteredDetails.length > 0) {
      return (
        <div className="col-md-6" style={{ padding: '0 15px', height: '100%', overflowY: 'auto' }}>
          <label style={{ fontSize: '30px', color: 'white' }}>Activity Detail:</label>
          <br />
          {this.state.filteredDetails.map((detail, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected: this.state.selectedDetails.some(
                  (d) => d.id === detail.id
                )
              })}
              onClick={() => this.handleDetailSelect(detail)}
              key={detail.id}
              style={{
                fontSize: `${this.calculateFontSize(detail.activityDetail)}pt`
              }}
            >
              {detail.activityDetail}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-6"
        style={{
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No detail options available
      </div>
    );
  }

  renderPartnerOptions() {
    if (this.state.filteredPartners.length > 0) {
      return (
        <div
          className="col-md-6"
          style={{
            borderLeft: '3px solid gray',
            padding: '0 15px',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>Partner:</label>
          <br />
          {this.state.filteredPartners.map((partner, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedPartner !== null &&
                  partner.id === this.state.selectedPartner.id
              })}
              onClick={() => this.handlePartnerSelect(partner)}
              key={partner.id}
              style={{
                fontSize: `${this.calculateFontSize(partner.name)}pt`
              }}
            >
              {partner.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-6"
        style={{
          borderLeft: '3px solid gray',
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No partner options available
      </div>
    );
  }

  renderProviderOptions() {
    if (this.state.filteredProviders.length > 0) {
      return (
        <div
          className="col-md-6"
          style={{
            borderLeft: '3px solid gray',
            padding: '0 15px',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <label style={{ fontSize: '30px', color: 'white' }}>Providers:</label>
          <br />
          {this.state.filteredProviders.map((provider, index) => (
            <button
              className={classNames({
                selectActivitiesButtonColumnButton: true,
                selectActivitiesButtonOptionSelected:
                  this.state.selectedProvider !== null &&
                  provider.id === this.state.selectedProvider.id
              })}
              onClick={() => this.handleProviderSelect(provider)}
              key={provider.id}
              style={{
                fontSize: `${this.calculateFontSize(provider.name)}pt`
              }}
            >
              {provider.name}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div
        className="col-md-6"
        style={{
          borderLeft: '3px solid gray',
          padding: '0 15px',
          transition: 'all 0.2s ease-in-out',
          fontSize: '30px',
          color: 'white'
        }}
      >
        No provider options available
      </div>
    );
  }

  async handleCitySelect(city) {
    this.setState({
      backButtonText: 'Cancel',
      submitButtonText: 'Set Activity',
      selectedCity: city,
      selectedProgram: null,
      selectedActivity: null,
      selectedSite: null,
      selectedDetails: [],
      selectedPartner: null,
      selectedProvider: null
    });

    await this.props.io.get('/programs', (resData) => {
      if (resData) {
        this.setState({
          filteredPrograms: resData,
          showPrograms: true,
          filteredActivities: [],
          showActivities: false,
          filteredSites: [],
          showSites: false,
          filteredDetails: [],
          showDetails: false,
          filteredPartners: [],
          showPartners: false,
          filteredProviders: [],
          showProviders: false
        });
      }
    });
  }

  async handleProgramSelect(program) {
    this.setState({
      backButtonText: 'Cancel',
      submitButtonText: 'Set Activity',
      selectedProgram: program,
      selectedActivity: null,
      selectedSite: null,
      selectedDetails: [],
      selectedPartner: null,
      selectedProvider: null
    });

    var searchModel = {
      city: this.state.selectedCity.id,
      program: program.id
    };

    await this.props.io.get('/activities/findbyprogramandcity', searchModel, (resData) => {
      if (resData) {
        this.setState({
          filteredActivities: resData,
          showActivities: true,
          filteredSites: [],
          showSites: false,
          filteredDetails: [],
          showDetails: false,
          filteredPartners: [],
          showPartners: false,
          filteredProviders: [],
          showProviders: false
        });
      }
    });
  }

  async handleActivitySelect(activity) {
    this.setState({
      selectedActivity: activity,
      selectedSite: null,
      selectedDetails: [],
      selectedPartner: null,
      selectedProvider: null
    });

    var searchModel = {
      city: this.state.selectedCity.id,
      activity: activity.id
    };

    await this.props.io.get('/sites/findbycityandactivity', searchModel, (resData) => {
      if (resData) {
        this.setState({
          filteredSites: resData,
          showSites: true,
          filteredDetails: [],
          showDetails: false,
          filteredPartners: [],
          showPartners: false,
          filteredProviders: [],
          showProviders: false
        });
      }
    });
  }

  async handleSiteSelect(site) {
    try {
      const detailsResponse = await new Promise((resolve, reject) => {
        this.props.io.get(
          `/activitiesactivitydetails/finddetailsbyactivity/${this.state.selectedActivity.id}`,
          (resData) => {
            if (resData.length) {
              resolve(resData);
            } else {
              resolve([]);
            }
          }
        );
      });

      this.setState({
        filteredDetails: detailsResponse
      });

      const providerSearchModel = {
        activity: this.state.selectedActivity.id,
        city: this.state.selectedCity.id
      };

      const providersResponse = await new Promise((resolve, reject) => {
        this.props.io.get('/providers/findbyactivityandcity', providerSearchModel, (resData) => {
          if (resData.length) {
            resolve(resData);
          } else {
            resolve([]);
          }
        });
      });

      this.setState({
        filteredProviders: providersResponse
      });

      var submitText =
        detailsResponse.length > 0 || providersResponse.length > 0 ? 'Next' : 'Set Activity';

      this.setState({
        selectedSite: site,
        submitButtonText: submitText
      });
    } catch (error) {
      console.error(error);
    }
  }

  async handleDetailSelect(detail) {
    this.setState((prevState) => {
      const { selectedDetails } = prevState;
      const detailIndex = selectedDetails.findIndex((d) => d.id === detail.id);
      let updatedDetails;

      if (detailIndex > -1) {
        // If detail is already selected, remove it
        updatedDetails = selectedDetails.filter((d) => d.id !== detail.id);
      } else {
        // If detail is not selected, add it
        updatedDetails = [...selectedDetails, detail];
      }

      return {
        selectedDetails: updatedDetails
      };
    });
  }

  async handlePartnerSelect(partner) {
    this.setState({
      selectedPartner: partner
    });
  }

  async handleProviderSelect(provider) {
    this.setState({
      selectedProvider: provider
    });
  }

  setActivityInState() {
    this.props.clearSelection();

    this.props.updateActivityCity(this.state.selectedCity.name);
    this.props.updateActivityCitySelected(parseInt(this.state.selectedCity.id));

    this.props.updateProgram(this.state.selectedProgram.name);
    this.props.updateProgramSelected(parseInt(this.state.selectedProgram.id));

    this.props.updateActivityName(this.state.selectedActivity.name);
    this.props.updateActivitySelected(parseInt(this.state.selectedActivity.id));

    this.props.updateActivitySite(this.state.selectedSite.name);
    this.props.updateActivitySiteSelected(parseInt(this.state.selectedSite.id));

    if (this.state.selectedDetails.length > 0) {
      const sortedDetails = this.state.selectedDetails.slice().sort((a, b) => {
        return a.activityDetail.localeCompare(b.activityDetail);
      });
      const detailIds = this.state.selectedDetails.map((detail) => parseInt(detail.id)).sort();
      this.props.updateActivityDetails(sortedDetails);
      this.props.updateActivityDetailsSelected(detailIds);
    } else {
      this.props.updateActivityDetails([]);
      this.props.updateActivityDetailsSelected([]);
    }

    if (this.state.selectedPartner) {
      this.props.updateActivityPartner(this.state.selectedPartner.name);
      this.props.updateActivityPartnerSelected(parseInt(this.state.selectedPartner.id));
    }

    if (this.state.selectedProvider) {
      this.props.updateActivityProvider(this.state.selectedProvider.name);
      this.props.updateActivityProviderSelected(parseInt(this.state.selectedProvider.id));
    }

    this.props.updateUuid(uuidv4());
  }

  checkSubmitButtonDisabled() {
    var requiredFields =
      this.state.selectedCity !== null &&
      this.state.selectedProgram != null &&
      this.state.selectedActivity !== null &&
      this.state.selectedSite !== null;

    return !requiredFields;
  }

  checkBackButtonDisabled() {
    var requiredStateSet =
      this.props.selectActivity.activityCitySelected !== 0 &&
      this.props.selectActivity.programSelected !== 0 &&
      this.props.selectActivity.activitySelected !== 0 &&
      this.props.selectActivity.activitySiteSelected !== 0;

    return this.state.backButtonText === 'Back' ? false : !requiredStateSet;
  }

  backButtonAction() {
    var requiredStateSet =
      this.props.selectActivity.activityCitySelected !== 0 &&
      this.props.selectActivity.programSelected !== 0 &&
      this.props.selectActivity.activitySelected !== 0 &&
      this.props.selectActivity.activitySiteSelected !== 0;

    if (this.state.showDetails || this.state.showProviders) {
      this.setState({
        showCities: true,
        showPrograms: true,
        showActivities: true,
        showSites: true,
        selectedDetails: [],
        showDetails: false,
        // showPartners: false,
        selectedProvider: null,
        showProviders: false,
        backButtonText: 'Cancel',
        submitButtonText: 'Next'
      });
    } else if (
      (!requiredStateSet && this.state.backButtonText === 'Cancel') ||
      this.props.scanner.name.length === 0
    ) {
      this.props.lock();
    } else {
      this.props.history.push('/activities/home');
    }
  }

  submitButtonAction() {
    var openSecondPage =
      this.state.filteredDetails.length > 0 || this.state.filteredProviders.length > 0;

    var scannerAssigned =
      this.props.scanner.name !== '' && this.props.scanner.assignmentEndDate !== null;

    if (openSecondPage && this.state.submitButtonText === 'Next') {
      this.setState({
        showCities: false,
        showPrograms: false,
        showActivities: false,
        showSites: false,
        showDetails: true,
        // showPartners: this.state.filteredPartners > 0,
        showProviders: true,
        backButtonText: 'Back',
        submitButtonText: 'Set Activity'
      });
    } else {
      this.setActivityInState();
      this.props.history.push('/activities/scanner');
    }
  }

  calculateFontSize(text) {
    const buttonWidth = 219; // Adjust this based on your button width percentage
    const maxWidth = buttonWidth - 70; // Adjust for padding and margins

    let fontSize = 24; // Initial font size
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    do {
      context.font = `${fontSize}pt 'Oswald', sans-serif`;
      const textWidth = context.measureText(text).width;

      if (textWidth < maxWidth) {
        return fontSize;
      }

      fontSize--;
    } while (fontSize > 0);

    return 24;
  }

  render() {
    if (this.state.filteredCities.length === 0) {
      return <Loading />;
    }
    return (
      <Page>
        <div className="bootstrap-iso" style={{ height: '100%' }}>
          <div className="row" style={{ height: 'calc(100% - 60px)' }}>
            {this.state.showCities ? this.renderCityOptions() : null}
            {this.state.showPrograms ? this.renderProgramOptions() : null}
            {this.state.showActivities ? this.renderActivityOptions() : null}
            {this.state.showSites ? this.renderSiteOptions() : null}
            {this.state.showDetails ? this.renderDetailOptions() : null}
            {/* {this.state.showPartners ? this.renderPartnerOptions() : null} */}
            {this.state.showProviders ? this.renderProviderOptions() : null}
          </div>
          <div className="row">
            <div className="col-md-4">
              <button
                className="selectActivitiesButtonColumnButton"
                style={{
                  marginTop: '15px',
                  fontSize: `${this.calculateFontSize(this.state.backButtonText)}pt`
                }}
                onClick={() => this.backButtonAction()}
                // disabled={this.checkBackButtonDisabled()}
              >
                {this.state.backButtonText}
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button
                className="selectActivitiesButtonColumnButton"
                style={{
                  marginTop: '15px',
                  fontSize: `${this.calculateFontSize(this.state.submitButtonText)}pt`
                }}
                onClick={() => this.submitButtonAction()}
                disabled={this.checkSubmitButtonDisabled()}
              >
                {this.state.submitButtonText}
              </button>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  users: state.activities.activities.users,
  activities: state.activities.activities.activities,
  cities: state.activities.activities.cities,
  sites: state.activities.activities.sites,
  details: state.activities.activities.details,
  partners: state.activities.activities.partners,
  providers: state.activities.activities.providers,
  selectActivity: state.activities.selectActivity,
  scanner: state.activities.scanner
});

export default connect(mapStateToProps, {
  updateProgram,
  updateProgramSelected,
  updateActivityName,
  updateActivitySelected,
  updateActivityCity,
  updateActivityCitySelected,
  updateActivitySite,
  updateActivitySiteSelected,
  updateActivityDetails,
  updateActivityDetailsSelected,
  updateActivityPartner,
  updateActivityPartnerSelected,
  updateActivityProvider,
  updateActivityProviderSelected,
  clearSelection,
  lock,
  updateUuid
})(SelectActivity);
