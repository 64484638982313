import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../Modal.css';
import { updateMembershipDiscounts } from '../../../../actions/adminActions/membershipsActions';
import {
  membershipsaccess,
  membershipsInventoryType,
  membershipsskatepunches
} from '../../../../apis/backend';

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPrice: 0
    };
  }

  clearState() {
    this.setState({
      newPrice: 0
    });
  }

  async updateDiscountPrice() {
    switch (this.props.discountSelected.type) {
      case 'punches':
        await membershipsskatepunches.post(
          `/`,
          {
            membership: this.props.discountSelected.membership,
            skatePunches: this.props.discountSelected.item,
            price: isNaN(this.state.newPrice) ? 0 : this.state.newPrice
          },
          { headers: { authorization: this.props.employeeToken } }
        );
        break;
      case 'access':
        await membershipsaccess.post(
          '/',
          {
            membership: this.props.discountSelected.membership,
            access: this.props.discountSelected.item,
            price: isNaN(this.state.newPrice) ? 0 : this.state.newPrice
          },
          { headers: { authorization: this.props.employeeToken } }
        );
        break;
      case 'inventory':
        await membershipsInventoryType.post(
          '/',
          {
            membership: this.props.discountSelected.membership,
            inventoryType: this.props.discountSelected.item,
            price: isNaN(this.state.newPrice) ? 0 : this.state.newPrice
          },
          { headers: { authorization: this.props.employeeToken } }
        );
        break;
      default:
        console.log('Unknown Item');
    }
    this.clearState();
  }

  render() {
    return (
      <div
        className="modal fade"
        id="updateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-fade">
          <div className="modal-dialog modal-dialog-centered lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Discount
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    updateMembershipDiscounts(0, 0, '');
                    this.setState({ newPrice: 0 });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Price
                    </span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step={0.01}
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.newPrice}
                    placeholder="0.00"
                    onChange={(e) =>
                      this.setState({
                        newPrice: e.target.value
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.clearState();
                    updateMembershipDiscounts(0, 0, '');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  data-dismiss="modal"
                  onClick={() => {
                    this.updateDiscountPrice();
                    updateMembershipDiscounts(0, 0, '');
                    this.setState({ newPrice: 0 });
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  discountSelected: state.admin.memberships.discountSelected,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateMembershipDiscounts })(UpdateModal);
