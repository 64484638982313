export const populateCollectiveFeed = (feedItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_FEED_ITEMS',
    // payload: feedItems.filter((item) => item.location === 'collective')
    payload: feedItems
  });
};

export const populateCollectiveNews = (newsItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_NEWS_ITEMS',
    payload: newsItems
  });
};

export const updateCollectiveFeedItems = (feedItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_FEED_ITEMS',
    payload: [feedItem, ...getState().collective.collective.feedItems]
  });
};

export const updateCollectiveNewsItems = (newsItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_NEWS_ITEMS',
    payload: [newsItem, ...getState().collective.collective.newsItems]
  });
};

export const populateCollectiveUsers = (users) => (dispatch) => {
  dispatch({ type: 'POPULATE_COLLECTIVE_USERS', payload: users });
};

export const createCollectiveUser = (user) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_USERS',
    payload: [user, ...getState().collective.collective.users]
  });
};

export const updateCollectiveUser = (updatedUsers) => async (dispatch, getState) => {
  const users = [...getState().collective.collective.users];
  updatedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users[users.indexOf(oldUser)] = user;
  });
  dispatch({
    type: 'POPULATE_COLLECTIVE_USERS',
    payload: users
  });
};

export const deleteCollectiveUser = (deletedUsers) => async (dispatch, getState) => {
  const users = [...getState().collective.collective.users];
  deletedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users.splice(users.indexOf(oldUser), 1);
  });
  dispatch({
    type: 'POPULATE_COLLECTIVE_USERS',
    payload: users
  });
};

export const populateCollectiveInventory = (inventory) => (dispatch) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_INVENTORY_ITEMS',
    payload: inventory
  });
};

export const createCollectiveInventory = (inventory) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COLLECTIVE_INVENTORY_ITEMS',
    payload: [inventory, ...getState().collective.collective.inventory]
  });
};

export const updateCollectiveInventory = (updatedInventory) => async (dispatch, getState) => {
  const inventorys = [...getState().collective.collective.inventory];
  const oldInventory = inventorys.find((obj) => obj.id === updatedInventory.id);
  inventorys[inventorys.indexOf(oldInventory)] = updatedInventory;
  dispatch({
    type: 'POPULATE_COLLECTIVE_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const deleteCollectiveInventory = (deletedInventorys) => async (dispatch, getState) => {
  const inventorys = [...getState().collective.collective.inventory];
  deletedInventorys.forEach((inventory) => {
    const oldInventory = inventorys.find((obj) => obj.id === inventory.id);
    inventorys.splice(inventorys.indexOf(oldInventory), 1);
  });
  dispatch({
    type: 'POPULATE_COLLECTIVE_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const clearCollectivePage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_COLLECTIVE_PAGE_DATA' });
};

export const initializeCollective = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_COLLECTIVE_PAGE' });
};

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({
    type: 'CHANGE_COLLECTIVE_ACTIVE_TAB',
    payload: index
  });
};

export const incrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_COLLECTIVE_NEWS_UNREAD_TALLY',
    payload: getState().collective.collective.newsUnreadTally + 1
  });
};

export const decrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'DECREMENT_COLLECTIVE_NEWS_UNREAD_TALLY',
    payload: getState().collective.collective.newsUnreadTally - 1
  });
};

export const incrementActivityUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_COLLECTIVE_ACTIVITY_UNREAD_TALLY',
    payload: getState().collective.collective.activityUnreadTally + 1
  });
};

export const clearUnreadTally = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_COLLECTIVE_UNREAD_TALLY',
    payload: 0
  });
};

export const updateCollectiveAttendance = (attendance) => (dispatch) => {
  dispatch({
    type: 'UPDATE_COLLECTIVE_PAGE_USERS_ATTENDANCE',
    payload: attendance
  });
};
