import React from 'react';
import {
  addToCart,
  removeFromCart,
  updatePurchaseItemFilter,
  initializePurchasePage,
  clearPurchasePage
} from '../../../actions/activitiesPageActions/purchaseActions.js';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';
import { connect } from 'react-redux';
import './purchase.css';
import NotFound from '../../404/404';
import { purchaseOptions, bypassPurchase } from '../../../apis/backend';
import Loading from '../../loading/loading';

class Purchase extends React.Component {
  async componentDidMount() {
    await purchaseOptions
      .get(`/${this.props.match.params.id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .then((response) => {
        const options = [];
        if (response.data.memberships) {
          options.push(
            ...response.data.memberships.map((membership) => ({
              id: membership.id,
              name: membership.accessName,
              price: membership.price,
              duration: membership.duration,
              type: 'Membership'
            }))
          );
        }
        if (response.data.access) {
          options.push(
            ...response.data.access.map((access) => ({
              id: access.id,
              name: access.name,
              price: access.price,
              type: 'Access'
            }))
          );
        }
        if (response.data.skatePunches) {
          options.push(
            ...response.data.skatePunches.map((skatePunches) => ({
              id: skatePunches.id,
              name: skatePunches.name,
              price: skatePunches.price,
              type: 'Skate Punches'
            }))
          );
        }
        this.props.initializePurchasePage(options);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    this.props.clearPurchasePage();
  }

  handleCheckout = async (amount, id, cart) => {
    const stateObject = {
      user: id,
      cart: cart,
      type: 'purchase',
      location: this.props.locationId,
      empUser: this.props.empUserId
    };

    // Bypasses Square if price is $0
    if (amount === 0) {
      await bypassPurchase
        .post('/', { state: stateObject }, { headers: { authorization: this.props.employeeToken } })
        .catch((err) => console.log(err));
    } else {
      // console.log(stateObject);
      const state = JSON.stringify(stateObject);
      // console.log(state);

      const user = this.props.users.find(
        (user) => user.id === parseInt(this.props.match.params.id)
      );
      let notes = `${user.firstName} ${user.lastName} purchased:\n`;
      for (const item of cart) {
        notes += `• ${item.type} - ${item.name}: $${item.price}\n`;
      }

      var dataParameter = {
        /* eslint-disable camelcase */
        amount_money: {
          amount: amount,
          currency_code: 'USD'
        },

        // Replace this value with your application's callback URL
        callback_url:
          // 'https://webhook.site/573d1875-a545-413b-b63a-f733cd1d9b13',
          // 'https://d7b4a553.ngrok.io/activities/purchaseConfirmation/',
          'thebay://',

        // Replace this value with your application's ID
        client_id: 'sq0idp-Twg8oP3IDO7TalQhddy7Ow',

        version: '1.3',
        state: state,
        notes: notes,
        options: {
          supported_tender_types: [
            'CREDIT_CARD',
            'CASH',
            'OTHER',
            'SQUARE_GIFT_CARD',
            'CARD_ON_FILE'
          ],
          auto_return: true
        },
        location_id: '609EGP8M9GT1H'
        /* eslint-enable camelcase */
      };

      // console.log(dataParameter);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (iOS) {
        window.webkit.messageHandlers.openSquare.postMessage({
          url:
            'square-commerce-v1://payment/create?data=' +
            encodeURIComponent(JSON.stringify(dataParameter))
        });
      } else {
        alert(
          'To complete purchase, you must be on an iOS device with the Square POS app installed.'
        );
      }
    }

    this.props.history.push('/activities/home');
  };

  renderPurchaseItemOptions() {
    const purchaseItemOptions = {
      columns: [{ width: 27 }, { width: 53 }, { width: 20 }],
      titles: ['Type', 'Item', 'Price'],
      entries: [],
      selectEvent: this.props.addToCart
    };
    this.props.purchaseOptions
      .filter((item) => {
        const fullItem = `${item.name} ${item.type} ${item.price}`;
        return (
          fullItem.toLowerCase().indexOf(this.props.purchaseItemFilter.toLowerCase()) > -1 &&
          !this.props.cart.includes(this.props.purchaseOptions.indexOf(item))
        );
      })
      .forEach((item) => {
        purchaseItemOptions.entries.push({
          id: this.props.purchaseOptions.indexOf(item),
          columns: [item.type, item.name, '$' + item.price.toFixed(2)]
        });
      });
    return purchaseItemOptions;
  }

  renderCart() {
    const purchaseItemOptions = {
      columns: [{ width: 27 }, { width: 53 }, { width: 20 }],
      titles: ['Type', 'Item', 'Price'],
      entries: [],
      selectEvent: this.props.removeFromCart
    };
    this.props.purchaseOptions
      .filter((item) => this.props.cart.includes(this.props.purchaseOptions.indexOf(item)))
      .forEach((item) => {
        purchaseItemOptions.entries.push({
          id: this.props.purchaseOptions.indexOf(item),
          columns: [item.type, item.name, '$' + item.price.toFixed(2)]
        });
      });
    return purchaseItemOptions;
  }

  // badly named. for showing the name and total at the top
  getTotal() {
    let total = 0;
    for (const item of this.props.cart) {
      total += this.props.purchaseOptions[item].price;
    }

    const user = this.props.users.find((user) => user.id === parseInt(this.props.match.params.id));
    // console.log(user);

    return {
      total: `${user.firstName !== null ? user.firstName : ''} ${
        user.lastName !== null ? user.lastName.substring(0, 1) : ''
      }. - Total: ${total}`
    };
  }

  // Gets the total price
  getTotalPrice() {
    let total = 0;
    for (const item of this.props.cart) {
      total += this.props.purchaseOptions[item].price * 100;
    }

    return total;
  }

  render() {
    if (!this.props.users.map((user) => user.id).includes(parseInt(this.props.match.params.id))) {
      return <NotFound />;
    }
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Page>
        <div className="purchase-top-scroll-view-container">
          <div className="purchase-menu-list">
            <ScrollView
              options={this.renderPurchaseItemOptions()}
              formatOption={true}
              // buttons={this.renderPurchaseItemButtons()}
              searchBar={{
                filterString: this.props.purchaseItemFilter,
                onChange: this.props.updatePurchaseItemFilter
              }}
            ></ScrollView>
          </div>
          <div className="purchase-menu-list">
            <ScrollView
              options={this.renderCart()}
              formatOption={true}
              // buttons={this.renderCartButtons()}
              total={this.getTotal()}
            ></ScrollView>
          </div>
        </div>
        <div className="purchasePageButtonRowContain">
          <div className="purchasePageButtonContain" style={{ justifyContent: 'flex-start' }}>
            <button
              className="purchasePageButton"
              onClick={() => this.props.history.push('/activities/home')}
            >
              Back
            </button>
          </div>
          <div className="purchasePageButtonContain" />
          <div className="purchasePageButtonContain" style={{ justifyContent: 'flex-end' }}>
            <button
              className="purchasePageButton"
              onClick={() => {
                const cart = [];
                for (const itemId of this.props.cart) {
                  // console.log(itemId);
                  cart.push(this.props.purchaseOptions[itemId]);
                }
                // console.log(cart);
                this.handleCheckout(this.getTotalPrice(), this.props.match.params.id, cart);
              }}
              disabled={!this.props.cart.length}
            >
              Check-Out
            </button>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.activities.purchase.cart,
  purchaseItemFilter: state.activities.purchase.purchaseItemFilter,
  users: state.activities.activities.users,
  purchaseOptions: state.activities.purchase.purchaseOptions,
  loaded: state.activities.purchase.loaded,
  locationId: state.auth.sysLocationId,
  empUserId: state.auth.empId,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  addToCart,
  removeFromCart,
  updatePurchaseItemFilter,
  initializePurchasePage,
  clearPurchasePage
})(Purchase);
