export const updateProviderFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PROVIDERS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedProvider = (Provider) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_PROVIDERS_PAGE_SELECTED_PROVIDER', payload: Provider });
};
