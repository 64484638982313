import React from 'react';
import './App.css';
import { connect } from 'react-redux';
import Login from './components/login/login.jsx';
import Skatepark from './components/skatepark/skatepark';
import Collective from './components/collective/collective';
import Events from './components/events/events';
import Coffee from './components/coffee/coffee';
import NotFound from './components/404/404.jsx';
import Admin from './components/admin/admin.jsx';
import Activities from './components/activities/activities.jsx';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { initializeSockets } from './actions/appActions';
import LoggedInRoute from './routes/LoggedInRoute';
import UnlockedRoute from './routes/UnlockedRoute';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap/css/bootstrap-iso.css';
import KeyPad from './components/login/keypad';
import LoggedOutRoute from './routes/LoggedOutRoute';
import Loading from './components/loading/loading';

class App extends React.Component {
  componentDidMount() {
    this.setIO();
    setInterval(() => {
      const current = new Date();
      if (this.props.io && !this.props.io._isConnecting && !this.props.io.isConnected()) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (
        this.props.systemTokenExpires &&
        current.getTime() > Date.parse(this.props.systemTokenExpires)
      ) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      if (
        this.props.employeeTokenExpires &&
        current.getTime() > Date.parse(this.props.employeeTokenExpires)
      ) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }, 10000);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth !== this.props.auth) {
      try {
        const serializedState = JSON.stringify({
          systemToken: this.props.auth.systemToken,
          employeeToken: this.props.auth.employeeToken
        });
        localStorage.setItem('auth', serializedState);
      } catch {
        console.log('ERROR: Could not update localStorage');
        localStorage.setItem('auth', '');
      }
    }
    if (prevProps.auth.isLoggedIn && !this.props.auth.isLoggedIn) {
      // console.log(this.props.io);
      this.props.io.disconnect();
      this.props.io.reconnect();
    }
    if (prevProps.auth.employeeToken === null && this.props.auth.employeeToken !== null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.authorization = this.props.auth.employeeToken;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    } else if (prevProps.auth.employeeToken !== null && this.props.auth.employeeToken === null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.authorization = undefined;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    }
    if (prevProps.auth.sysLocationId === null && this.props.auth.sysLocationId !== null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.locationid = this.props.auth.sysLocationId;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    } else if (prevProps.auth.sysLocationId !== null && this.props.auth.sysLocationId === null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.locationid = undefined;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    }
    if (prevProps.auth.systemToken === null && this.props.auth.systemToken !== null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.systemauthorization = this.props.auth.systemToken;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    } else if (prevProps.auth.systemToken !== null && this.props.auth.systemToken === null) {
      const socket = this.props.io;
      const attributes = { ...socket.headers };
      attributes.systemauthorization = undefined;
      socket.headers = attributes;
      this.props.initializeSockets({ socket });
    }
  }

  setIO() {
    const io = sailsIOClient(socketIOClient);
    if (process.env.REACT_APP_ENV === 'prod') {
      io.socket.url = 'https://api.thebay.org';
    } else if (process.env.REACT_APP_ENV === 'test') {
      io.socket.url = 'https://testapi.thebay.org';
    } else {
      io.socket.url = 'http://localhost:1337';
    }
    this.props.initializeSockets(io);
  }

  checkPermissions(level) {
    var str = 'permission-check-';
    switch (level) {
      case 1:
        str += 'root';
        break;
      case 2:
        str += 'super-admin';
        break;
      case 3:
        str += 'super-admin'; //TODO: revert back to 'admin'
        break;
      case 4:
        str += 'manager';
        break;
      default:
        str += 'none';
    }
    return str;
  }

  render() {
    var permissionLevel = this.checkPermissions(this.props.empPermission);
    if (!this.props.connected) {
      return <Loading />;
    }
    return (
      <Router className={permissionLevel}>
        <Switch>
          <UnlockedRoute path="/activities" component={Activities} />
          {/* <UnlockedRoute path="/skatepark" component={Skatepark} />
          <UnlockedRoute path="/collective" component={Collective} /> */}
          <UnlockedRoute path="/events" component={Events} />
          {/* <UnlockedRoute path="/coffee" component={Coffee} /> */}
          <UnlockedRoute path="/admin" component={Admin} />
          <LoggedInRoute path="/keypad" component={KeyPad} />
          <LoggedOutRoute exact path="/login" component={Login} />
          <UnlockedRoute exact path="/" />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  connected: state.application.connected,
  auth: state.auth,
  io: state.application.io,
  systemTokenExpires: state.auth.systemTokenExpires,
  employeeTokenExpires: state.auth.employeeTokenExpires,
  empPermission: state.auth.empPermission
});

export default connect(mapStateToProps, {
  initializeSockets
})(App);
