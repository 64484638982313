import { events } from '../../apis/backend';

export const populateEventsFeed = (feedItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_EVENTS_FEED_ITEMS',
    // payload: feedItems.filter((item) => item.location === 'events')
    payload: feedItems
  });
};

export const populateEventsNews = (newsItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_EVENTS_NEWS_ITEMS',
    payload: newsItems
  });
};

export const updateEventsFeedItems = (feedItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_FEED_ITEMS',
    payload: [feedItem, ...getState().events.events.feedItems]
  });
};

export const updateEventsNewsItems = (newsItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_NEWS_ITEMS',
    payload: [newsItem, ...getState().events.events.newsItems]
  });
};

export const populateEventsUsers = (users) => (dispatch) => {
  dispatch({ type: 'POPULATE_EVENTS_USERS', payload: users });
};

export const createEventsUser = (user) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_USERS',
    payload: [user, ...getState().events.events.users]
  });
};

export const updateEventsUser = (updatedUsers) => async (dispatch, getState) => {
  const users = [...getState().events.events.users];
  updatedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users[users.indexOf(oldUser)] = user;
  });
  dispatch({
    type: 'POPULATE_EVENTS_USERS',
    payload: users
  });
};

export const deleteEventsUser = (deletedUsers) => async (dispatch, getState) => {
  const users = [...getState().events.events.users];
  deletedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users.splice(users.indexOf(oldUser), 1);
  });
  dispatch({
    type: 'POPULATE_EVENTS_USERS',
    payload: users
  });
};

export const populateEventsInventory = (inventory) => (dispatch) => {
  dispatch({
    type: 'POPULATE_EVENTS_INVENTORY_ITEMS',
    payload: inventory
  });
};

export const createEventsInventory = (inventory) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_INVENTORY_ITEMS',
    payload: [inventory, ...getState().events.events.inventory]
  });
};

export const updateEventsInventory = (updatedInventory) => async (dispatch, getState) => {
  const inventorys = [...getState().events.events.inventory];
  const oldInventory = inventorys.find((obj) => obj.id === updatedInventory.id);
  inventorys[inventorys.indexOf(oldInventory)] = updatedInventory;
  dispatch({
    type: 'POPULATE_EVENTS_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const deleteEventsInventory = (deletedInventorys) => async (dispatch, getState) => {
  const inventorys = [...getState().events.events.inventory];
  deletedInventorys.forEach((inventory) => {
    const oldInventory = inventorys.find((obj) => obj.id === inventory.id);
    inventorys.splice(inventorys.indexOf(oldInventory), 1);
  });
  dispatch({
    type: 'POPULATE_EVENTS_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const clearEventsPage = () => (dispatch, getState) => {
  const { ticketAttendanceInterval, cashAttendanceInterval } = getState().events.events;
  clearInterval(ticketAttendanceInterval);
  clearInterval(cashAttendanceInterval);
  dispatch({ type: 'CLEAR_EVENTS_PAGE_DATA' });
};

export const initializeEvents = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_EVENTS_PAGE' });
};

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({
    type: 'CHANGE_EVENTS_ACTIVE_TAB',
    payload: index
  });
};

export const incrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_EVENTS_NEWS_UNREAD_TALLY',
    payload: getState().events.events.newsUnreadTally + 1
  });
};

export const decrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'DECREMENT_EVENTS_NEWS_UNREAD_TALLY',
    payload: getState().events.events.newsUnreadTally - 1
  });
};

export const incrementActivityUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_EVENTS_ACTIVITY_UNREAD_TALLY',
    payload: getState().events.events.activityUnreadTally + 1
  });
};

export const clearUnreadTally = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_EVENTS_UNREAD_TALLY',
    payload: 0
  });
};

export const getAllEvents = (events) => (dispatch) => {
  dispatch({
    type: 'POPULATE_EVENTS_PAGE_EVENTS',
    payload: events
  });
};

export const setCurrentEvent = (event) => (dispatch) => {
  dispatch({
    type: 'GET_CURRENT_EVENT',
    payload: event
  });
};

export const createEvent = (event) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_PAGE_EVENTS',
    payload: [event, ...getState().events.events.events].sort((a, b) => a.startTime < b.startTime)
  });
};

export const updateEvent = (updatedEvents) => async (dispatch, getState) => {
  const events = [...getState().events.events.events];
  updatedEvents.forEach((event) => {
    const oldEvent = events.find((obj) => obj.id === event.id);
    events[events.indexOf(oldEvent)] = event;
  });
  dispatch({
    type: 'POPULATE_EVENTS_PAGE_EVENTS',
    payload: events
  });
};

export const deleteEvent = (deletedEvents) => async (dispatch, getState) => {
  const events = [...getState().events.events.events];
  deletedEvents.forEach((event) => {
    const oldEvent = events.find((obj) => obj.id === event.id);
    events.splice(events.indexOf(oldEvent), 1);
    if (oldEvent.id === getState().events.events.selectedEventId && events.length > 0) {
      dispatch({
        type: 'GET_CURRENT_EVENT',
        payload: events[0].id
      });
    }
  });
  dispatch({
    type: 'POPULATE_EVENTS_PAGE_EVENTS',
    payload: events
  });
};

export const feedItemTimerExpired = (feedItem) => async (dispatch, getState) => {
  const eventsList = [...getState().events.events.events];
  const event =
    eventsList[
      eventsList.indexOf(
        eventsList.find((obj) => obj.id === getState().events.events.selectedEventId)
      )
    ];
  const data = {};
  if (feedItem.id === 'tempCash') {
    data.cashAttendance = event.cashAttendance + feedItem.updateValue;
  } else {
    data.ticketAttendance = event.ticketAttendance + feedItem.updateValue;
  }
  await events
    .patch(`/${event.id}`, data, {
      headers: { authorization: getState().auth.employeeToken }
    })
    .catch((response) => {
      console.log(response);
    });
};

export const removeExistingEventsFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_EVENTS_PAGE_CASH_INTERVAL';
    interval = await getState().events.events.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_EVENTS_PAGE_TICKET_INTERVAL';
    interval = await getState().events.events.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  const feedItems = [...getState().events.events.feedItems];
  const feedItem = feedItems.find((obj) => obj.id === updatedFeedItem.id);
  if (feedItem) {
    feedItems.splice(feedItems.indexOf(feedItem), 1);
  }
  dispatch({ type: 'POPULATE_EVENTS_FEED_ITEMS', payload: feedItems });
  dispatch({ type: actionType, payload: null });
};

export const addToExistingEventsFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_EVENTS_PAGE_CASH_INTERVAL';
    interval = await getState().events.events.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_EVENTS_PAGE_TICKET_INTERVAL';
    interval = await getState().events.events.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  dispatch({
    type: 'POPULATE_EVENTS_FEED_ITEMS',
    payload: [updatedFeedItem, ...getState().events.events.feedItems]
  });
  const tempInterval = setInterval(() => {
    dispatch(feedItemTimerExpired(updatedFeedItem));
    dispatch(removeExistingEventsFeedItem(updatedFeedItem));
  }, 5000);
  dispatch({ type: actionType, payload: tempInterval });
};

export const updateExistingEventsFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_EVENTS_PAGE_CASH_INTERVAL';
    interval = await getState().events.events.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_EVENTS_PAGE_TICKET_INTERVAL';
    interval = await getState().events.events.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  const feedItems = [...getState().events.events.feedItems];
  const feedItem = feedItems.find((obj) => obj.id === updatedFeedItem.id);
  if (feedItem) {
    feedItems[feedItems.indexOf(feedItem)] = updatedFeedItem;
  }
  dispatch({ type: 'POPULATE_EVENTS_FEED_ITEMS', payload: feedItems });
  const tempInterval = setInterval(() => {
    dispatch(feedItemTimerExpired(updatedFeedItem));
    dispatch(removeExistingEventsFeedItem(updatedFeedItem));
  }, 5000);
  dispatch({ type: actionType, payload: tempInterval });
};

export const updateEventsAttendance = (attendance) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EVENTS_PAGE_USERS_ATTENDANCE',
    payload: attendance
  });
};

export const populateEventsUserNotes = (notes) => (dispatch) => {
  dispatch({ type: 'POPULATE_EVENTS_USER_NOTES', payload: notes });
};

export const createEventsUserNote = (note) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_EVENTS_USER_NOTES',
    payload: [note, ...getState().events.events.userNotes]
  });
};

export const updateEventsUserNote = (updatedNotes) => async (dispatch, getState) => {
  const notes = [...getState().events.events.userNotes];
  updatedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes[notes.indexOf(oldNote)] = note;
  });
  dispatch({
    type: 'POPULATE_EVENTS_USER_NOTES',
    payload: notes
  });
};

export const deleteEventsUserNote = (deletedNotes) => async (dispatch, getState) => {
  const notes = [...getState().events.events.userNotes];
  deletedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes.splice(notes.indexOf(oldNote), 1);
  });
  dispatch({
    type: 'POPULATE_EVENTS_USER_NOTES',
    payload: notes
  });
};
