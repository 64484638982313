import React from 'react';
import './purchaseHistory.css';
import { connect } from 'react-redux';
import {
  updatePurchaseLogFilter,
  updateSelectedPurchaseLog
} from '../../../actions/adminActions/adminPurchaseLogActions';
import CartModal from './purchaseHistoryModals/cartModal.jsx';

class PurchaseHistory extends React.Component {
  getUser(id) {
    return `${this.props.users.find((user) => user.id === id).firstName} ${
      this.props.users.find((user) => user.id === id).lastName
    }`;
  }

  getEmployee(id) {
    return `${this.props.empUsers.find((emp) => emp.id === id).firstName} ${
      this.props.empUsers.find((emp) => emp.id === id).lastName
    }`;
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return `${date.toDateString()}, ${date.toLocaleTimeString(undefined, options)}`;
  }

  renderPurchaseLogs() {
    //renders the PurchaseLog table
    return (
      <>
        {this.props.purchaseLogs
          .filter((log) => {
            const filterString = `${log.time} ${log.transactionId} ${log.cart} ${this.getUser(
              log.user
            )} ${this.getEmployee(log.empUser)} ${this.formatDate(log.time)}`;
            return (
              filterString.toLowerCase().indexOf(this.props.purchaseLogFilter.toLowerCase()) > -1
            );
          })
          .map((log) => (
            <tr key={this.props.purchaseLogs.indexOf(log)}>
              <td className="text-center" style={{ width: '15%' }}>
                {this.getUser(log.user)}
              </td>
              <td className="text-center" style={{ width: '15%' }}>
                {this.getEmployee(log.empUser)}
              </td>
              <td className="text-center" style={{ width: '15%' }}>
                {`${log.type.charAt(0).toUpperCase()}${log.type.slice(1)}`}
              </td>
              <td className="text-center" style={{ width: '15%' }}>
                {this.formatDate(log.time)}
              </td>
              <td className="text-center" style={{ width: '20%' }}>
                {log.transactionId}
              </td>
              <td className="text-center " style={{ width: '10%' }}>
                <div
                  data-target="#cartModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedPurchaseLog(log.id);
                  }}
                >
                  View Cart
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  render() {
    return (
      <div className="purchase-history-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Purchase History</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.purchaseLog}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updatePurchaseLogFilter(e.target.value);
              }}
            />
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '15%' }}>
                    User
                  </th>
                  <th className="text-center" style={{ width: '15%' }}>
                    Employee
                  </th>
                  <th className="text-center " style={{ width: '12%' }}>
                    Type
                  </th>
                  <th className="text-center " style={{ width: '18%' }}>
                    Date
                  </th>
                  <th className="text-center " style={{ width: '20%' }}>
                    Transaction ID
                  </th>
                  <th className="text-center " style={{ width: '10%' }}>
                    View Cart
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderPurchaseLogs()}</tbody>
            </table>
          </div>
        </div>

        <CartModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  purchaseLogs: state.admin.admin.purchaseLogs,
  purchaseLogFilter: state.admin.purchaseLogs.purchaseLogFilter,
  selectedPurchaseLog: state.admin.purchaseLogs.selectedPurchaseLog,
  systemLocations: state.admin.admin.systemLocations,
  users: state.admin.admin.users,
  empUsers: state.admin.admin.employees
});

export default connect(mapStateToProps, {
  updatePurchaseLogFilter,
  updateSelectedPurchaseLog
})(PurchaseHistory);
