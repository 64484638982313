import React from 'react';
import { connect } from 'react-redux';
import './modal.css';
import { usernotes } from '../../../apis/backend';

class CreateUserNote extends React.Component {
  async createUserNote(userId) {
    await usernotes
      .post(
        '/',
        {
          note: this.props.note,
          userId: userId,
          flagStatus: 'none'
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => console.log(response));
  }

  render() {
    return (
      <>
        <div className={this.props.visible ? 'popup-container' : 'popup-container hide-content'}>
          <div id="content-spacer">
            <div className="popup-content">
              <div className="modal-text">Create a new note:</div>
              <textarea
                type="text"
                className="form-control note-input"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={this.props.note}
                placeholder="Note"
                onChange={(e) => this.props.updateNote(e.target.value)}
              />
              <div className="popup-buttons">
                <button
                  className="popup-button"
                  onClick={() => {
                    this.props.clearModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="popup-button"
                  onClick={() => {
                    this.createUserNote(this.props.id);
                    this.props.clearModal();
                  }}
                >
                  Create Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationId: state.auth.sysLocationId,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(CreateUserNote);
