export const updateActivitiesCheckinModalName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_CHECKIN_MODAL_NAME',
    payload: name
  });
};

export const updateActivitiesCheckinModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_CHECKIN_MODAL_USER_ID',
    payload: getState().activities.checkin.selected === id ? 0 : id
  });
};

export const updateActivitiesCheckinModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_CHECKIN_MODAL_ACTIVE',
    payload: value
  });
};

export const clearActivitiesCheckinModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ACTIVITIES_CHECKIN_MODAL'
  });
};

export const updateActivitiesNoteModalNote = (note) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_NOTE_MODAL_NOTE',
    payload: note
  });
};

export const updateActivitiesNoteModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_NOTE_MODAL_USER_ID',
    payload: getState().activities.checkin.selected === id ? 0 : id
  });
};

export const updateActivitiesNoteModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_NOTE_MODAL_ACTIVE',
    payload: value
  });
};

export const clearActivitiesNoteModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ACTIVITIES_NOTE_MODAL'
  });
};
