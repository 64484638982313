import { types } from '../../actions/actionTypes';
const INITIAL_STATE = {
  isLoaded: false,
  users: [],
  usersLoaded: false,
  raspberryPis: [],
  raspberryPisLoaded: false,
  inventory: [],
  inventoryLoaded: false,
  inventoryTypes: [],
  inventoryTypesLoaded: false,
  memberships: [],
  membershipsLoaded: false,
  employees: [],
  employeesLoaded: false,
  systemUsers: [],
  systemUsersLoaded: false,
  events: [],
  eventsLoaded: false,
  cities: [],
  citiesLoaded: false,
  sites: [],
  sitesLoaded: false,
  partners: [],
  partnersLoaded: false,
  partnerActivities: [],
  partnerActivitiesLoaded: false,
  activities: [],
  activitiesLoaded: false,
  activityDetails: [],
  activityDetailsLoaded: false,
  programs: [],
  programsLoaded: false,
  providers: [],
  providersLoaded: false,
  news: [],
  newsLoaded: false,
  skatePunches: [],
  skatePunchesLoaded: false,
  systemLocations: [],
  systemLocationsLoaded: false,
  purchaseLogs: [],
  purchaseLogsLoaded: false,
  skateAccess: [],
  skateAccessLoaded: false,
  attendanceLogRecent: [],
  attendanceLogRecentLoaded: false,
  mealSwipesAllowance: [],
  mealSwipesAllowanceLoaded: false,
  rentals: [],
  rentalsLoaded: false,
  userNotes: [],
  userNotesLoaded: false,
  noteFlags: [],
  noteFlagsLoaded: false,
  permissionLevels: [],
  permissionLevelsLoaded: false
};

export const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.adminPageInitialization:
      return {
        ...state,
        isLoaded: true
      };
    case types.populateAdminUsers:
      return {
        ...state,
        users: action.payload,
        usersLoaded: true
      };
    case types.populateAdminRaspberryPi:
      return {
        ...state,
        raspberryPis: action.payload,
        raspberryPisLoaded: true
      };
    case types.populateAdminInventory:
      return {
        ...state,
        inventory: action.payload,
        inventoryLoaded: true
      };
    case types.populateAdminInventoryTypes:
      return {
        ...state,
        inventoryTypes: action.payload,
        inventoryTypesLoaded: true
      };
    case types.populateAdminMemberships:
      return {
        ...state,
        memberships: action.payload,
        membershipsLoaded: true
      };
    case types.populateAdminEvents:
      return {
        ...state,
        events: action.payload,
        eventsLoaded: true
      };
    case types.populateAdminCities:
      return {
        ...state,
        cities: action.payload,
        citiesLoaded: true
      };
    case types.populateAdminSites:
      return {
        ...state,
        sites: action.payload,
        sitesLoaded: true
      };
    case types.populateAdminPartners:
      return {
        ...state,
        partners: action.payload,
        partnersLoaded: true
      };
    case types.populateAdminPartnerActivities:
      return {
        ...state,
        partnerActivities: action.payload,
        partnerActivitiesLoaded: true
      };
    case types.populateAdminActivities:
      return {
        ...state,
        activities: action.payload,
        activitiesLoaded: true
      };
    case types.populateAdminActivityDetails:
      return {
        ...state,
        activityDetails: action.payload,
        activityDetailsLoaded: true
      };
    case types.populateAdminPrograms:
      return {
        ...state,
        programs: action.payload,
        programsLoaded: true
      };
    case types.populateAdminProviders:
      return {
        ...state,
        providers: action.payload,
        providersLoaded: true
      };
    case types.populateAdminNews:
      return {
        ...state,
        news: action.payload,
        newsLoaded: true
      };
    case types.populateAdminSkatePunches:
      return {
        ...state,
        skatePunches: action.payload,
        skatePunchesLoaded: true
      };
    case types.populateAdminEmployees:
      return {
        ...state,
        employees: action.payload,
        employeesLoaded: true
      };
    case types.populateAdminSystemUsers:
      return {
        ...state,
        systemUsers: action.payload,
        systemUsersLoaded: true
      };
    case types.populateAdminSystemLocations:
      return {
        ...state,
        systemLocations: action.payload,
        systemLocationsLoaded: true
      };
    case types.populateAdminPurchaseLogs:
      return {
        ...state,
        purchaseLogs: action.payload,
        purchaseLogsLoaded: true
      };
    case types.populateAdminAccess:
      return {
        ...state,
        access: action.payload,
        accessLoaded: true
      };
    case types.populateAdminAttendanceLogRecent:
      return {
        ...state,
        attendanceLogRecent: action.payload,
        attendanceLogRecentLoaded: true
      };
    case types.populateAdminMealSwipesAllowance:
      return {
        ...state,
        mealSwipesAllowance: action.payload,
        mealSwipesAllowanceLoaded: true
      };
    case types.populateAdminRentals:
      return {
        ...state,
        rentals: action.payload,
        rentalsLoaded: true
      };
    case types.populateAdminUserNotes:
      return {
        ...state,
        userNotes: action.payload,
        userNotesLoaded: true
      };
    case types.populateAdminNoteFlags:
      return {
        ...state,
        noteFlags: action.payload,
        noteFlagsLoaded: true
      };
    case types.populateAdminPermissionLevels:
      return {
        ...state,
        permissionLevels: action.payload,
        permissionLevelsLoaded: true
      };
    case types.clearAdminPage:
      return INITIAL_STATE;
    default:
      return state;
  }
};
