import React, { Component } from 'react';
import Select from 'react-select';
import '../../Modal.css';
import { connect } from 'react-redux';
import { activities } from '../../../../apis/backend';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      programs: [],
      cities: [],
      sites: [],
      partners: [],
      providers: [],
      details: []
    };
  }

  clearState() {
    this.setState({
      id: '',
      name: '',
      programs: [],
      cities: [],
      sites: [],
      partners: [],
      providers: [],
      details: []
    });
  }

  async activityRequest() {
    try {
      await activities
        .post(
          '/',
          {
            // "field in the db that you are adding: data to be added"
            // look in the backend/api/models to see which fields are required
            id: this.state.id,
            name: this.state.name,
            programs: this.state.programs.map((program) => program.value),
            cities: this.state.cities.map((city) => city.value),
            sites: this.state.sites.map((site) => site.value),
            partners: this.state.partners.map((partner) => partner.value),
            providers: this.state.providers.map((provider) => provider.value),
            details: this.state.details.map((detail) => detail.value)
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    const programOptions = this.props.programs.map((program) => {
      const option = {
        value: program.id,
        label: program.name
      };
      return option;
    });
    const cityOptions = this.props.cities.map((city) => {
      const option = {
        value: city.id,
        label: city.name
      };
      return option;
    });
    const siteOptions = this.props.sites.map((site) => {
      const option = {
        value: site.id,
        label: site.name
      };
      return option;
    });
    const partnerOptions = this.props.partners.map((partner) => {
      const option = {
        value: partner.id,
        label: partner.name
      };
      return option;
    });
    const providerOptions = this.props.providers.map((provider) => {
      const option = {
        value: provider.id,
        label: provider.name
      };
      return option;
    });
    const detailOptions = this.props.activityDetails.map((detail) => {
      const option = {
        value: detail.id,
        label: detail.activityDetail
      };
      return option;
    });
    return (
      <div id="addModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Activity</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Activity Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.name}
                  placeholder="Activity Name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Program
                  </span>
                </div>
                <Select
                  name="ActivityPrograms"
                  id="ActivityPrograms"
                  value={this.state.programs}
                  options={programOptions}
                  onChange={(e) => {
                    var prog = [];
                    prog.push(e);
                    this.setState({ programs: prog });
                  }}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Cities
                  </span>
                </div>
                <Select
                  name="ActivityCities"
                  id="ActivityCities"
                  className="multiselector"
                  isMulti
                  value={this.state.cities}
                  options={cityOptions}
                  onChange={(e) => {
                    this.setState({ cities: e });
                  }}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Sites
                  </span>
                </div>
                <Select
                  name="ActivitySites"
                  id="ActivitySites"
                  className="multiselector"
                  isMulti
                  value={this.state.sites}
                  options={siteOptions}
                  onChange={(e) => {
                    this.setState({ sites: e });
                  }}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Partners
                  </span>
                </div>
                <Select
                  name="ActivityPartners"
                  id="ActivityPartners"
                  className="multiselector"
                  isMulti
                  value={this.state.partners}
                  options={partnerOptions}
                  onChange={(e) => {
                    this.setState({ partners: e });
                  }}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Providers
                  </span>
                </div>
                <Select
                  name="ActivityProviders"
                  id="ActivityProviders"
                  className="multiselector"
                  isMulti
                  value={this.state.providers}
                  options={providerOptions}
                  onChange={(e) => {
                    this.setState({ providers: e });
                  }}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Activity Details
                  </span>
                </div>
                <Select
                  name="ActivityDetails"
                  id="ActivityDetails"
                  className="multiselector"
                  isMulti
                  value={this.state.details}
                  options={detailOptions}
                  onChange={(e) => {
                    this.setState({ details: e });
                  }}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.activityRequest();
                }}
                disabled={!this.state.name != ''}
              >
                Add Activity
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: state.admin.admin.activities,
  programs: state.admin.admin.programs,
  cities: state.admin.admin.cities,
  sites: state.admin.admin.sites,
  partners: state.admin.admin.partners,
  providers: state.admin.admin.providers,
  activityDetails: state.admin.admin.activityDetails,
  selectedActivity: state.admin.activities.selectedActivity,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(AddModal);
