const INITIAL_STATE = {
  mealSwipesAllowanceFilter: '',
  selectedMealSwipesAllowance: 0
};

export const mealSwipesAllowanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_MEAL_SWIPES_ALLOWANCE_PAGE_FILTER_STRING':
      return {
        ...state,
        mealSwipesAllowanceFilter: action.payload
      };
    case 'UPDATE_ADMIN_MEAL_SWIPES_ALLOWANCE_PAGE_SELECTED_ACCESS':
      return {
        ...state,
        selectedMealSwipesAllowance: action.payload
      };
    default:
      return state;
  }
};
