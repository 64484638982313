export const populateCoffeeFeed = (feedItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_COFFEE_FEED_ITEMS',
    // payload: feedItems.filter((item) => item.location === 'coffee')
    payload: feedItems
  });
};

export const updateCoffeeFeedItems = (feedItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COFFEE_FEED_ITEMS',
    payload: [feedItem, ...getState().coffee.coffee.feedItems]
  });
};

export const populateCoffeeUsers = (users) => (dispatch) => {
  dispatch({ type: 'POPULATE_COFFEE_USERS', payload: users });
};

export const createCoffeeUser = (user) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_COFFEE_USERS',
    payload: [user, ...getState().coffee.coffee.users]
  });
};

export const updateCoffeeUser = (updatedUsers) => async (dispatch, getState) => {
  const users = [...getState().coffee.coffee.users];
  updatedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users[users.indexOf(oldUser)] = user;
  });
  dispatch({
    type: 'POPULATE_COFFEE_USERS',
    payload: users
  });
};

export const deleteCoffeeUser = (deletedUsers) => async (dispatch, getState) => {
  const users = [...getState().coffee.coffee.users];
  deletedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users.splice(users.indexOf(oldUser), 1);
  });
  dispatch({
    type: 'POPULATE_COFFEE_USERS',
    payload: users
  });
};

export const clearCoffeePage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_COFFEE_PAGE_DATA' });
};

export const initializeCoffee = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_COFFEE_PAGE' });
};
