import React from 'react';
import './rfid-register.css';
import {
  updateName,
  updateSelected,
  clearSelection
} from '../../../actions/activitiesPageActions/checkinActions.js';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';

class RFIDRegister extends React.Component {
  componentWillUnmount() {
    this.props.clearSelection();
  }

  getAge(user) {
    const birthDate = new Date(user.dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  renderCheckInOptions() {
    const checkinOptions = {
      columns: [{ width: 75 }, { width: 25 }],
      titles: ['Name', 'Age'],
      entries: [],
      selectEvent: this.props.updateSelected,
      selected: this.props.selected
    };
    this.props.users
      .filter((user) => {
        const fullname = `${user.firstName} ${user.lastName}`;
        const age = this.getAge(user);
        return (
          fullname.toLowerCase().indexOf(this.props.name.toLowerCase()) > -1 ||
          age.toString().indexOf(this.props.name.toLowerCase()) > -1
        );
      })
      .forEach((user) => {
        var age = this.getAge(user);
        checkinOptions.entries.push({
          id: user.id,
          columns: [`${user.firstName} ${user.lastName}`, age.toString()]
        });
      });
    return checkinOptions;
  }

  renderCheckInButtons() {
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/activities/home')
      },
      right: {
        title: 'Select',
        onClick: () => this.props.history.push(`/activities/rfid/scan/${this.props.selected}`),
        disabled: this.props.selected === 0
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={this.renderCheckInOptions()}
          buttons={this.renderCheckInButtons()}
          searchBar={{
            filterString: this.props.name,
            onChange: this.props.updateName
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.activities.checkin.name,
  users: state.activities.activities.users,
  selected: state.activities.checkin.selected
});

export default connect(mapStateToProps, {
  updateName,
  updateSelected,
  clearSelection
})(RFIDRegister);
