import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { systemAuth } from '../apis/backend';
import { updateSysUser, updateAuth } from '../actions/authActions';

class LoggedOutRoute extends React.Component {
  componentDidMount() {
    try {
      const serializedState = localStorage.getItem('auth');
      if (serializedState !== null) {
        this.props.updateAuth(JSON.parse(serializedState));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async checkLogin() {
    await systemAuth
      .get('/', { headers: { systemauthorization: this.props.systemToken } })
      .then(async (res) => {
        await this.props.updateSysUser(
          res.data.id,
          res.data.location,
          res.data.locationId,
          res.data.expires
        );
      })
      .catch((err) => {
        console.log(err);
        this.props.updateAuth({ systemToken: null, employeeToken: null });
      });
  }

  render() {
    if (this.props.isLoggedIn) {
      return <Redirect to={`/${this.props.sysLocation}`} />;
    }
    if (this.props.systemToken) {
      this.checkLogin();
    }
    return <Route component={this.props.component} />;
  }
}

const mapStateToProps = (state) => ({
  systemToken: state.auth.systemToken,
  isLoggedIn: state.auth.isLoggedIn,
  sysLocation: state.auth.sysLocation
});

export default connect(mapStateToProps, { updateSysUser, updateAuth })(LoggedOutRoute);
