import React from 'react';
// import classNames from 'classnames';
import './feedView.css';
// import { changeActiveTab } from '../../../actions/skateparkPageActions/skateparkActions.js';

export default class FeedView extends React.Component {
  render() {
    const leftActiveStyle = {
      // border: '3px solid gray',
      borderBottom: 'none'
      // borderRight: 'none',
      // borderRadius: '0 20px 0 0',
      // paddingBottom: '3px'
    };
    const rightActiveStyle = {
      // border: '3px solid gray',
      borderBottom: 'none'
      // borderLeft: 'none',
      // borderRadius: '0 20px 0 0',
      // paddingBottom: '3px'
    };
    const leftDisabledStyle = {
      // border: '3px solid gray',
      color: 'gray',
      backgroundColor: '#c0c0c0',
      borderBottom: '3px solid gray',
      borderRight: '3px solid gray',
      opacity: '90%',
      borderRadius: '20px 0 20px 0'
    };
    const rightDisabledStyle = {
      // border: '3px solid gray',
      color: 'gray',
      backgroundColor: '#c0c0c0',
      borderBottom: '3px solid gray',
      borderLeft: '3px solid gray',
      opacity: '90%',
      borderRadius: '0 20px 0 20px'
    };
    return (
      <>
        <div className="container">
          <div className="feedButtons">
            <div
              style={this.props.activeTab === 0 ? leftActiveStyle : leftDisabledStyle}
              className="leftTab"
              onClick={() => {
                this.props.tabClick(0);
              }}
            >
              <div className="activityItems">
                Activity
                {this.props.tally.activityUnreadTally > 0 && this.props.activeTab === 1 ? (
                  <div className="circleContainer">
                    <div className="tallyCircle">{this.props.tally.activityUnreadTally}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              style={this.props.activeTab === 1 ? rightActiveStyle : rightDisabledStyle}
              className="rightTab"
              onClick={() => {
                this.props.tabClick(1);
              }}
            >
              <div className="newsItems">
                News
                {this.props.tally.newsUnreadTally > 0 ? (
                  <div className="circleContainer">
                    <div className="tallyCircle">{this.props.tally.newsUnreadTally}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="activityFeed">
            {this.props.activeTab === 0 ? (
              this.props.activity ? (
                this.props.activity
              ) : (
                <h1>No items to display</h1>
              )
            ) : this.props.updates ? (
              this.props.updates
            ) : (
              <h1>No items to display</h1>
            )}
          </div>
        </div>
      </>
    );
  }
}
