const INITIAL_STATE = {
  siteFilter: '',
  selectedSite: 0
};

export const sitesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_SITES_PAGE_FILTER_STRING':
      return {
        ...state,
        siteFilter: action.payload
      };
    case 'UPDATE_ADMIN_SITES_PAGE_SELECTED_SITE':
      return {
        ...state,
        selectedSite: action.payload
      };
    default:
      return state;
  }
};
