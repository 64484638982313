import React from 'react';
import { connect } from 'react-redux';
import Page from '../utils/page/page.jsx';
import './loading.css';
import Error from '../../images/broken-board-transparent-white.png';
import { lock } from '../../actions/authActions.js';

class Loading extends React.Component {
  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      document.getElementById('loading').style.display = 'none';
      document.getElementById('error').style.display = 'flex';
    }, 10000);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      <Page>
        <div className="loading-indicator" id="loading">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <div className="error-indicator" id="error">
          <div style={{ marginBottom: '5px' }}>Something went wrong...</div>
          <img style={{ maxWidth: '200px' }} src={Error} alt="Broken Board" />
          {/* <div>Tap to reload!</div> */}
          <div className="button-container">
            <button
              className="error-lock-button"
              style={{ marginTop: '15px' }}
              onClick={() => window.location.reload(false)}
              // disabled={this.checkBackButtonDisabled()}
            >
              Reload
            </button>
            <button
              className="error-lock-button"
              style={{ marginTop: '15px' }}
              onClick={() => this.props.lock()}
              // disabled={this.checkBackButtonDisabled()}
            >
              Lock
            </button>
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(null, { lock })(Loading);
