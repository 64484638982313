export const updatePartnerActivityFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PARTNER_ACTIVITIES_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedPartnerActivity = (PartnerActivity) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PARTNER_ACTIVITIES_PAGE_SELECTED_PARTNER_ACTIVITY',
    payload: PartnerActivity
  });
};
