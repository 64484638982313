const INITIAL_STATE = {
  partnerFilter: '',
  selectedPartner: 0
};

export const partnersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_PARTNERS_PAGE_FILTER_STRING':
      return {
        ...state,
        partnerFilter: action.payload
      };
    case 'UPDATE_ADMIN_PARTNERS_PAGE_SELECTED_PARTNER':
      return {
        ...state,
        selectedPartner: action.payload
      };
    default:
      return state;
  }
};
