export const updateInventoryTypeFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_INVENTORY_TYPE_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedInventoryType = (inventoryType) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_INVENTORY_TYPE_PAGE_SELECTED_INVENTORY',
    payload: inventoryType
  });
};
