import React from 'react';
import './register.css';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView';
import { waiverURL } from '../../../apis/smartwaiver';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderRegisterButtons() {
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/collective')
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView buttons={this.renderRegisterButtons()}>
          <iframe src={waiverURL} title="smartwaiver"></iframe>
        </ScrollView>
      </Page>
    );
  }
}

export default Register;
