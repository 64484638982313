const INITIAL_STATE = {
  isLoggedIn: false,
  isUnlocked: false,
  systemToken: null,
  systemTokenExpires: null,
  employeeToken: null,
  employeeTokenExpires: null,
  sysId: null,
  sysLocation: '',
  sysLocationId: null,
  empId: null,
  empPermission: 0,
  uuid: ''
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AUTHENTICATION_LOG_IN': {
      return {
        ...state,
        systemToken: action.payload.token,
        systemTokenExpires: action.payload.expires
      };
    }
    case 'AUTHENTICATION_LOG_OUT': {
      return {
        ...state,
        isLoggedIn: false,
        systemToken: null,
        sysId: null,
        sysLocation: '',
        sysLocationId: null,
        empPermission: ''
      };
    }
    case 'AUTHENTICATION_UNLOCK': {
      return {
        ...state,
        employeeToken: action.payload.token,
        employeeTokenExpires: action.payload.expires,
        empPermission: action.payload.permission
      };
    }
    case 'AUTHENTICATION_LOCK': {
      return {
        ...state,
        isUnlocked: false,
        employeeToken: null,
        empId: null,
        empPermission: '',
        uuid: ''
      };
    }
    case 'AUTHENTICATION_UPDATE_SYSTEM_USER':
      return {
        ...state,
        sysId: action.payload.id,
        sysLocation: action.payload.location,
        sysLocationId: action.payload.locationId,
        systemTokenExpires: action.payload.expires,
        isLoggedIn: true
      };
    case 'AUTHENTICATION_UPDATE_EMPLOYEE_USER':
      return {
        ...state,
        empId: action.payload.id,
        employeeTokenExpires: action.payload.expires,
        isUnlocked: true,
        empPermission: action.payload.permission
      };
    case 'AUTHENTICATION_LOAD_FROM_LOCALSTORAGE':
      return {
        ...state,
        systemToken: action.payload.systemToken,
        employeeToken: action.payload.employeeToken,
        empPermission: action.payload.permission
      };
    case 'AUTHENTICATION_UPDATE_UUID':
      return {
        ...state,
        uuid: action.payload
      };
    default:
      return state;
  }
};
