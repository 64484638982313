import React from 'react';
import '../../bootstrap/css/bootstrap-iso.css';
import './activities.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './home/home.jsx';
import Register from './register/register.jsx';
import Checkin from './checkin/checkin.jsx';
import AddUser from './add-user/add-user.jsx';
import SelectActivity from './selectActivity/selectActivity.jsx';
import Purchase from './purchase/purchase.jsx';
import Inventory from './inventory/inventory.jsx';
import Edit from './inventory/edit/edit.jsx';
import Rentals from './rentals/rentals.jsx';
import Notes from './notes/notes.jsx';
import Loading from '../loading/loading.jsx';
import NotFound from '../404/404.jsx';
import RFIDRegister from './rfid-register/rfid-register.jsx';
import RFIDScan from './rfid-register/rfid-scan.jsx';
import ScannerRegister from './scanner-register/scanner-register.jsx';
import Login from '../login/login.jsx';
import {
  populateActivitiesFeed,
  populateActivitiesNews,
  updateActivitiesFeedItems,
  populateActivitiesUsers,
  createActivitiesUser,
  updateActivitiesUser,
  deleteActivitiesUser,
  updateActivitiesAttendance,
  populateActivitiesInventory,
  createActivitiesInventory,
  updateActivitiesInventory,
  deleteActivitiesInventory,
  clearActivitiesPage,
  initializeActivities,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  getAllActivities,
  getAllCities,
  getAllSites,
  getAllDetails,
  getAllPartners,
  getAllProviders,
  setCurrentActivity,
  decrementNewsUnreadTally,
  createActivity,
  updateActivity,
  deleteActivity,
  updateActivitiesNewsItems,
  populateActivitiesUserNotes,
  createActivitiesUserNote,
  updateActivitiesUserNote,
  deleteActivitiesUserNote
} from '../../actions/activitiesPageActions/activitiesActions.js';
import { clearSelection } from '../../actions/activitiesPageActions/selectActivityActions.js';
import { clearScannerRegisterPage } from '../../actions/activitiesPageActions/scannerRegisterActions.js';
import { activities } from '../../apis/backend.js';
// import notificationAudio from '../../audio/notification.mp3';

class Activities extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/newslog/recent', (resData) => {
        this.props.populateActivitiesNews(resData);
      });
      await this.props.io.get('/users', (resData) => {
        this.props.populateActivitiesUsers(resData);
      });
      await this.props.io.get('/inventory/report', (resData) => {
        this.props.populateActivitiesInventory(resData);
      });
      await this.props.io.get('/users/checkedIn', (resData) => {
        this.props.updateActivitiesAttendance(resData);
      });

      this.props.io.on('attendanceupdate', (event) => {
        this.props.updateActivitiesAttendance([event, ...this.props.attendance]);
      });

      this.props.io.on('usercreate', (event) => {
        this.props.createActivitiesUser(event);
      });
      this.props.io.on('userupdate', (event) => {
        this.props.updateActivitiesUser(event);
      });
      this.props.io.on('userdelete', (event) => {
        this.props.deleteActivitiesUser(event);
      });

      this.props.io.on('inventorycreate', (event) => {
        this.props.createActivitiesInventory(event);
      });
      this.props.io.on('inventoryupdate', (event) => {
        this.props.updateActivitiesInventory(event);
      });
      this.props.io.on('inventorydelete', (event) => {
        this.props.deleteActivitiesInventory(event);
      });

      this.props.io.on('activitylogcreate', (event) => {
        this.props.updateActivitiesFeedItems(event);
        if (this.props.activeTab === 1) {
          this.props.incrementActivityUnreadTally();
          // this.playSound(notificationAudio, 0.5);
        }
      });

      this.props.io.on('newslogcreate', (event) => {
        this.props.updateActivitiesNewsItems(event);
        this.props.incrementNewsUnreadTally();
        // this.playSound(notificationAudio, 0.5);
      });
      this.props.io.on('newslogupdate', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateActivitiesNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogdelete', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateActivitiesNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogread', async (event) => {
        this.props.decrementNewsUnreadTally();
        await this.props.io.get('/newslog/recent', (resData) => {
          this.props.populateActivitiesNews(resData);
        });
      });

      // this.props.io.on('checkinafterpurchase', (event) => {
      //   if (event.clearModal) {
      //     this.props.clearSkateparkCheckinModal();
      //   } else if (event.name && event.userId) {
      //     this.props.updateSkateparkCheckinModalName(event.name);
      //     this.props.updateSkateparkCheckinModalId(event.userId);
      //     setTimeout(() => {
      //       this.props.updateSkateparkCheckinModalVisibility(true);
      //     }, 1000);
      //   }
      // });

      await this.props.io.get('/usernotes', (resData) => {
        this.props.populateActivitiesUserNotes(resData);
      });
      this.props.io.on('usernotescreate', (event) => {
        this.props.createActivitiesUserNote(event);
      });
      this.props.io.on('usernotesupdate', (event) => {
        this.props.updateActivitiesUserNote(event);
      });
      this.props.io.on('usernotesdelete', (event) => {
        this.props.deleteActivitiesUserNote(event);
      });
    } catch (err) {
      console.log(err);
    }
  }

  getActivityName() {
    var fullName = this.props.activityName;

    if (this.props.details.length > 0) {
      const sortedDetails = this.props.details.sort((a, b) => {
        return a.activityDetail.localeCompare(b.activityDetail);
      });
      const detailNames = sortedDetails.map((detail) => detail.activityDetail);
      if (detailNames.length === 1) {
        fullName = fullName + ' - ' + detailNames.join('');
      } else {
        fullName = fullName + ' - ' + detailNames.join(', ');
      }
    }

    if (this.props.providerId !== 0) {
      fullName = fullName + ' (' + this.props.providerName + ')';
    }
    fullName = fullName + ', ' + this.props.cityName + ' - ' + this.props.siteName;

    return fullName;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loaded &&
      this.props.usersLoaded &&
      this.props.feedItemsLoaded &&
      this.props.newsItemsLoaded &&
      this.props.inventoryLoaded &&
      this.props.userNotesLoaded
    ) {
      this.props.initializeActivities();
    }
    if (
      (prevProps.cityId !== this.props.cityId && this.props.cityId !== 0) ||
      (prevProps.programId !== this.props.programId && this.props.programId !== 0) ||
      (prevProps.activityId !== this.props.activityId && this.props.activityId !== 0) ||
      (prevProps.siteId !== this.props.siteId && this.props.siteId !== 0) ||
      prevProps.detailIds !== this.props.detailIds ||
      (prevProps.providerId !== this.props.providerId && this.props.providerId !== 0)
    ) {
      const activityTitle = this.getActivityName();
      // console.log('Activity Title: ' + activityTitle);
      await this.props.io.get(
        `/activities/recent`,
        {
          activityTitle: activityTitle,
          activity: this.props.activityId,
          city: this.props.cityId,
          site: this.props.siteId
        },
        (resData) => {
          this.props.populateActivitiesFeed(resData);
        }
      );
    } else if (
      this.props.activityId === 0 &&
      this.props.cityId === 0 &&
      this.props.siteId === 0 &&
      this.props.detailIds.length === 0 &&
      this.props.providerId === 0 &&
      !this.props.feedItemsLoaded
    ) {
      this.props.populateActivitiesFeed([]);
    }
  }

  componentWillUnmount() {
    try {
      this.props.io.off('activitylogcreate');
      this.props.io.off('newslogcreate');
      this.props.io.off('newslogupdate');
      this.props.io.off('newslogdelete');
      this.props.io.off('newslogread');
      this.props.io.off('inventorydelete');
      this.props.io.off('inventoryupdate');
      this.props.io.off('inventorycreate');
      this.props.io.off('userdelete');
      this.props.io.off('userupdate');
      this.props.io.off('usercreate');
      this.props.io.off('usernotescreate');
      this.props.io.off('usernotesupdate');
      this.props.io.off('usernotesdelete');
      this.props.clearScannerRegisterPage();
      this.props.clearActivitiesPage();
      this.props.clearSelection();
    } catch (err) {
      console.log(err);
    }
    // if (this.state.interval) {
    //   clearInterval(this.state.interval);
    // }
  }

  async attendanceRequest(id) {
    const activityTitle = this.getActivityName();
    await activities
      .post(
        '/user',
        {
          id,
          activityTitle: activityTitle,
          activity: this.props.activityId,
          city: this.props.cityId,
          site: this.props.siteId,
          provider: this.props.providerId,
          details: this.props.detailIds
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((err) => console.log(err));
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Router>
        <Switch>
          <Route path="/activities/home/register" component={Register} />
          <Route
            path="/activities/home/checkin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Check-In',
                  clickEvent: (e) => this.attendanceRequest(e),
                  redirect: '/activities/home'
                }}
              ></Checkin>
            )}
          />
          {/* <Route
            path="/activities/purchasecheckin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Continue',
                  redirectId: '/activities/purchase'
                }}
              ></Checkin>
            )}
          /> */}
          <Route path="/activities/purchase/:id" component={Purchase} />
          <Route
            path="/activities/rentalscheckin"
            render={(props) => (
              <Checkin
                {...props}
                userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/activities/rentals'
                }}
              ></Checkin>
            )}
          />
          <Route
            path="/activities/notescheckin"
            render={(props) => (
              <Checkin
                {...props}
                // userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/activities/notes'
                }}
              ></Checkin>
            )}
          />
          <Route exact path="/activities" component={SelectActivity} />
          <Route exact path="/activities/adduser" component={AddUser} />
          <Route path="/activities/rentals/:id" component={Rentals} />
          <Route path="/activities/inventory/edit/:id" component={Edit} />
          <Route path="/activities/notes/:userId" component={Notes} />
          <Route path="/activities/inventory" component={Inventory} />
          <Route exact path="/activities/rfid" component={RFIDRegister} />
          <Route exact path="/activities/rfid/scan/:id" component={RFIDScan} />
          <Route exact path="/activities/scanner" component={ScannerRegister} />
          <Route path="/activities/home" component={HomePage} />
          <Route exact path="/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  connected: state.application.connected,
  loaded: state.activities.activities.loaded,
  feedItemsLoaded: state.activities.activities.feedItemsLoaded,
  newsItemsLoaded: state.activities.activities.newsItemsLoaded,
  usersLoaded: state.activities.activities.usersLoaded,
  inventoryLoaded: state.activities.activities.inventoryLoaded,
  userNotesLoaded: state.activities.activities.userNotesLoaded,
  activeTab: state.activities.activities.activeTab,
  cityName: state.activities.selectActivity.activityCity,
  cityId: state.activities.selectActivity.activityCitySelected,
  programName: state.activities.activities.program,
  programId: state.activities.activities.programSelected,
  activityName: state.activities.selectActivity.activityName,
  activityId: state.activities.selectActivity.activitySelected,
  siteName: state.activities.selectActivity.activitySite,
  siteId: state.activities.selectActivity.activitySiteSelected,
  details: state.activities.selectActivity.activityDetails,
  detailIds: state.activities.selectActivity.activityDetailsSelected,
  providerName: state.activities.selectActivity.activityProviders,
  providerId: state.activities.selectActivity.activityProvidersSelected,
  ticketAttendanceInterval: state.activities.activities.ticketAttendanceInterval,
  cashAttendanceInterval: state.activities.activities.cashAttendanceInterval,
  attendance: state.activities.activities.attendance,
  employeeToken: state.auth.employeeToken,
  newsItems: state.activities.activities.newsItems
});

export default connect(mapStateToProps, {
  populateActivitiesFeed,
  populateActivitiesNews,
  updateActivitiesFeedItems,
  populateActivitiesUsers,
  createActivitiesUser,
  updateActivitiesUser,
  deleteActivitiesUser,
  updateActivitiesAttendance,
  populateActivitiesInventory,
  createActivitiesInventory,
  updateActivitiesInventory,
  deleteActivitiesInventory,
  clearActivitiesPage,
  initializeActivities,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  getAllActivities,
  getAllCities,
  getAllSites,
  getAllDetails,
  getAllPartners,
  getAllProviders,
  setCurrentActivity,
  decrementNewsUnreadTally,
  createActivity,
  updateActivity,
  deleteActivity,
  updateActivitiesNewsItems,
  populateActivitiesUserNotes,
  createActivitiesUserNote,
  updateActivitiesUserNote,
  deleteActivitiesUserNote,
  clearSelection,
  clearScannerRegisterPage
})(Activities);
