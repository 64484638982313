import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { lock } from '../actions/authActions';
import Loading from '../components/loading/loading';

class UnlockedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      locksAt: new Date(new Date().getTime() + 5 * 60 * 1000),
      interval: 0
    };
  }

  resetTimer = () => {
    this.setState({ locksAt: new Date(new Date().getTime() + 5 * 60 * 1000) });
  };

  componentDidMount() {
    const interval = setInterval(() => {
      if (window.location.pathname.includes('/register')) {
        this.resetTimer();
      } else if (new Date() > this.state.locksAt) {
        this.props.lock();
      }
    }, 10000);
    this.setState({ interval, isLoaded: true });

    window.addEventListener('mousedown', this.resetTimer);
    window.addEventListener('touchstart', this.resetTimer);
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    window.removeEventListener('mousedown', this.resetTimer);
    window.removeEventListener('touchstart', this.resetTimer);
  }

  render() {
    if (!this.state.isLoaded) {
      return <Loading />;
    }
    if (this.props.isUnlocked) {
      return <Route component={this.props.component} />;
    }
    return <Redirect to={'/keypad'} />;
  }
}

const mapStateToProps = (state) => ({
  isUnlocked: state.auth.isUnlocked
});

export default connect(mapStateToProps, { lock })(UnlockedRoute);
