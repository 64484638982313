import React, { Component } from 'react';
import '../../Modal.css';
import { newsLogEmployeeUser } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedNews } from '../../../../actions/adminActions/adminNewsActions';

class ReadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: []
    };
  }

  clearState() {
    this.setState({
      news: []
    });
  }

  renderReadStatus() {
    return (
      <>
        {this.state.news
          .sort((a, b) => {
            if (a.read && !b.read) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((news) => {
            const employee = this.props.employees.find((emp) => emp.id === news.employeeUser);
            return (
              <tr key={employee.id}>
                <td className="text-center">{`${employee.firstName} ${employee.lastName}`}</td>
                <td className="text-center">{news.read ? 'Read' : 'Unread'}</td>
              </tr>
            );
          })}
      </>
    );
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (prevProps.selectedNews !== this.props.selectedNews && this.props.selectedNews !== 0) {
      this.getLogByNewsId();
    }
    if (this.props.selectedNews === 0 && prevProps.selectedNews !== this.props.selectedNews) {
      this.setState({ news: [] });
    }
  }

  async getLogByNewsId() {
    await newsLogEmployeeUser
      .get(`/findbynewsid/${this.props.selectedNews}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .then((res) => {
        this.setState({ news: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div id="readModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Employee Read Status</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedNews(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table
                className="table table-striped table-bordered table-hover bg-white mr-3"
                id="tab_logic"
              >
                <thead className=" bg-dark thead-dark">
                  <tr>
                    <th>Employee</th>
                    <th>Read?</th>
                  </tr>
                </thead>
                <tbody>{this.renderReadStatus()}</tbody>
              </table>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.props.updateSelectedNews(0)}
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNews: state.admin.news.selectedNews,
  employees: state.admin.admin.employees,
  newsLogEmployeeUsers: state.admin.admin.newsLogEmployeeUsers,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedNews })(ReadModal);
