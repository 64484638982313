import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedActivityDetail } from '../../../../actions/adminActions/activityDetailsActions';
import { activityDetails } from '../../../../apis/backend';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      activityDetail: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedActivityDetail !== this.props.selectedActivityDetail &&
      this.props.selectedActivityDetail !== 0
    ) {
      const activityDetail = this.props.activityDetails.find(
        (obj) => obj.id === this.props.selectedActivityDetail
      );
      if (activityDetail) {
        this.setState({
          activityDetail: activityDetail.activityDetail
        });
      }
    }
  }

  clearState() {
    this.setState({
      id: '',
      activityDetail: ''
    });
  }

  async activityDetailRequest() {
    try {
      await activityDetails
        .patch(
          `/${this.props.selectedActivityDetail}`,
          {
            activityDetail: this.state.activityDetail
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Activity Detail</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Activity Detail
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.activityDetail}
                  onChange={(e) => this.setState({ activityDetail: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.activityDetailRequest();
                }}
                disabled={!this.state.activityDetail != ''}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activityDetails: state.admin.admin.activityDetails,
  selectedActivityDetail: state.admin.activityDetails.selectedActivityDetail,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedActivityDetail })(EditModal);
