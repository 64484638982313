const INITIAL_STATE = {
  skatePunchFilter: '',
  selectedSkatePunch: 0
};

export const skatePunchesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_SKATEPUNCHES_PAGE_FILTER_STRING':
      return {
        ...state,
        skatePunchFilter: action.payload
      };
    case 'UPDATE_ADMIN_SKATEPUNCHES_PAGE_SELECTED_SKATEPUNCHES':
      return {
        ...state,
        selectedSkatePunch: action.payload
      };
    default:
      return state;
  }
};
