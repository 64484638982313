export const updateNewsFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_NEWS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedNews = (news) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_NEWS_PAGE_SELECTED_NEWS', payload: news });
};
