const INITIAL_STATE = {
  membershipFilter: '',
  selectedMembership: 0,
  discountSelected: {
    membership: 0,
    item: 0,
    type: ''
  }
};

export const membershipsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_MEMBERSHIP_PAGE_FILTER_STRING':
      return {
        ...state,
        membershipFilter: action.payload
      };
    case 'UPDATE_ADMIN_MEMBERSHIP_PAGE_SELECTED_MEMBERSHIP':
      return {
        ...state,
        selectedMembership: action.payload
      };
    case 'UPDATE_ADMIN_MEMBERSHIP_PAGE_UPDATE_DISCOUNTS':
      return {
        ...state,
        discountSelected: {
          membership: action.payload.membership,
          item: action.payload.item,
          type: action.payload.type
        }
      };
    default:
      return state;
  }
};
