import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { loginReducer } from './loginReducer';
import { keypadReducer } from './keypadReducer';

// Skatepark
import { skateparkReducer } from './skateparkPageReducers/skateparkReducer';
import { skateparkHomeReducer } from './skateparkPageReducers/homeReducer';
import { skateparkPurchaseReducer } from './skateparkPageReducers/purchaseReducer';
import { skateparkCheckinReducer } from './skateparkPageReducers/checkinReducer';
import { skateparkRentalsReducer } from './skateparkPageReducers/rentalReducer';
import { skateparkInventoryReducer } from './skateparkPageReducers/inventoryReducer';
import { skateparkRegisterRFIDReducer } from './skateparkPageReducers/rfidRegisterReducer';
import { skateparkPopupReducer } from './skateparkPageReducers/skateparkPopupReducer';

// Collective
import { collectiveReducer } from './collectivePageReducers/collectiveReducer';
import { collectiveHomeReducer } from './collectivePageReducers/homeReducer';
import { collectiveCheckinReducer } from './collectivePageReducers/checkinReducer';
import { collectiveRentalsReducer } from './collectivePageReducers/rentalReducer';
import { collectiveInventoryReducer } from './collectivePageReducers/inventoryReducer';
import { collectiveRegisterRFIDReducer } from './collectivePageReducers/rfidRegisterReducer';
import { collectiveInventoryEditPopupReducer } from './collectivePageReducers/collectiveInventoryEditPopupReducer';

// Events
import { eventsPageReducer } from './eventsPageReducers/eventsPageReducer';
import { eventsHomeReducer } from './eventsPageReducers/homeReducer';
import { eventsPurchaseReducer } from './eventsPageReducers/purchaseReducer';
import { eventsCheckinReducer } from './eventsPageReducers/checkinReducer';
import { eventsSelectReducer } from './eventsPageReducers/selectEventReducer';
import { eventsRegisterRFIDReducer } from './eventsPageReducers/rfidRegisterReducer';
import { eventsPopupReducer } from './eventsPageReducers/eventsPopupReducer';
import { eventsRentalsReducer } from './eventsPageReducers/rentalReducer';
import { eventsInventoryReducer } from './eventsPageReducers/inventoryReducer';

// Activities
import { activitiesPageReducer } from './activitiesPageReducers/activitiesPageReducer';
import { activitiesHomeReducer } from './activitiesPageReducers/homeReducer';
import { activitiesPurchaseReducer } from './activitiesPageReducers/purchaseReducer';
import { activitiesCheckinReducer } from './activitiesPageReducers/checkinReducer';
import { activitiesSelectReducer } from './activitiesPageReducers/selectActivityReducer';
import { activitiesRegisterRFIDReducer } from './activitiesPageReducers/rfidRegisterReducer';
import { activitiesPopupReducer } from './activitiesPageReducers/activitiesPopupReducer';
import { activitiesRentalsReducer } from './activitiesPageReducers/rentalReducer';
import { activitiesInventoryReducer } from './activitiesPageReducers/inventoryReducer';
import { scannerRegisterReducer } from './activitiesPageReducers/scannerRegisterReducer';

// Coffee
import { coffeeReducer } from './coffeePageReducers/coffeeReducer';
import { coffeeHomeReducer } from './coffeePageReducers/homeReducer';
import { coffeeCheckinReducer } from './coffeePageReducers/checkinReducer';

// Admin
import { systemUsersReducer } from './adminReducers/adminSystemUsersReducer';
import { membershipsReducer } from './adminReducers/adminMembershipsReducer';
import { usersReducer } from './adminReducers/adminUsersReducers';
import { skatePunchesReducer } from './adminReducers/adminSkatePunchesReducer';
import { employeesReducer } from './adminReducers/adminEmployeesReducer';
import { eventsReducer } from './adminReducers/adminEventsReducer';
import { citiesReducer } from './adminReducers/adminCitiesReducer';
import { sitesReducer } from './adminReducers/adminSitesReducer';
import { programsReducer } from './adminReducers/adminProgramsReducer';
import { partnersReducer } from './adminReducers/adminPartnersReducer';
import { partnerActivitiesReducer } from './adminReducers/adminPartnerActivitiesReducer';
import { activitiesReducer } from './adminReducers/adminActivitiesReducer';
import { activityDetailsReducer } from './adminReducers/adminActivityDetailsReducer';
import { providersReducer } from './adminReducers/adminProvidersReducer';
import { scannersReducer } from './adminReducers/scannersReducer';
import { adminReducer } from './adminReducers/adminReducer';
import { newsReducer } from './adminReducers/adminNewsReducer';
import { authReducer } from './authReducer';
import { inventoryReducer } from './adminReducers/adminInventoryReducer';
import { inventoryTypeReducer } from './adminReducers/adminInventoryTypeReducer';
import { purchaseLogReducer } from './adminReducers/adminPurchaseLogsReducer';
import { accessReducer } from './adminReducers/adminAccessReducer';
import { mealSwipesAllowanceReducer } from './adminReducers/adminMealSwipesAllowanceReducer';
import { reportingReducer } from './adminReducers/adminReportingReducer';

export default combineReducers({
  auth: authReducer,
  keypad: keypadReducer,
  application: appReducer,
  login: loginReducer,
  skatepark: combineReducers({
    skatepark: skateparkReducer,
    home: skateparkHomeReducer,
    purchase: skateparkPurchaseReducer,
    rentals: skateparkRentalsReducer,
    checkin: skateparkCheckinReducer,
    inventory: skateparkInventoryReducer,
    rfidRegister: skateparkRegisterRFIDReducer,
    skateparkPopup: skateparkPopupReducer
  }),
  collective: combineReducers({
    collective: collectiveReducer,
    home: collectiveHomeReducer,
    rentals: collectiveRentalsReducer,
    checkin: collectiveCheckinReducer,
    inventory: collectiveInventoryReducer,
    rfidRegister: collectiveRegisterRFIDReducer,
    collectiveInventoryEditPopup: collectiveInventoryEditPopupReducer
  }),
  events: combineReducers({
    events: eventsPageReducer,
    home: eventsHomeReducer,
    purchase: eventsPurchaseReducer,
    rentals: eventsRentalsReducer,
    checkin: eventsCheckinReducer,
    selectEvent: eventsSelectReducer,
    eventsPopup: eventsPopupReducer,
    inventory: eventsInventoryReducer,
    rfidRegister: eventsRegisterRFIDReducer
  }),
  activities: combineReducers({
    activities: activitiesPageReducer,
    home: activitiesHomeReducer,
    purchase: activitiesPurchaseReducer,
    rentals: activitiesRentalsReducer,
    checkin: activitiesCheckinReducer,
    selectActivity: activitiesSelectReducer,
    activitiesPopup: activitiesPopupReducer,
    inventory: activitiesInventoryReducer,
    rfidRegister: activitiesRegisterRFIDReducer,
    scanner: scannerRegisterReducer
  }),
  coffee: combineReducers({
    coffee: coffeeReducer,
    home: coffeeHomeReducer,
    checkin: coffeeCheckinReducer
  }),
  admin: combineReducers({
    memberships: membershipsReducer,
    users: usersReducer,
    employees: employeesReducer,
    systemUsers: systemUsersReducer,
    events: eventsReducer,
    cities: citiesReducer,
    sites: sitesReducer,
    programs: programsReducer,
    partners: partnersReducer,
    partnerActivities: partnerActivitiesReducer,
    activities: activitiesReducer,
    activityDetails: activityDetailsReducer,
    providers: providersReducer,
    scanners: scannersReducer,
    news: newsReducer,
    admin: adminReducer,
    inventory: inventoryReducer,
    inventoryTypes: inventoryTypeReducer,
    skatePunches: skatePunchesReducer,
    purchaseLogs: purchaseLogReducer,
    access: accessReducer,
    allowance: mealSwipesAllowanceReducer,
    reporting: reportingReducer
  })
});
