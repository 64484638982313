const INITIAL_STATE = {
  selectedReport: ''
};

export const reportingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_REPORTING_PAGE_SELECTED_REPORT':
      return {
        ...state,
        selectedReport: action.payload
      };
    default:
      return state;
  }
};
