import axios from 'axios';

let url = 'http://localhost:1337';
if (process.env.REACT_APP_ENV === 'prod') {
  url = 'https://api.thebay.org';
} else if (process.env.REACT_APP_ENV === 'test') {
  url = 'https://testapi.thebay.org';
}

export const users = axios.create({
  baseURL: url + '/users'
});

export const attendancelog = axios.create({
  baseURL: url + '/attendancelog'
});

export const activitylog = axios.create({
  baseURL: url + '/activitylog'
});

export const activitylogPurchase = axios.create({
  baseURL: url + '/activitylogpurchase'
});

export const inventory = axios.create({
  baseURL: url + '/inventory'
});

export const inventoryStatus = axios.create({
  baseURL: url + '/inventorystatus'
});

export const rentals = axios.create({
  baseURL: url + '/rentals'
});

export const purchaseOptions = axios.create({
  baseURL: url + '/purchaseoptions'
});

export const rentalsOptions = axios.create({
  baseURL: url + '/rentalsoptions'
});

export const memberships = axios.create({
  baseURL: url + '/memberships'
});

export const inventoryType = axios.create({
  baseURL: url + '/inventorytype'
});

export const systemAuth = axios.create({
  baseURL: url + '/systemuser'
});

export const employeeAuth = axios.create({
  baseURL: url + '/employeeuser'
});

export const systemEmployee = axios.create({
  baseURL: url + '/sysempuser'
});

export const usersMemberships = axios.create({
  baseURL: url + '/usersmemberships'
});

export const usersAccess = axios.create({
  baseURL: url + '/usersaccess'
});

export const usersSkatePunches = axios.create({
  baseURL: url + '/usersskatepunches'
});

export const newsLogEmployeeUser = axios.create({
  baseURL: url + '/newslogemployeeuser'
});

export const newsLog = axios.create({
  baseURL: url + '/newslog'
});

export const events = axios.create({
  baseURL: url + '/events'
});

export const usersEvents = axios.create({
  baseURL: url + '/usersevents'
});

export const raspberrypi = axios.create({
  baseURL: url + '/raspberrypi'
});

export const skatePunches = axios.create({
  baseURL: url + '/skatepunches'
});

export const purchaseLogs = axios.create({
  baseURL: url + '/purchaselog'
});

export const access = axios.create({
  baseURL: url + '/access'
});

export const mealSwipeAllowance = axios.create({
  baseURL: url + '/allowance'
});

export const bypassPurchase = axios.create({
  baseURL: url + '/bypasspurchase'
});

export const membershipsInventoryType = axios.create({
  baseURL: url + '/membershipsinventorytype'
});

export const membershipsskatepunches = axios.create({
  baseURL: url + '/membershipsskatepunches'
});

export const membershipsaccess = axios.create({
  baseURL: url + '/membershipsaccess'
});

export const sysempaccess = axios.create({
  baseURL: url + '/sysempuser'
});

export const reporting = axios.create({
  baseURL: url + '/reporting'
});

export const usernotes = axios.create({
  baseURL: url + '/usernotes'
});

export const noteflags = axios.create({
  baseURL: url + '/noteflags'
});

export const cities = axios.create({
  baseURL: url + '/cities'
});

export const sites = axios.create({
  baseURL: url + '/sites'
});

export const activityDetails = axios.create({
  baseURL: url + '/activitydetails'
});

export const providers = axios.create({
  baseURL: url + '/providers'
});

export const partners = axios.create({
  baseURL: url + '/partners'
});

export const activities = axios.create({
  baseURL: url + '/activities'
});

export const activityCities = axios.create({
  baseURL: url + '/activitiescities'
});

export const activitySites = axios.create({
  baseURL: url + '/activitiessites'
});

export const activityActivityDetails = axios.create({
  baseURL: url + '/activitiesactivitydetails'
});

export const activityProviders = axios.create({
  baseURL: url + '/activitiesproviders'
});

export const activityPartners = axios.create({
  baseURL: url + '/activitiespartners'
});

export const programs = axios.create({
  baseURL: url + '/programs'
});

export const programActivities = axios.create({
  baseURL: url + '/programsactivities'
});

export const permissionlevels = axios.create({
  baseURL: url + '/permissionlevels'
});

// export const smartwaiver = axios.create({
//   baseURL: url + '/smartwaiver',
// });
