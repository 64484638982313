export const updateInventoryFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_INVENTORY_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedInventory = (inventory) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_INVENTORY_PAGE_SELECTED_INVENTORY',
    payload: inventory
  });
};
