import React from 'react';
import './collective.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './home/home';
import Register from './register/register';
import Checkin from './checkin/checkin';
import Inventory from './inventory/inventory';
import Edit from './inventory/edit/edit';
import Rentals from './rentals/rentals';
import Loading from '../loading/loading';
import NotFound from '../404/404';
import RFIDRegister from '../collective/rfid-register/rfid-register';
import RFIDScan from '../collective/rfid-register/rfid-scan';
import Login from '../../components/login/login.jsx';
import {
  populateCollectiveFeed,
  populateCollectiveNews,
  updateCollectiveFeedItems,
  populateCollectiveUsers,
  createCollectiveUser,
  updateCollectiveUser,
  deleteCollectiveUser,
  populateCollectiveInventory,
  createCollectiveInventory,
  updateCollectiveInventory,
  deleteCollectiveInventory,
  clearCollectivePage,
  initializeCollective,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  decrementNewsUnreadTally,
  updateCollectiveAttendance,
  updateCollectiveNewsItems
} from '../../actions/collectivePageActions/collectiveActions';
import { attendancelog } from '../../apis/backend';
import notificationAudio from '../../audio/notification.mp3';

class Collective extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/activitylog/recent', (resData) => {
        this.props.populateCollectiveFeed(resData);
      });
      await this.props.io.get('/newslog/recent', (resData) => {
        this.props.populateCollectiveNews(resData);
      });
      await this.props.io.get('/users', (resData) => {
        this.props.populateCollectiveUsers(resData);
      });
      await this.props.io.get('/inventory/report', (resData) => {
        this.props.populateCollectiveInventory(resData);
      });
      await this.props.io.get('/users/checkedIn', (resData) => {
        this.props.updateCollectiveAttendance(resData);
      });

      this.props.io.on(`attendanceupdate`, (event) => {
        this.props.updateCollectiveAttendance([event, ...this.props.attendance]);
      });

      this.props.io.on('usercreate', (event) => {
        this.props.createCollectiveUser(event);
      });
      this.props.io.on('userupdate', (event) => {
        this.props.updateCollectiveUser(event);
      });
      this.props.io.on('userdelete', (event) => {
        this.props.deleteCollectiveUser(event);
      });
      this.props.io.on('inventorycreate', (event) => {
        this.props.createCollectiveInventory(event);
      });
      this.props.io.on('inventoryupdate', (event) => {
        this.props.updateCollectiveInventory(event);
      });
      this.props.io.on('inventorydelete', (event) => {
        this.props.deleteCollectiveInventory(event);
      });
      this.props.io.on('activitylogcreate', (event) => {
        this.props.updateCollectiveFeedItems(event);
        if (this.props.activeTab === 1) {
          this.props.incrementActivityUnreadTally();
          // this.playSound(notificationAudio, 0.5);
        }
      });
      this.props.io.on('newslogcreate', (event) => {
        this.props.updateCollectiveNewsItems(event);
        this.props.incrementNewsUnreadTally();
        // this.playSound(notificationAudio, 0.5);
      });
      this.props.io.on('newslogupdate', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateCollectiveNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogdelete', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateCollectiveNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogread', async (event) => {
        this.props.decrementNewsUnreadTally();
        await this.props.io.get('/newslog/recent', (resData) => {
          this.props.populateCollectiveNews(resData);
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loaded &&
      this.props.usersLoaded &&
      this.props.inventoryLoaded &&
      this.props.feedItemsLoaded &&
      this.props.newsItemsLoaded
    ) {
      this.props.initializeCollective();
    }
  }

  componentWillUnmount() {
    try {
      this.props.io.off('activitylogcreate');
      this.props.io.off('newslogcreate');
      this.props.io.off('newslogupdate');
      this.props.io.off('newslogdelete');
      this.props.io.off('newslogread');
      this.props.io.off('inventorydelete');
      this.props.io.off('inventoryupdate');
      this.props.io.off('inventorycreate');
      this.props.io.off('userdelete');
      this.props.io.off('userupdate');
      this.props.io.off('usercreate');
      // this.props.clearCollectivePage();
    } catch (err) {
      console.log(err);
    }
  }

  async attendanceRequest(id) {
    await attendancelog
      .post(
        '/user',
        {
          id,
          location: this.props.locationId
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((err) => console.log(err));
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Router>
        <Switch>
          <Route path="/collective/register" component={Register} />
          <Route
            path="/collective/checkin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Check-In',
                  clickEvent: (e) => this.attendanceRequest(e),
                  redirect: '/collective'
                }}
              ></Checkin>
            )}
          />
          <Route
            path="/collective/rentalscheckin"
            render={(props) => (
              <Checkin
                {...props}
                userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/collective/rentals'
                }}
              ></Checkin>
            )}
          />
          <Route path="/collective/rentals/:id" component={Rentals} />
          <Route path="/collective/inventory/edit/:id" component={Edit} />
          <Route path="/collective/inventory" component={Inventory} />
          <Route exact path="/collective/rfid" component={RFIDRegister} />
          <Route exact path="/collective/rfid/scan/:id" component={RFIDScan} />
          <Route exact path="/collective" component={HomePage} />
          <Route exact path="/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  connected: state.application.connected,
  loaded: state.collective.collective.loaded,
  feedItemsLoaded: state.collective.collective.feedItemsLoaded,
  newsItemsLoaded: state.collective.collective.newsItemsLoaded,
  usersLoaded: state.collective.collective.usersLoaded,
  inventoryLoaded: state.collective.collective.inventoryLoaded,
  activeTab: state.collective.collective.activeTab,
  locationId: state.auth.sysLocationId,
  attendance: state.collective.collective.attendance,
  employeeToken: state.auth.employeeToken,
  newsItems: state.collective.collective.newsItems
});

export default connect(mapStateToProps, {
  populateCollectiveFeed,
  populateCollectiveNews,
  updateCollectiveFeedItems,
  populateCollectiveUsers,
  createCollectiveUser,
  updateCollectiveUser,
  deleteCollectiveUser,
  populateCollectiveInventory,
  createCollectiveInventory,
  updateCollectiveInventory,
  deleteCollectiveInventory,
  clearCollectivePage,
  initializeCollective,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  decrementNewsUnreadTally,
  updateCollectiveAttendance,
  updateCollectiveNewsItems
})(Collective);
