import React from 'react';
import './rfid-register.css';
import {
  updateName,
  updateSelected,
  clearSelection
} from '../../../actions/eventsPageActions/checkinActions';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';

class RFIDRegister extends React.Component {
  componentWillUnmount() {
    this.props.clearSelection();
  }

  renderCheckInOptions() {
    const checkinOptions = {
      columns: [{ width: 75 }, { width: 25 }],
      titles: ['Name', 'Phone Number'],
      entries: [],
      selectEvent: this.props.updateSelected,
      selected: this.props.selected
    };
    this.props.users
      .filter((user) => {
        const fullname = `${user.firstName} ${user.lastName}`;
        return fullname.toLowerCase().indexOf(this.props.name.toLowerCase()) > -1;
      })
      .forEach((user) => {
        checkinOptions.entries.push({
          id: user.id,
          columns: [`${user.firstName} ${user.lastName}`, user.phoneNumber]
        });
      });
    return checkinOptions;
  }

  renderCheckInButtons() {
    return {
      left: {
        title: 'Back',
        onClick: () => this.props.history.push('/events/home')
      },
      right: {
        title: 'Select',
        onClick: () => this.props.history.push(`/events/rfid/scan/${this.props.selected}`),
        disabled: this.props.selected === 0
      }
    };
  }

  render() {
    return (
      <Page>
        <ScrollView
          options={this.renderCheckInOptions()}
          buttons={this.renderCheckInButtons()}
          searchBar={{
            filterString: this.props.name,
            onChange: this.props.updateName
          }}
        />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.events.checkin.name,
  users: state.events.events.users,
  selected: state.events.checkin.selected
});

export default connect(mapStateToProps, {
  updateName,
  updateSelected,
  clearSelection
})(RFIDRegister);
