import React, { Component } from 'react';
import './../../Modal.css';
import { users, usersMemberships, usersAccess } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedUser } from '../../../../actions/adminActions/usersActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      dateOfBirth: '',
      city: '',
      state: '',
      zipcode: '',
      address1: '',
      address2: '',
      emergencyContact1: '',
      emergencyContact2: '',
      medicalNotes: '',
      pronoun: 'N/A',
      gender: 'N/A',
      race: '',
      ethnicity: '',
      grade: '',
      school: '',
      allAccess: 0,
      allAccessId: 0,
      collectiveAccess: 0,
      collectiveAccessId: 0,
      // AAP fields (not required)
      respondant: '',
      respondantKnowledge: '',
      respondantMentioner: '',
      applicantBenefit: '',
      reducedLunch: 'No',
      juvenileJustice: 'No',
      applicantInterests: '',
      mentoring: 0,
      transportationMode: '',
      digitalArtInterests: '',
      guardianName: '',
      guardianRelationship: '',
      guardianPhone: '',
      guardianEmail: '',
      guardianAddress: ''
    };
  }

  clearState() {
    this.setState({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      dateOfBirth: '',
      city: '',
      state: '',
      zipcode: '',
      address1: '',
      address2: '',
      emergencyContact1: '',
      emergencyContact2: '',
      medicalNotes: '',
      pronoun: 'N/A',
      gender: 'N/A',
      race: '',
      ethnicity: '',
      grade: '',
      school: '',
      allAccess: 0,
      allAccessId: 0,
      collectiveAccess: 0,
      collectiveAccessId: 0,
      // AAP fields (not required)
      respondant: '',
      respondantKnowledge: '',
      respondantMentioner: '',
      applicantBenefit: '',
      reducedLunch: 'No',
      juvenileJustice: 'No',
      applicantInterests: '',
      mentoring: 0,
      transportationMode: '',
      digitalArtInterests: '',
      guardianName: '',
      guardianRelationship: '',
      guardianPhone: '',
      guardianEmail: '',
      guardianAddress: ''
    });
  }

  async userUpdateRequest() {
    const aapFields = {};
    if (this.state.allAccess) {
      aapFields.respondant = this.state.respondant;
      aapFields.respondantKnowledge = this.state.respondantKnowledge;
      aapFields.respondantMentioner = this.state.respondantMentioner;
      aapFields.applicantBenefit = this.state.applicantBenefit;
      aapFields.applicantInterests = this.state.applicantInterests;
      aapFields.mentoring = this.state.mentoring;
      aapFields.transportationMode = this.state.transportationMode;
      aapFields.digitalArtInterests = this.state.digitalArtInterests;
      aapFields.guardianName = this.state.guardianName;
      aapFields.guardianRelationship = this.state.guardianRelationship;
      aapFields.guardianPhone = this.state.guardianPhone;
      aapFields.guardianEmail = this.state.guardianEmail;
      aapFields.guardianAddress = this.state.guardianAddress;
    }
    await users
      .patch(
        `/${this.props.selectedUser}`,
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          dateOfBirth: this.state.dateOfBirth,
          phoneNumber: this.state.phone,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zipcode,
          address1: this.state.address1,
          address2: this.state.address2,
          email: this.state.email,
          emergencyContact1: this.state.emergencyContact1,
          emergencyContact2: this.state.emergencyContact2,
          medicalNotes: this.state.medicalNotes,
          pronoun: this.state.pronoun,
          gender: this.state.gender,
          race: this.state.race,
          ethnicity: this.state.ethnicity,
          grade: this.state.grade,
          school: this.state.school,
          aapData: JSON.stringify(aapFields),
          reducedLunch: this.state.reducedLunch,
          juvenileJustice: this.state.juvenileJustice
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      // .then(() => {
      //   this.setState({
      //     firstName: '',
      //     lastName: '',
      //     phone: '',
      //     email: '',
      //     dateOfBirth: '',
      //     city: '',
      //     state: '',
      //     zipcode: '',
      //     address1: '',
      //     address2: '',
      //     emergencyContact1: '',
      //     emergencyContact2: '',
      //     medicalNotes: '',
      //     pronoun: 'N/A',
      //     gender: 'N/A',
      //     ethnicity: '',
      //     grade: '',
      //     school: '',
      //   });
      // })
      .catch((response) => {
        console.log(response);
      });

    if (this.state.allAccessId !== 0 && this.state.allAccess === 0) {
      // Expire All Access Pass
      await usersMemberships
        .patch(
          `/expire/${this.state.allAccessId}`,
          {},
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => this.setState({ allAccess: 0, allAccessId: 0 }))
        .catch((err) => console.log(err));
    } else if (this.state.allAccessId === 0 && this.state.allAccess !== 0) {
      // Create All Access Pass
      const allAccess = this.props.memberships.find((obj) => obj.accessName === 'All Access Pass');
      await usersMemberships
        .post(
          '/',
          {
            user: this.props.selectedUser,
            membership: allAccess.id
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => this.setState({ allAccess: 0, allAccessId: 0 }))
        .catch((err) => console.log(err));
    }

    if (this.state.collectiveAccessId !== 0 && this.state.collectiveAccess === 0) {
      // Expire Collective Access
      await usersAccess
        .patch(
          `/expire/${this.state.collectiveAccessId}`,
          {},
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => this.setState({ collectiveAccess: 0, collectiveAccessId: 0 }))
        .catch((err) => console.log(err));
    } else if (this.state.collectiveAccessId === 0 && this.state.collectiveAccess !== 0) {
      // Create Collective Access
      const collectiveAccess = this.props.access.find((obj) => obj.name === 'Collective Access');
      await usersAccess
        .post(
          '/',
          {
            user: this.props.selectedUser,
            access: collectiveAccess.id
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => this.setState({ collectiveAccess: 0, collectiveAccessId: 0 }))
        .catch((err) => console.log(err));
    }

    this.clearState();
    this.props.updateSelectedUser(0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedUser !== this.props.selectedUser && this.props.selectedUser !== 0) {
      const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);
      if (user) {
        const aapFields = user.aapData === '' ? {} : JSON.parse(user.aapData);
        this.setState({
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phoneNumber,
          email: user.email,
          dateOfBirth: user.dateOfBirth,
          city: user.city,
          state: user.state,
          zipcode: user.zipcode,
          address1: user.address1,
          address2: user.address2,
          emergencyContact1: user.emergencyContact1,
          emergencyContact2: user.emergencyContact2,
          medicalNotes: user.medicalNotes,
          pronoun: user.pronoun ? user.pronoun : 'N/A',
          gender: user.gender ? user.gender : 'N/A',
          ethnicity: user.ethnicity ? user.ethnicity : '',
          grade: user.grade ? user.grade : '',
          school: user.school ? user.school : '',
          // AAP fields
          respondant: aapFields.respondant ? aapFields.respondant : '',
          respondantKnowledge: aapFields.respondantKnowledge ? aapFields.respondantKnowledge : '',
          respondantMentioner: aapFields.respondantMentioner ? aapFields.respondantMentioner : '',
          applicantBenefit: aapFields.applicantBenefit ? aapFields.applicantBenefit : '',
          reducedLunch: user.reducedLunch ? user.reducedLunch : '',
          juvenileJustice: user.juvenileJustice ? user.juvenileJustice : '',
          applicantInterests: aapFields.applicantInterests ? aapFields.applicantInterests : '',
          mentoring: aapFields.mentoring ? aapFields.mentoring : '',
          transportationMode: aapFields.transportationMode ? aapFields.transportationMode : '',
          digitalArtInterests: aapFields.digitalArtInterests ? aapFields.digitalArtInterests : '',
          guardianName: aapFields.guardianName ? aapFields.guardianName : '',
          guardianRelationship: aapFields.guardianRelationship
            ? aapFields.guardianRelationship
            : '',
          guardianPhone: aapFields.guardianPhone ? aapFields.guardianPhone : '',
          guardianEmail: aapFields.guardianEmail ? aapFields.guardianEmail : '',
          guardianAddress: aapFields.guardianAddress ? aapFields.guardianAddress : ''
        });
      }

      this.setInitialPermissions();
    }
  }

  async setInitialPermissions() {
    const current = new Date();

    // All Access Pass
    const allAccess = this.props.memberships.find((obj) => obj.accessName === 'All Access Pass');
    const userMembership = await usersMemberships
      .get('/find', {
        headers: {
          authorization: this.props.employeeToken
        },
        params: {
          user: this.props.selectedUser,
          membership: allAccess.id
        }
      })
      .catch((response) => {
        console.log(response);
      });
    if (
      !(
        !userMembership.data.length > 0 ||
        new Date(userMembership.data[0].endTime).getTime() < current.getTime()
      )
    ) {
      this.setState({
        allAccess: 1,
        allAccessId: userMembership.data[0].id
      });
    } else {
      this.setState({
        allAccess: 0,
        allAccessId: 0
      });
    }

    // Collective Access
    const collectiveAccess = this.props.access.find((obj) => obj.name === 'Collective Access');
    const userAccess = await usersAccess
      .get('/find', {
        headers: { authorization: this.props.employeeToken },
        params: {
          user: this.props.selectedUser,
          access: collectiveAccess.id
        }
      })
      .catch((response) => {
        console.log(response);
      });
    if (
      !(
        !userAccess.data.length > 0 ||
        new Date(userAccess.data[0].endTime).getTime() < current.getTime()
      )
    ) {
      this.setState({
        collectiveAccess: 1,
        collectiveAccessId: userAccess.data[0].id
      });
    } else {
      this.setState({
        collectiveAccess: 0,
        collectiveAccessId: 0
      });
    }
  }

  waiverStatus(user) {
    if (user?.smartWaiver) {
      const timeRemaining = new Date(user.smartWaiver).getTime() - new Date().getTime();
      if (timeRemaining <= 0) {
        return 'Expired on ' + new Date(user.smartWaiver).toDateString();
      } else {
        return 'Valid till ' + new Date(user.smartWaiver).toDateString();
      }
    } else {
      return 'Not filled out!';
    }
  }

  rfidStatus(user) {
    if (user?.rfid && user?.rfid !== '') {
      return `${user.rfid}`;
    } else {
      return 'None Assigned!';
    }
  }

  freeReducedStatus(user) {
    if (user?.reducedLunch && user?.reducedLunch !== '') {
      return `${user.reducedLunch}`;
    } else {
      return 'Unknown';
    }
  }

  juvenileJusticeStatus(user) {
    if (user?.juvenileJustice && user?.juvenileJustice !== '') {
      return `${user.juvenileJustice}`;
    } else {
      return 'Unknown';
    }
  }

  renderAAPFields() {
    if (this.state.allAccess) {
      return (
        <>
          <hr />
          <div className="input-group input-group-sm mb-3">
            <strong>AAP Fields</strong>
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Who filled out the form?
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.respondant}
              placeholder="..."
              onChange={(e) => this.setState({ respondant: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3" style={{ display: 'block' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                How did they find out about this form?
              </span>
            </div>
            <textarea
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.respondantKnowledge}
              placeholder="..."
              onChange={(e) => this.setState({ respondantKnowledge: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Who told them about the All-Access Pass?
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.respondantMentioner}
              placeholder="..."
              onChange={(e) => this.setState({ respondantMentioner: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3" style={{ display: 'block' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Why would the applicant benefit from having an All-Access Pass?
              </span>
            </div>
            <textarea
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.applicantBenefit}
              placeholder="..."
              onChange={(e) => this.setState({ applicantBenefit: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Does the applicant qualify for free and reduced school meals?
              </span>
            </div>
            <select
              className="form-control"
              value={this.state.reducedLunch}
              onChange={(e) => this.setState({ reducedLunch: parseInt(e.target.value) })}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Is the applicant involved in the Juvenile Justice System?
              </span>
            </div>
            <select
              className="form-control"
              value={this.state.juvenileJustice}
              onChange={(e) => this.setState({ juvenileJustice: parseInt(e.target.value) })}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
              <option value="Unknown/Prefer Not to Answer">Unknown/Prefer Not to Answer</option>
            </select>
          </div>
          <div className="input-group input-group-sm mb-3" style={{ display: 'block' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Applicant&apos;s areas of interest at The Bay
              </span>
            </div>
            <textarea
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.applicantInterests}
              placeholder="..."
              onChange={(e) => this.setState({ applicantInterests: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Is the applicant interested in any mentoring?
              </span>
            </div>
            <select
              className="form-control"
              value={this.state.mentoring}
              onChange={(e) => this.setState({ mentoring: parseInt(e.target.value) })}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                How will the applicant travel to/from The Bay?
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.transportationMode}
              placeholder="..."
              onChange={(e) => this.setState({ transportationMode: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3" style={{ display: 'block' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Applicant&apos;s digital art interests?
              </span>
            </div>
            <textarea
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.digitalArtInterests}
              placeholder="..."
              onChange={(e) => this.setState({ digitalArtInterests: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Parent / Guardian Full Name
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.guardianName}
              placeholder="..."
              onChange={(e) => this.setState({ guardianName: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Parent / Guardian Relationship
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.guardianRelationship}
              placeholder="..."
              onChange={(e) => this.setState({ guardianRelationship: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Parent / Guardian Phone Number
              </span>
            </div>
            <input
              type="tel"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.guardianPhone}
              placeholder="Phone #"
              onChange={(e) => this.setState({ guardianPhone: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Parent / Guardian Email
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.guardianEmail}
              placeholder="Email"
              onChange={(e) => this.setState({ guardianEmail: e.target.value })}
            />
          </div>
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Parent / Guardian Address
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              value={this.state.guardianAddress}
              placeholder="Email"
              onChange={(e) => this.setState({ guardianAddress: e.target.value })}
            />
          </div>
        </>
      );
    }
  }

  render() {
    const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit User</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedUser(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-wrapper-scroll-y" style={{ maxHeight: '550px' }}>
                <div className="input-group input-group-sm mb-3">
                  <strong>Waiver:</strong>&nbsp;{this.waiverStatus(user)}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <strong>RFID:</strong>&nbsp;{this.rfidStatus(user)}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <strong>Free Reduced Lunch:</strong>&nbsp;
                  {this.freeReducedStatus(user)}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <strong>In Juvenile Justice System:</strong>&nbsp;
                  {this.juvenileJusticeStatus(user)}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      First Name
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.firstName}
                    placeholder="First Name"
                    onChange={(e) => this.setState({ firstName: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Last Name
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.lastName}
                    placeholder="Last Name"
                    onChange={(e) => this.setState({ lastName: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Birthdate
                    </span>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.dateOfBirth.split('T')[0]}
                    placeholder="Last Name"
                    onChange={(e) => this.setState({ dateOfBirth: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Email
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.email}
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Phone #
                    </span>
                  </div>
                  <input
                    type="tel"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.phone}
                    placeholder="Phone #"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      City
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.city}
                    placeholder="City"
                    onChange={(e) => this.setState({ city: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      State
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.state}
                    placeholder="State"
                    onChange={(e) => this.setState({ state: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Zipcode
                    </span>
                  </div>
                  <input
                    type="Number"
                    min="0"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.zipcode}
                    placeholder="Zipcode"
                    onChange={(e) => this.setState({ zipcode: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Address Line 1
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.address1}
                    placeholder="Address #1"
                    onChange={(e) => this.setState({ address1: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Address Line 2
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.address2}
                    placeholder="Address #2"
                    onChange={(e) => this.setState({ address2: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Emergency Contact Name
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.emergencyContact1}
                    placeholder="Jon Doe"
                    onChange={(e) => this.setState({ emergencyContact1: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Emergency Contact #
                    </span>
                  </div>
                  <input
                    type="tel"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.emergencyContact2}
                    placeholder="(123) 456-7890"
                    onChange={(e) => this.setState({ emergencyContact2: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Pronouns
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.pronoun}
                    onChange={(e) => this.setState({ pronoun: e.target.value })}
                  >
                    <option value="N/A" disabled>
                      Select pronoun
                    </option>
                    <option value="He/Him">He/Him</option>
                    <option value="She/Her">She/Her</option>
                    <option value="They/Them">They/Them</option>
                    <option value="Ze">Ze</option>
                    <option value="A Pronoun Not Listed">A Pronoun Not Listed</option>
                    <option value="No Pronoun Preference">No Pronoun Preference</option>
                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                  </select>
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Gender Identity
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.gender}
                    onChange={(e) => this.setState({ gender: e.target.value })}
                  >
                    <option value="N/A" disabled>
                      Select gender
                    </option>
                    <option value="Female/Woman">Female/Woman</option>
                    <option value="Gender Fluid">Gender Fluid</option>
                    <option value="Genderqueer/Non-Binary">Genderqueer/Non-Binary</option>
                    <option value="Intersex">Intersex</option>
                    <option value="Male/Man">Male/Man</option>
                    <option value="Trans">Trans</option>
                    <option value="An Identity Not Listed">An Identity Not Listed</option>
                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                    <option value="Gender Identity Unknown">Gender Identity Unknown</option>
                  </select>
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Race
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.race}
                    placeholder="Race"
                    onChange={(e) => this.setState({ race: e.target.value })}
                  />
                  {/* <select
                    className="form-control"
                    value={this.state.race}
                    onChange={(e) => this.setState({ race: e.target.value })}
                  >
                    <option value="N/A" disabled>Select gender</option>
                    <option value='Asian'>Asian</option>
                    <option value='Black and/or African American'>Black and/or African American</option>
                    <option value='Native Ameraican/Alaskan Native'>Native Ameraican/Alaskan Native</option>
                    <option value='Pacific Islander'>Pacific Islander</option>
                    <option value='White/Caucasian'>White/Caucasian</option>
                    <option value='Bi-Racial'>Bi-Racial</option>
                    <option value='Multi-Racial'>Multi-Racial</option>
                    <option value='My Race Is Not Listed'>My Race Is Not Listed</option>
                    <option value='Prefer Not To Say'>Prefer Not To Say</option>
                    <option value='Race Unknown'>Race Unknown</option>
                  </select> */}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Ethnicity
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.ethnicity}
                    placeholder="Ethnicity"
                    onChange={(e) => this.setState({ ethnicity: e.target.value })}
                  />
                  {/* <select
                    className="form-control"
                    value={this.state.ethnicity}
                    onChange={(e) => this.setState({ ethnicity: e.target.value })}
                  >
                    <option value="N/A" disabled>Select ethnicity</option>
                    <option value='Cuban'>Cuban</option>
                    <option value='Dominican'>Dominican</option>
                    <option value='Mexican/Chicano/a'>Mexican/Chicano/a</option>
                    <option value='Non-Hispanic/Latino'>Non-Hispanic/Latino</option>
                    <option value='Puerto Rican'>Puerto Rican</option>
                    <option value='Other Hispanic/Latino'>Other Hispanic/Latino</option>
                    <option value='Prefer Not to Say'>Prefer Not to Say</option>
                    <option value='Ethnicity Unknown'>Ethnicity Unknown</option>
                  </select> */}
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Grade
                    </span>
                  </div>
                  <input
                    type="number"
                    max="12"
                    min="1"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.grade}
                    placeholder="Grade"
                    onChange={(e) => this.setState({ grade: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      School
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    value={this.state.school}
                    placeholder="School"
                    onChange={(e) => this.setState({ school: e.target.value })}
                  />
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      All Access Pass
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.allAccess}
                    onChange={(e) => this.setState({ allAccess: parseInt(e.target.value) })}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Collective Access
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.collectiveAccess}
                    onChange={(e) =>
                      this.setState({
                        collectiveAccess: parseInt(e.target.value)
                      })
                    }
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>
                {this.renderAAPFields()}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  this.clearState();
                  this.props.updateSelectedUser(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.userUpdateRequest();
                }}
                disabled={
                  !(
                    this.state.firstName !== '' &&
                    this.state.lastName !== '' &&
                    this.state.phone !== '' &&
                    this.state.email !== '' &&
                    this.state.dateOfBirth !== '' &&
                    this.state.city !== '' &&
                    this.state.state !== '' &&
                    this.state.zipcode !== '' &&
                    this.state.address1 !== '' &&
                    this.state.emergencyContact1 !== '' &&
                    this.state.pronoun !== 'N/A' &&
                    this.state.gender !== 'N/A' &&
                    this.state.ethnicity !== '' &&
                    this.state.zipcode >= 0
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  selectedUser: state.admin.users.selectedUser,
  memberships: state.admin.admin.memberships,
  access: state.admin.admin.access,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedUser })(EditModal);
