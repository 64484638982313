const INITIAL_STATE = {
  partnerActivityFilter: '',
  selectedPartnerActivity: 0
};

export const partnerActivitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_PARTNER_ACTIVITIES_PAGE_FILTER_STRING':
      return {
        ...state,
        partnerActivityFilter: action.payload
      };
    case 'UPDATE_ADMIN_PARTNER_ACTIVITIES_PAGE_SELECTED_PARTNER_ACTIVITY':
      return {
        ...state,
        selectedPartnerActivity: action.payload
      };
    default:
      return state;
  }
};
