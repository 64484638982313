export const updateScannerFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SCANNERS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedScanner = (scanner) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SCANNERS_PAGE_SELECTED_SCANNER',
    payload: scanner
  });
};

export const updateRegistrationReady = (ready) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SCANNERS_PAGE_REGISTER_SCANNER',
    payload: ready
  });
};

export const updateRaspberryPiSelected = (mac) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SCANNERS_PAGE_PI_SELECTED',
    payload: mac
  });
};
