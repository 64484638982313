import React from 'react';
import './providers.css';
import { connect } from 'react-redux';
import {
  updateProviderFilter,
  updateSelectedProvider
} from '../../../actions/adminActions/providersActions';
import { providers } from '../../../apis/backend'; //make sure to include this for each page
import EditProviderModal from './providerModals/editProviderModal.jsx';
import AddProviderModal from './providerModals/addProviderModal.jsx';

class Providers extends React.Component {
  async providerDeleteRequest(id) {
    await providers
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  renderproviders() {
    //renders the providers table
    return (
      <>
        {this.props.providers
          .filter((provider) => {
            const filterString = `${provider.name}`;
            return filterString.toLowerCase().indexOf(this.props.providerFilter.toLowerCase()) > -1;
          })
          .map((provider) => (
            <tr key={this.props.providers.indexOf(provider)}>
              <td className="text-center" style={{ width: '24%' }}>
                {`${provider.name}`}
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedProvider(provider.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedProvider(provider.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getProviderName() {
    const provider = this.props.providers.find((obj) => obj.id === this.props.selectedProvider);

    return provider ? provider.name : '';
  }

  render() {
    return (
      <div className="providers-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Providers</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.providerFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateProviderFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add Provider
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '24%' }}>
                    Name
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Edit
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderproviders()}</tbody>
            </table>
          </div>
        </div>

        <AddProviderModal />
        <EditProviderModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedProvider(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getProviderName()}&apos;</strong> will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.providerDeleteRequest(this.props.selectedProvider);
                    this.props.updateSelectedProvider(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedProvider(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  providers: state.admin.admin.providers,
  providerFilter: state.admin.providers.providerFilter,
  selectedProvider: state.admin.providers.selectedProvider,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateProviderFilter,
  updateSelectedProvider
})(Providers);
