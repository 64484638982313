export const updatePartnerFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PARTNERS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedPartner = (Partner) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_PARTNERS_PAGE_SELECTED_PARTNER', payload: Partner });
};
