import React from 'react';
import './feedItem.css';
import Checkmark from '../../../images/checkmark.png';
import Xmark from '../../../images/xmark.png';
import Warning from '../../../images/warning.png';
import PurchaseIcon from '../../../images/purchase.png';
import Helmet from '../../../images/helmet.png';
import Flag from '../../../images/flag.png';
import { connect } from 'react-redux';
import { updateShowButtons } from '../../../actions/skateparkPageActions/homeActions';
import classNames from 'classnames';
import { newsLogEmployeeUser, activities } from '../../../apis/backend';
import { getContrastColor, lightenDarkenColor } from '../../../apis/colors';

class FeedItem extends React.Component {
  async updateNewsLogStatus() {
    await newsLogEmployeeUser
      .post(
        '/read',
        { newsLog: this.props.id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: this.props.empUser
          }
        }
      )
      .then(() => {
        this.props.updateShowButtons(0);
      })
      .catch((err) => console.log(err));
  }

  getActivityName() {
    var fullName = this.props.selectActivity.activityName;

    if (this.props.selectActivity.activityDetails.length > 0) {
      const detailNames = this.props.selectActivity.activityDetails.map(
        (detail) => detail.activityDetail
      );
      if (detailNames.length === 1) {
        fullName = fullName + ' - ' + detailNames.join('');
      } else {
        fullName = fullName + ' - ' + detailNames.join(', ');
      }
    }

    if (this.props.selectActivity.activityProvidersSelected !== 0) {
      fullName = fullName + ' (' + this.props.selectActivity.activityProviders + ')';
    }
    fullName =
      fullName +
      ', ' +
      this.props.selectActivity.activityCity +
      ' - ' +
      this.props.selectActivity.activitySite;

    return fullName;
  }

  getFeedItemButtons() {
    const type = this.props.type;
    const status = this.props.status;
    const styles = status === 'red' ? { display: 'none' } : {};
    const userNotes = status === 'red' || status === 'purchase' ? { display: 'none' } : {};
    if (this.props.userId || type === 'news') {
      if (type === 'skatepark') {
        return (
          <>
            <button
              className="feedItemButton"
              onClick={() => {
                this.props.history.push('/skatepark/purchase/' + this.props.userId);
              }}
            >
              Purchase
            </button>
            <button
              className="feedItemButton"
              style={styles}
              onClick={() => {
                this.props.history.push('/skatepark/rentals/' + this.props.userId);
              }}
            >
              Rent
            </button>
            <button
              className="feedItemButton"
              style={userNotes}
              onClick={() => {
                this.props.history.push('/skatepark/notes/' + this.props.userId);
              }}
            >
              Notes
            </button>
            <button
              className="feedItemButton"
              style={userNotes}
              onClick={() => {
                this.props.history.push('/skatepark/notes/' + this.props.userId);
              }}
            >
              Undo Check-In
            </button>
          </>
        );
      } else if (type === 'activities') {
        return (
          <>
            <button
              className="feedItemButton"
              style={userNotes}
              onClick={() => {
                this.props.history.push('/activities/notes/' + this.props.userId);
              }}
            >
              Notes
            </button>
            <button
              className="feedItemButton"
              style={userNotes}
              onClick={async () => {
                const activityTitle = this.getActivityName();
                await activities
                  .post(
                    '/undouser',
                    {
                      id: this.props.userId,
                      activityTitle: activityTitle,
                      activity: this.props.selectActivity.activitySelected,
                      city: this.props.selectActivity.activityCitySelected,
                      site: this.props.selectActivity.activitySiteSelected,
                      provider: this.props.selectActivity.activityProvidersSelected,
                      details: this.props.selectActivity.activityDetailsSelected
                    },
                    { headers: { authorization: this.props.employeeToken } }
                  )
                  .then(() => {
                    // console.log('undo finished');
                  })
                  .catch((err) => console.log(err));
              }}
            >
              Undo Check-In
            </button>
          </>
        );
      } else if (type === 'collective') {
        return (
          <>
            <button
              className="feedItemButton"
              style={styles}
              onClick={() => {
                this.props.history.push('/collective/rentals/' + this.props.userId);
              }}
            >
              Rent
            </button>
            <button
              className="feedItemButton"
              style={userNotes}
              onClick={() => {
                this.props.history.push('/collective/notes/' + this.props.userId);
              }}
            >
              Notes
            </button>
          </>
        );
      } else if (type === 'news') {
        return (
          <button
            className="feedItemButton"
            onClick={() => {
              this.updateNewsLogStatus();
            }}
          >
            Mark As Read
          </button>
        );
      } else {
        return;
      }
    } else {
      return;
    }
  }

  getFeedItemIndicator() {
    let indicator;
    const hexRegex = /^#[0-9A-F]{6}$/i;
    switch (true) {
      case /green/.test(this.props.status): {
        // green
        indicator = (
          <div className="feedItemIndicator">
            <img style={{ margin: 'auto' }} src={Checkmark} alt={'Checkmark'} />
          </div>
        );
        break;
      }
      case /yellow/.test(this.props.status): {
        // yellow
        indicator = (
          <div className="feedItemIndicator">
            <img style={{ margin: 'auto' }} src={Warning} alt={'Warning'} />
          </div>
        );
        break;
      }
      case /red/.test(this.props.status): {
        // red
        indicator = (
          <div className="feedItemIndicator">
            <img src={Xmark} alt={'Invalid'} />
          </div>
        );
        break;
      }
      case /purchase/.test(this.props.status): {
        // purchase
        indicator = (
          <div className="feedItemIndicator">
            <img src={PurchaseIcon} alt={'Purchase'} />
          </div>
        );
        break;
      }
      case /purple/.test(this.props.status): {
        // helmet
        indicator = (
          <div className="feedItemIndicator">
            <img style={{ margin: 'auto' }} src={Helmet} alt={'Helmet'} />
          </div>
        );
        break;
      }
      case hexRegex.test(this.props.status): {
        // flag
        const outerColor = lightenDarkenColor(this.props.status, -80);
        const invertImage = getContrastColor(this.props.status) === 'black' ? true : false;
        indicator = (
          <div className="feedItemIndicator">
            <div
              id="flag"
              style={{
                background: `${this.props.status}`,
                boxShadow: `0 0 0 2px ${outerColor}`
              }}
            >
              <img
                className={classNames({ invertFlag: invertImage })}
                style={{ margin: 'auto' }}
                src={Flag}
                alt={'Flag'}
              />
            </div>
          </div>
        );
        break;
      }
      case /unread/.test(this.props.status): {
        // news log not read
        indicator = (
          <div className="feedItemIndicator" style={{ margin: '0 10px' }}>
            <span className="dot"></span>
          </div>
        );
        break;
      }
      case /read/.test(this.props.status): {
        // news log read
        indicator = <></>;
        break;
      }
      default: {
        indicator = (
          <div className="feedItemIndicator">
            <img src={Xmark} alt={'Invalid'} />
          </div>
        );
      }
    }
    return indicator;
  }

  getFeedItemMessage() {
    if (this.props.type === 'news') {
      return (
        <div className="feedItemContentMessage" style={{ margin: 0 }}>
          {this.props.messages}
        </div>
      );
    } else {
      return (
        <ul className="feedItemContentMessage">
          {this.props.messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      );
    }
  }

  render() {
    return (
      <div className="feedItem">
        <div
          className="feedItemContentContain"
          onClick={() => this.props.updateShowButtons(this.props.id)}
        >
          <div className="feedItemTextContain">
            <div className="feedItemHeader">{this.props.title}</div>
            {this.getFeedItemMessage()}
          </div>
          {this.getFeedItemIndicator()}
        </div>
        <div className="feedItemButtonContain">
          <div
            className={classNames({
              feedItemButtonsContainer: true,
              feedItemButtonsShow: this.props.showButtons === this.props.id,
              feedItemButtonsHide: this.props.showButtons !== this.props.id
            })}
          >
            {this.getFeedItemButtons()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showButtons: state.skatepark.home.showButtons,
  empUser: state.auth.employeeToken,
  selectActivity: state.activities.selectActivity
});

export default connect(mapStateToProps, {
  updateShowButtons
})(FeedItem);
