import React from 'react';
import './activityDetails.css';
import { connect } from 'react-redux';
import {
  updateActivityDetailFilter,
  updateSelectedActivityDetail
} from '../../../actions/adminActions/activityDetailsActions.js';
import { activityDetails } from '../../../apis/backend.js'; //make sure to include this for each page
import EditActivityDetailModal from './activityDetailModals/editActivityDetailModal.jsx';
import AddActivityDetailModal from './activityDetailModals/addActivityDetailModal.jsx';

class ActivityDetails extends React.Component {
  async activityDetailDeleteRequest(id) {
    await activityDetails
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  renderactivitydetails() {
    //renders the activities table
    return (
      <>
        {this.props.activityDetails
          .filter((activityDetail) => {
            const filterString = `${activityDetail.activityDetail}`;
            return (
              filterString.toLowerCase().indexOf(this.props.activityDetailFilter.toLowerCase()) > -1
            );
          })
          .map((activityDetail) => (
            <tr key={this.props.activityDetails.indexOf(activityDetail)}>
              <td className="text-center" style={{ width: '24%' }}>
                {`${activityDetail.activityDetail}`}
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedActivityDetail(activityDetail.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedActivityDetail(activityDetail.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getActivityDetailName() {
    const activityDetail = this.props.activityDetails.find(
      (obj) => obj.id === this.props.selectedActivityDetail
    );

    return activityDetail ? activityDetail.activityDetail : '';
  }

  render() {
    return (
      <div className="activity-details-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Activity Details</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.activityDetailFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateActivityDetailFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add Activity Detail
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '24%' }}>
                    Activity Detail
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Edit
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderactivitydetails()}</tbody>
            </table>
          </div>
        </div>

        <AddActivityDetailModal />
        <EditActivityDetailModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedActivityDetail(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getActivityDetailName()}&apos;</strong> will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.activityDetailDeleteRequest(this.props.selectedActivityDetail);
                    this.props.updateSelectedActivityDetail(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedActivityDetail(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activityDetails: state.admin.admin.activityDetails,
  activityDetailFilter: state.admin.activityDetails.activityDetailFilter,
  selectedActivityDetail: state.admin.activityDetails.selectedActivityDetail,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateActivityDetailFilter,
  updateSelectedActivityDetail
})(ActivityDetails);
