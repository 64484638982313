export const initializeRentalsPage = (response) => (dispatch) => {
  dispatch({ type: 'INITIALIZE_COLLECTIVE_RENTALS_PAGE', payload: response });
};

export const addToCart = (id) => (dispatch, getState) => {
  const currentCart = [...getState().collective.rentals.cart];
  if (!currentCart.includes(id)) {
    currentCart.push(id);
    dispatch({
      type: 'COLLECTIVE_RENTALS_PAGE_UPDATE_CART',
      payload: currentCart
    });
  }
};

export const removeFromCart = (id) => (dispatch, getState) => {
  const currentCart = [...getState().collective.rentals.cart];
  if (currentCart.includes(id)) {
    dispatch({
      type: 'COLLECTIVE_RENTALS_PAGE_UPDATE_CART',
      payload: currentCart.filter((item) => item !== id)
    });
  }
};

export const updateFilter = (filter) => (dispatch) => {
  dispatch({
    type: 'COLLECTIVE_RENTALS_PAGE_UPDATE_FILTER_STRING',
    payload: filter
  });
};

export const clearRentalsPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_COLLECTIVE_RENTALS_PAGE' });
};
