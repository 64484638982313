import React, { Component } from 'react';
import './../../Modal.css';
import {
  updateSelectedUser,
  updateSelectedNote
} from '../../../../actions/adminActions/usersActions';
import { getContrastColor } from '../../../../apis/colors';
import { connect } from 'react-redux';

class NotesModal extends Component {
  getNoteFlagType(note) {
    if (note.flag) {
      const flag = this.props.noteFlags.find((obj) => obj.id === note.flag);
      return (
        <div style={{ float: 'left', width: '25%' }}>
          Flag:
          <strong
            className="note-flag-style"
            style={{
              backgroundColor: `${flag.color}`,
              color: getContrastColor(flag.color)
            }}
          >
            {flag.name}
          </strong>
        </div>
      );
    }
    return <div style={{ float: 'left', width: '25%' }}>Flag: none</div>;
  }

  getNoteFlagStatus(note) {
    let status = '';
    switch (note.flagStatus) {
      case 'cleared':
        status = 'Status: Cleared';
        break;
      case 'notCleared':
        status = 'Status: Not Cleared';
        break;
      case 'autoClears':
        status = 'Status: Auto Clears';
        break;
      case 'none':
        break;
      default:
        break;
    }
    return <div style={{ float: 'left', width: '30%' }}>{status}</div>;
  }

  renderNotes() {
    return (
      <>
        {this.props.userNotes
          .filter((obj) => obj.user === this.props.selectedUser)
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((note) => (
            <tr key={this.props.userNotes.indexOf(note)}>
              <td className="comment-block" style={{ width: '45%' }}>
                <div className="comment-info">{note.note}</div>
                <div className="comment-status">
                  <div
                    style={{
                      float: 'left',
                      width: '45%',
                      display: 'inline-block'
                    }}
                  >
                    {new Date(note.timestamp).toString().split(' GMT')[0]}
                  </div>
                  {this.getNoteFlagType(note)}
                  {this.getNoteFlagStatus(note)}
                </div>
              </td>
              <td className="text-center" style={{ width: '9%' }}>
                <div
                  data-target="#editNotesModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedNote(note.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center" style={{ width: '9%' }}>
                <div
                  data-target="#deleteNotesModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedNote(note.id);
                  }}
                >
                  Delete
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  findNotes() {
    const notes = this.props.userNotes.filter((obj) => obj.user === this.props.selectedUser);
    if (notes.length) {
      return (
        <div
          className="table-wrapper-scroll-y"
          style={{ overflow: 'hidden scroll', maxHeight: '400px' }}
        >
          <table className="table table-striped table-bordered table-hover bg-white mt-1 mr-3">
            <thead className="bg-dark thead-dark">
              <tr>
                <th className="text-center" style={{ width: '45%' }}>
                  User Notes
                </th>
                <th className="text-center" style={{ width: '9%' }}>
                  Edit
                </th>
                <th className="text-center" style={{ width: '9%' }}>
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>{this.renderNotes()}</tbody>
          </table>
        </div>
      );
    } else {
      return <div style={{ padding: '.5rem' }}>No user notes...</div>;
    }
  }

  render() {
    const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);
    return (
      <div id="notesModal" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {user?.firstName} {user?.lastName}&apos;s Notes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.updateSelectedUser(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ padding: '0' }}>
              {this.findNotes()}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedUser(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                data-target="#createNotesModal"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedNote(0);
                }}
              >
                Create New Note
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  userNotes: state.admin.admin.userNotes,
  noteFlags: state.admin.admin.noteFlags,
  selectedUser: state.admin.users.selectedUser,
  selectedNote: state.admin.users.selectedNote,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateSelectedUser,
  updateSelectedNote
})(NotesModal);
