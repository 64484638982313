import React from 'react';
import './home.css';
import FeedItem from '../../utils/feeditem/feedItem.jsx';
import { connect } from 'react-redux';
import Logo from '../../../images/events.png';
import FeedView from '../../utils/feedView/feedView.jsx';
import Page from '../../utils/page/page';
import { lock } from '../../../actions/authActions';
import {
  changeActiveTab,
  clearUnreadTally,
  updateEventsFeedItems,
  populateEventsFeed,
  updateExistingEventsFeedItem,
  removeExistingEventsFeedItem,
  addToExistingEventsFeedItem
} from '../../../actions/eventsPageActions/eventsActions.js';
import { updateShowButtons } from '../../../actions/eventsPageActions/homeActions.js';

class HomePage extends React.Component {
  async changeCashAttendance(value) {
    let feedItem = this.props.feedItems.find((obj) => obj.id === 'tempCash');
    if (!feedItem) {
      feedItem = {
        id: 'tempCash',
        status: 'yellow',
        user: 'Updating Cash Attendance',
        userId: null,
        messages: ['Cash Attendance decreased: -1'],
        updateValue: -1
      };
      if (value > 0) {
        feedItem.messages = ['Cash Attendance increased: 1'];
        feedItem.updateValue = 1;
      }
      this.props.addToExistingEventsFeedItem(feedItem);
    } else {
      feedItem.updateValue += value;
      if (feedItem.updateValue > 0) {
        feedItem.messages = [`Cash Attendance increased: ${feedItem.updateValue}`];
        this.props.updateExistingEventsFeedItem(feedItem);
      } else if (feedItem.updateValue < 0) {
        feedItem.messages = [`Cash Attendance decreased: ${feedItem.updateValue}`];
        this.props.updateExistingEventsFeedItem(feedItem);
      } else {
        this.props.removeExistingEventsFeedItem(feedItem);
      }
    }
  }

  async changeTicketAttendance(value) {
    let feedItem = this.props.feedItems.find((obj) => obj.id === 'tempTicket');
    if (!feedItem) {
      feedItem = {
        id: 'tempTicket',
        status: 'yellow',
        user: 'Updating Ticket Attendance',
        userId: null,
        messages: ['Ticket Attendance decreased: -1'],
        updateValue: -1
      };
      if (value > 0) {
        feedItem.messages = ['Ticket Attendance increased: 1'];
        feedItem.updateValue = 1;
      }
      this.props.addToExistingEventsFeedItem(feedItem);
    } else {
      feedItem.updateValue += value;
      if (feedItem.updateValue > 0) {
        feedItem.messages = [`Ticket Attendance increased: ${feedItem.updateValue}`];
        this.props.updateExistingEventsFeedItem(feedItem);
      } else if (feedItem.updateValue < 0) {
        feedItem.messages = [`Ticket Attendance decreased: ${feedItem.updateValue}`];
        this.props.updateExistingEventsFeedItem(feedItem);
      } else {
        this.props.removeExistingEventsFeedItem(feedItem);
      }
    }
  }

  setFontSize(event) {
    if (event?.eventName && event?.eventName.length > 30) {
      const fontSize = 28 * 0.7;
      return fontSize + 'px';
    }
    return '28px';
  }

  hasAttendance(event, totalAttendance) {
    if (event.eventSetting !== 'skatepark') {
      return (
        <div className="eventText">
          Total Attendance: {isNaN(totalAttendance) ? 0 : totalAttendance}
        </div>
      );
    }
  }

  render() {
    let feedItems;
    let newsItems;

    // Dynamically displays feed items
    if (this.props.feedItems.length === 0) {
      feedItems = <h1>No items to display</h1>;
    } else {
      feedItems = this.props.feedItems.map((log) => (
        <FeedItem
          title={log.user}
          status={log.status}
          messages={log.messages}
          userId={log.userId}
          id={log.id}
          key={log.id}
          history={this.props.history}
          type="events"
        />
      ));
    }

    // Dynamically displays news items
    if (this.props.newsItems.length === 0) {
      newsItems = <h1>No items to display</h1>;
    } else {
      newsItems = this.props.newsItems.map((news) => (
        <FeedItem
          title={news.title}
          status={news.read ? 'read' : 'unread'}
          messages={[news.message]}
          id={news.id}
          key={news.id}
          history={this.props.history}
          type="news"
        />
      ));
    }

    const { events } = this.props;
    const event =
      events[events.indexOf(events.find((obj) => obj.id === this.props.selectedEventId))];
    const totalAttendance =
      event?.cashAttendance + event?.ticketAttendance + event?.allAccessAttendance;

    const skateparkButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/events/home/register')
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/events/rfid')
      },
      {
        text: 'Manual Check-In',
        onClick: () => this.props.history.push('/events/home/checkin')
      },
      {
        text: 'User Notes',
        onClick: () => this.props.history.push('/events/notescheckin')
      },
      {
        text: 'Purchase',
        onClick: () => this.props.history.push('/events/purchasecheckin')
      },
      {
        text: 'Rentals',
        onClick: () => this.props.history.push('/events/rentalscheckin')
      },
      {
        text: 'Inventory',
        onClick: () => this.props.history.push('/events/inventory')
      },
      {
        text: 'Select Event',
        onClick: () => this.props.history.push('/events')
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock()
      }
    ];

    const eventButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/events/home/register')
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/events/rfid')
      },
      {
        text: 'Manual Check-In',
        onClick: () => this.props.history.push('/events/home/checkin')
      },
      {
        text: 'User Notes',
        onClick: () => this.props.history.push('/events/notescheckin')
      },
      {
        text: 'Select Event',
        onClick: () => this.props.history.push('/events')
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock()
      }
    ];

    const menuButtons = event.eventSetting === 'skatepark' ? skateparkButtons : eventButtons;

    const attendanceButtonTypes = [
      {
        text: 'Cash Attendance',
        leftButtonText: <strong>&minus;</strong>,
        leftClick: async () => {
          await this.changeCashAttendance(-1);
        },
        rightButtonText: <strong>&#43;</strong>,
        rightClick: async () => {
          await this.changeCashAttendance(1);
        }
      },
      {
        text: 'Ticket attendance',
        leftButtonText: <strong>&minus;</strong>,
        leftClick: async () => {
          await this.changeTicketAttendance(-1);
        },
        rightButtonText: <strong>&#43;</strong>,
        rightClick: async () => {
          await this.changeTicketAttendance(1);
        }
      }
    ];

    const attendanceButtonList = event.eventSetting === 'skatepark' ? [] : attendanceButtonTypes;

    return (
      <Page>
        <div className="eventsPageColumn">
          <div className="eventsButtonColumnContain">
            <div className="eventsButtonColumnImageContain">
              <img src={Logo} alt="logo" style={{ maxWidth: '80%' }} />
            </div>
            {attendanceButtonList.map((button) => (
              <div className="attendanceButton" key={button.text}>
                <button
                  className="tallyChange"
                  style={{ maxWidth: '90px', maxHeight: '90px' }}
                  onClick={() => button.leftClick()}
                  disabled={this.props.selectedEventId === 0}
                >
                  {button.leftButtonText}
                </button>
                <div className="" style={{ margin: 'auto' }}>
                  {button.text}
                </div>
                <button
                  className="tallyChange"
                  style={{ maxWidth: '90px', maxHeight: '90px' }}
                  onClick={() => button.rightClick()}
                  disabled={this.props.selectedEventId === 0}
                >
                  {button.rightButtonText}
                </button>
              </div>
            ))}
            {menuButtons.map((button) => (
              <button
                className="eventsButtonColumnButton"
                onClick={() => button.onClick()}
                key={button.text}
              >
                {button.text}
              </button>
            ))}
          </div>
        </div>
        <div className="eventsPageColumn">
          <div
            className={`${
              event.eventSetting === 'skatepark' ? 'skateAttendanceText' : 'attendanceText'
            }`}
          >
            <div className="eventText" style={{ fontSize: this.setFontSize(event) }}>
              Event: {this.props.selectedEventId === 0 ? 'No Event Selected' : event.eventName}
            </div>
            {this.hasAttendance(event, totalAttendance)}
          </div>
          <div
            className={`${
              event.eventSetting === 'skatepark' ? 'skateFeedContainer' : 'feedContainer'
            }`}
          >
            <FeedView
              activity={feedItems}
              updates={newsItems}
              activeTab={this.props.activeTab}
              tabClick={(index) => {
                this.props.changeActiveTab(index);
                this.props.clearUnreadTally();
                this.props.updateShowButtons(0);
              }}
              tally={{
                newsUnreadTally: this.props.newsUnreadTally,
                activityUnreadTally: this.props.activityUnreadTally
              }}
            ></FeedView>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  feedItems: state.events.events.feedItems,
  newsItems: state.events.events.newsItems,
  activeTab: state.events.events.activeTab,
  newsUnreadTally: state.events.events.newsUnreadTally,
  activityUnreadTally: state.events.events.activityUnreadTally,
  selectedEventId: state.events.events.selectedEventId,
  events: state.events.events.events,
  ticketAttendanceInterval: state.events.ticketAttendanceInterval
});

export default connect(mapStateToProps, {
  changeActiveTab,
  clearUnreadTally,
  lock,
  updateShowButtons,
  updateEventsFeedItems,
  populateEventsFeed,
  updateExistingEventsFeedItem,
  removeExistingEventsFeedItem,
  addToExistingEventsFeedItem
})(HomePage);
