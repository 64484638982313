import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import Page from '../utils/page/page.jsx';
import './404.css';
import funny from '../../images/skateboard_fail.gif';
import { logout, lock } from '../../actions/authActions';

class NotFound extends React.Component {
  handleGoBack = () => {
    const { history, location } = this.props;

    if (location.state && location.state.from === '/login') {
      this.props.logout();
    } else if (location.state && location.state.from === '/keypad') {
      this.props.lock();
    } else {
      history.goBack();
    }
  };

  render() {
    const names = ['Joey', 'Easton', 'Avinash', 'Micah', 'Aaron', 'Sarah'];
    const name = names[Math.floor(Math.random() * names.length)];
    return (
      <Page>
        <div className="not-found">
          <h1>404 not found</h1>
          <p>Oops! Something went wrong! It&apos;s probably {name}&apos;s fault...</p>
          <div>
            <img src={funny} alt="404"></img>
          </div>
          <button className="backButton" onClick={this.handleGoBack}>
            Go Back
          </button>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io
});

export default connect(mapStateToProps, {
  lock,
  logout
})(NotFound);
