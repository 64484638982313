import React from 'react';
import { connect } from 'react-redux';
import {
  initializeKeypad,
  addToPin,
  removeOneFromPin,
  clearPin,
  playIncorrectAnimation,
  stopIncorrectAnimation
} from '../../actions/keypadActions';
import { unlock, logout } from '../../actions/authActions';
// import { Redirect } from 'react-router-dom';
import './keypad.css';
import Page from '../utils/page/page.jsx';
import { employeeAuth } from '../../apis/backend';
import Skatepark from '../../images/skatepark.png';
import Collective from '../../images/collective.png';
import Events from '../../images/events.png';
import Coffee from '../../images/coffee.png';
import Logo from '../../images/small-white-.png';
import clickAudio from '../../audio/click2.mp3';
import backAudio from '../../audio/back.mp3';
import pressAudio from '../../audio/click1.mp3';

class KeyPad extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.props.initializeKeypad();
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  async checkLogin(pin) {
    try {
      await employeeAuth
        .post('/login', { pin }, { headers: { systemauthorization: this.props.systemToken } })
        .then((res) => {
          setTimeout(() => {
            this.props.unlock(res.data);
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          this.props.playIncorrectAnimation();
          setTimeout(() => {
            this.props.stopIncorrectAnimation();
            this.props.clearPin();
          }, 500);
        });
    } catch (except) {
      // Ideally this catch statement will do something once we're requesting information from the backend
      // this.props.updateErrorMessage(
      //     'Sorry, something went wrong. Please check your internet connection.'
      // );
    }
  }

  async addToPin(num) {
    await this.props.addToPin(num);
    if (this.props.pin.length >= 6) {
      this.checkLogin(this.props.pin.join().replace(/,/g, ''));
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 8) {
      // this.playSound(backAudio, 0.2);
      this.props.removeOneFromPin();
    } else if (!isNaN(event.key)) {
      // this.playSound(clickAudio, 0.2);
      const value = parseInt(event.key);
      this.addToPin(value);
    }
  }

  getBanner() {
    let banner;
    switch (this.props.sysLocation) {
      case 'skatepark':
        banner = <img style={{ maxWidth: '400px' }} src={Skatepark} alt={'Skatepark'} />;
        break;
      case 'collective':
        banner = <img style={{ maxWidth: '400px' }} src={Collective} alt={'Collective'} />;
        break;
      case 'events':
        banner = <img style={{ maxWidth: '400px' }} src={Events} alt={'Events'} />;
        break;
      case 'coffee':
        banner = <img style={{ maxWidth: '400px' }} src={Coffee} alt={'Coffee'} />;
        break;
      default:
        banner = <img style={{ maxHeight: '100px' }} src={Logo} alt={'The Bay'} />;
    }
    return banner;
  }

  getNumpad() {
    return (
      <div className="numpad">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <button
            onClick={() => {
              // this.playSound(clickAudio, 0.2);
              this.addToPin(num);
            }}
            key={num}
          >
            {num}
          </button>
        ))}

        <button
          className="alt-button"
          onClick={() => {
            // this.playSound(pressAudio, 0.2);
            this.props.logout();
          }}
        >
          Log Out
        </button>
        <button
          onClick={() => {
            // this.playSound(clickAudio, 0.2);
            this.addToPin(0);
          }}
        >
          0
        </button>
        <button
          className="alt-button"
          onClick={() => {
            // this.playSound(backAudio, 0.2);
            this.props.removeOneFromPin();
          }}
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    return (
      <Page>
        <div className="keypad">
          <div className="locationBanner">{this.getBanner()}</div>
          <div className="pin">
            {[1, 2, 3, 4, 5, 6].map((num) => {
              if (this.props.incorrectAnimation === true) {
                return (
                  <div
                    className="pinCircle"
                    style={{
                      background: 'rgb(194, 41, 41)',
                      animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
                    }}
                    key={num}
                  />
                );
              } else {
                if (num <= this.props.pin.length) {
                  return (
                    <div
                      className="pinCircle"
                      style={{
                        background: 'rgb(41, 138, 194)'
                      }}
                      key={num}
                    />
                  );
                } else {
                  return <div className="pinCircle" key={num} />;
                }
              }
            })}
          </div>
          {this.getNumpad()}
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  pin: state.keypad.pin,
  incorrectAnimation: state.keypad.incorrectAnimation,
  isUnlocked: state.auth.isUnlocked,
  systemToken: state.auth.systemToken,
  employeeToken: state.auth.employeeToken,
  sysLocation: state.auth.sysLocation
});

export default connect(mapStateToProps, {
  initializeKeypad,
  addToPin,
  removeOneFromPin,
  clearPin,
  playIncorrectAnimation,
  stopIncorrectAnimation,
  unlock,
  logout
})(KeyPad);
