export const updateCollectiveInventoryEditModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_COLLECTIVE_INVENTORY_EDIT_MODAL_ACTIVE',
    payload: value
  });
};

export const clearCollectiveInventoryEditModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_COLLECTIVE_INVENTORY_EDIT_MODAL'
  });
};
