const INITIAL_STATE = {
  program: '',
  programSelected: 0,
  activityName: '',
  activitySelected: 0,
  activityCity: '',
  activityCitySelected: 0,
  activitySite: '',
  activitySiteSelected: 0,
  activityDetails: [],
  activityDetailsSelected: [],
  activityPartners: '',
  activityPartnersSelected: 0,
  activityProviders: '',
  activityProvidersSelected: 0
};

export const activitiesSelectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_PROGRAM_NAME':
      return {
        ...state,
        program: action.payload
      };
    case 'UPDATE_CURRENT_PROGRAM_SELECTED':
      return {
        ...state,
        programSelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_NAME':
      return {
        ...state,
        activityName: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_SELECTED':
      return {
        ...state,
        activitySelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_CITY':
      return {
        ...state,
        activityCity: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_CITY_SELECTED':
      return {
        ...state,
        activityCitySelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_SITE':
      return {
        ...state,
        activitySite: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_SITE_SELECTED':
      return {
        ...state,
        activitySiteSelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_DETAILS':
      return {
        ...state,
        activityDetails: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_DETAILS_SELECTED':
      return {
        ...state,
        activityDetailsSelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_PARTNERS':
      return {
        ...state,
        activityPartners: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_PARTNERS_SELECTED':
      return {
        ...state,
        activityPartnersSelected: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_PROVIDERS':
      return {
        ...state,
        activityProviders: action.payload
      };
    case 'UPDATE_CURRENT_ACTIVITIES_PROVIDERS_SELECTED':
      return {
        ...state,
        activityProvidersSelected: action.payload
      };
    case 'CLEAR_CURRENT_ACTIVITIES_SELECTED':
      return {
        ...state,
        program: '',
        programSelected: 0,
        activityName: '',
        activitySelected: 0,
        activityCity: '',
        activityCitySelected: 0,
        activitySite: '',
        activitySiteSelected: 0,
        activityDetails: [],
        activityDetailsSelected: [],
        activityPartners: '',
        activityPartnersSelected: 0,
        activityProviders: '',
        activityProvidersSelected: 0
      };
    default:
      return state;
  }
};
