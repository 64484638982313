import React, { Component } from 'react';
import './../../Modal.css';
import { usernotes } from '../../../../apis/backend';
import { connect } from 'react-redux';
import {
  updateSelectedUser,
  updateSelectedNote
} from '../../../../actions/adminActions/usersActions';

class DeleteNotesModal extends Component {
  async noteDeleteRequest(id) {
    await usernotes
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  render() {
    const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);
    const note = this.props.userNotes.find((obj) => obj.id === this.props.selectedNote);
    return (
      <div className="modal fade" id="deleteNotesModal">
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete {user?.firstName}&apos;s Note</h5>
              <button
                type="button"
                data-target="#notesModal"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
              <strong>This note will be deleted forever!!</strong>
              <br />
              &#34;{note?.note ? note.note : 'Error...'}&#34;
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-primary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.noteDeleteRequest(this.props.selectedNote);
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                  this.props.updateSelectedNote(0);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  userNotes: state.admin.admin.userNotes,
  selectedUser: state.admin.users.selectedUser,
  selectedNote: state.admin.users.selectedNote,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateSelectedUser,
  updateSelectedNote
})(DeleteNotesModal);
