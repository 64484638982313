import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedCity } from '../../../../actions/adminActions/citiesActions';
import { cities } from '../../../../apis/backend';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCity !== this.props.selectedCity && this.props.selectedCity !== 0) {
      const city = this.props.cities.find((obj) => obj.id === this.props.selectedCity);
      if (city) {
        this.setState({
          name: city.name
        });
      }
    }
  }

  clearState() {
    this.setState({
      id: '',
      name: ''
    });
  }

  async cityRequest() {
    try {
      await cities
        .patch(
          `/${this.props.selectedCity}`,
          {
            name: this.state.name
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update city</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    City Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.cityRequest();
                }}
                disabled={!this.state.name != ''}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.admin.admin.cities,
  selectedCity: state.admin.cities.selectedCity,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedCity })(EditModal);
