import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedPurchaseLog } from '../../../../actions/adminActions/adminPurchaseLogActions';

class CartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedPurchaseLog !== this.props.selectedPurchaseLog &&
      this.props.selectedPurchaseLog !== 0
    ) {
      const log = this.props.purchaseLogs.find((obj) => obj.id === this.props.selectedPurchaseLog);
      if (log) {
        this.setState({
          cart: JSON.parse(log.cart)
        });
      }
    }
  }

  render() {
    return (
      <div id="cartModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Cart</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span>
                <ul className="list-group">
                  {this.state.cart.map((item) => (
                    <li key={item.id} className="list-group-item">{`${
                      item.name
                    }: $${item.price.toFixed(2)}`}</li>
                  ))}
                </ul>
              </span>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  purchaseLogs: state.admin.admin.purchaseLogs,
  selectedPurchaseLog: state.admin.purchaseLogs.selectedPurchaseLog
});

export default connect(mapStateToProps, { updateSelectedPurchaseLog })(CartModal);
