export const updateActivityFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_ACTIVITIES_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedActivity = (Activity) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_ACTIVITIES_PAGE_SELECTED_ACTIVITY', payload: Activity });
};
