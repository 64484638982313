import React from 'react';
import './events.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './home/home';
import Register from './register/register';
import Checkin from './checkin/checkin';
import SelectEvent from './selectEvent/selectEvent';
import Purchase from './purchase/purchase';
import Inventory from './inventory/inventory';
import Edit from './inventory/edit/edit';
import Rentals from './rentals/rentals';
import Notes from './notes/notes';
import Loading from '../loading/loading';
import NotFound from '../404/404';
import RFIDRegister from './rfid-register/rfid-register';
import RFIDScan from './rfid-register/rfid-scan';
import Login from '../login/login.jsx';
import {
  populateEventsFeed,
  populateEventsNews,
  updateEventsFeedItems,
  populateEventsUsers,
  createEventsUser,
  updateEventsUser,
  deleteEventsUser,
  updateEventsAttendance,
  populateEventsInventory,
  createEventsInventory,
  updateEventsInventory,
  deleteEventsInventory,
  clearEventsPage,
  initializeEvents,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  getAllEvents,
  setCurrentEvent,
  decrementNewsUnreadTally,
  createEvent,
  updateEvent,
  deleteEvent,
  updateEventsNewsItems,
  populateEventsUserNotes,
  createEventsUserNote,
  updateEventsUserNote,
  deleteEventsUserNote
} from '../../actions/eventsPageActions/eventsActions';
import { events } from '../../apis/backend';
import notificationAudio from '../../audio/notification.mp3';

class Events extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/newslog/recent', (resData) => {
        this.props.populateEventsNews(resData);
      });
      await this.props.io.get('/users', (resData) => {
        this.props.populateEventsUsers(resData);
      });
      await this.props.io.get('/events/current', (resData) => {
        this.props.getAllEvents(resData);
        if (resData.length > 0) {
          this.props.setCurrentEvent(resData[0].id);
        }
      });
      await this.props.io.get('/inventory/report', (resData) => {
        this.props.populateEventsInventory(resData);
      });
      await this.props.io.get('/users/checkedIn', (resData) => {
        this.props.updateEventsAttendance(resData);
      });

      this.props.io.on('attendanceupdate', (event) => {
        this.props.updateEventsAttendance([event, ...this.props.attendance]);
      });

      this.props.io.on('eventcreate', (event) => {
        this.props.createEvent(event);
      });
      this.props.io.on('eventupdate', (event) => {
        this.props.updateEvent(event);
      });
      this.props.io.on('eventdelete', (event) => {
        this.props.deleteEvent(event);
      });

      this.props.io.on('usercreate', (event) => {
        this.props.createEventsUser(event);
      });
      this.props.io.on('userupdate', (event) => {
        this.props.updateEventsUser(event);
      });
      this.props.io.on('userdelete', (event) => {
        this.props.deleteEventsUser(event);
      });

      this.props.io.on('inventorycreate', (event) => {
        this.props.createEventsInventory(event);
      });
      this.props.io.on('inventoryupdate', (event) => {
        this.props.updateEventsInventory(event);
      });
      this.props.io.on('inventorydelete', (event) => {
        this.props.deleteEventsInventory(event);
      });

      this.props.io.on('activitylogcreate', (event) => {
        this.props.updateEventsFeedItems(event);
        if (this.props.activeTab === 1) {
          this.props.incrementActivityUnreadTally();
          // this.playSound(notificationAudio, 0.5);
        }
      });
      this.props.io.on('newslogcreate', (event) => {
        this.props.updateEventsNewsItems(event);
        this.props.incrementNewsUnreadTally();
        // this.playSound(notificationAudio, 0.5);
      });
      this.props.io.on('newslogupdate', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateEventsNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogdelete', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateEventsNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogread', async (event) => {
        this.props.decrementNewsUnreadTally();
        await this.props.io.get('/newslog/recent', (resData) => {
          this.props.populateEventsNews(resData);
        });
      });
      await this.props.io.get('/usernotes', (resData) => {
        this.props.populateEventsUserNotes(resData);
      });
      this.props.io.on('usernotescreate', (event) => {
        this.props.createEventsUserNote(event);
      });
      this.props.io.on('usernotesupdate', (event) => {
        this.props.updateEventsUserNote(event);
      });
      this.props.io.on('usernotesdelete', (event) => {
        this.props.deleteEventsUserNote(event);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loaded &&
      this.props.usersLoaded &&
      this.props.feedItemsLoaded &&
      this.props.newsItemsLoaded &&
      this.props.eventsLoaded &&
      this.props.inventoryLoaded &&
      this.props.userNotesLoaded
    ) {
      this.props.initializeEvents();
    }
    if (prevProps.eventId !== this.props.eventId && this.props.eventId !== 0) {
      await this.props.io.get(`/events/recent/${this.props.eventId}`, (resData) => {
        this.props.populateEventsFeed(resData);
      });
      const { ticketAttendanceInterval, cashAttendanceInterval } = this.props;
      if (ticketAttendanceInterval) {
        clearInterval(ticketAttendanceInterval);
      }
      if (cashAttendanceInterval) {
        clearInterval(cashAttendanceInterval);
      }
    } else if (this.props.eventId === 0 && !this.props.FeedItemsLoaded) {
      this.props.populateEventsFeed([]);
    }
  }

  componentWillUnmount() {
    try {
      this.props.io.off('activitylogcreate');
      this.props.io.off('newslogcreate');
      this.props.io.off('newslogupdate');
      this.props.io.off('newslogdelete');
      this.props.io.off('newslogread');
      this.props.io.off('inventorydelete');
      this.props.io.off('inventoryupdate');
      this.props.io.off('inventorycreate');
      this.props.io.off('userdelete');
      this.props.io.off('userupdate');
      this.props.io.off('usercreate');
      this.props.io.off('eventcreate');
      this.props.io.off('eventupdate');
      this.props.io.off('eventdelete');
      this.props.io.off('usernotescreate');
      this.props.io.off('usernotesupdate');
      this.props.io.off('usernotesdelete');
      this.props.clearEventsPage();
    } catch (err) {
      console.log(err);
    }
    // if (this.state.interval) {
    //   clearInterval(this.state.interval);
    // }
  }

  async attendanceRequest(id) {
    await events
      .post(
        '/user',
        {
          id,
          event: this.props.eventId
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((err) => console.log(err));
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }
    // if (this.props.events.length === 0) {
    //   return <div>No events</div>;
    // }
    return (
      <Router>
        <Switch>
          <Route path="/events/home/register" component={Register} />
          <Route
            path="/events/home/checkin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Check-In',
                  clickEvent: (e) => this.attendanceRequest(e),
                  redirect: '/events/home'
                }}
              ></Checkin>
            )}
          />
          <Route
            exact
            path="/events"
            render={(props) => (
              <SelectEvent
                {...props}
                button={{
                  title: 'Confirm',
                  clickEvent: (e) => this.props.setCurrentEvent(e),
                  redirect: '/events/home'
                }}
              ></SelectEvent>
            )}
          />
          <Route
            path="/events/purchasecheckin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Continue',
                  redirectId: '/events/purchase'
                }}
              ></Checkin>
            )}
          />
          <Route path="/events/purchase/:id" component={Purchase} />
          <Route
            path="/events/rentalscheckin"
            render={(props) => (
              <Checkin
                {...props}
                userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/events/rentals'
                }}
              ></Checkin>
            )}
          />
          <Route
            path="/events/notescheckin"
            render={(props) => (
              <Checkin
                {...props}
                // userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/events/notes'
                }}
              ></Checkin>
            )}
          />
          <Route path="/events/rentals/:id" component={Rentals} />
          <Route path="/events/inventory/edit/:id" component={Edit} />
          <Route path="/events/notes/:userId" component={Notes} />
          <Route path="/events/inventory" component={Inventory} />
          <Route exact path="/events/rfid" component={RFIDRegister} />
          <Route exact path="/events/rfid/scan/:id" component={RFIDScan} />
          <Route path="/events/home" component={HomePage} />
          <Route exact path="/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  connected: state.application.connected,
  loaded: state.events.events.loaded,
  feedItemsLoaded: state.events.events.feedItemsLoaded,
  newsItemsLoaded: state.events.events.newsItemsLoaded,
  usersLoaded: state.events.events.usersLoaded,
  eventsLoaded: state.events.events.eventsLoaded,
  inventoryLoaded: state.events.events.inventoryLoaded,
  userNotesLoaded: state.events.events.userNotesLoaded,
  activeTab: state.events.events.activeTab,
  events: state.events.events.events,
  eventId: state.events.events.selectedEventId,
  ticketAttendanceInterval: state.events.events.ticketAttendanceInterval,
  cashAttendanceInterval: state.events.events.cashAttendanceInterval,
  attendance: state.events.events.attendance,
  employeeToken: state.auth.employeeToken,
  newsItems: state.events.events.newsItems
});

export default connect(mapStateToProps, {
  populateEventsFeed,
  populateEventsNews,
  updateEventsFeedItems,
  populateEventsUsers,
  createEventsUser,
  updateEventsUser,
  deleteEventsUser,
  updateEventsAttendance,
  populateEventsInventory,
  createEventsInventory,
  updateEventsInventory,
  deleteEventsInventory,
  clearEventsPage,
  initializeEvents,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  getAllEvents,
  setCurrentEvent,
  decrementNewsUnreadTally,
  createEvent,
  updateEvent,
  deleteEvent,
  updateEventsNewsItems,
  populateEventsUserNotes,
  createEventsUserNote,
  updateEventsUserNote,
  deleteEventsUserNote
})(Events);
