import React from 'react';
import './users.css';
import { connect } from 'react-redux';
import { updateUserFilter, updateSelectedUser } from '../../../actions/adminActions/usersActions';
import { users } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './userModals/editModal.jsx';
import AddModal from './userModals/addModal.jsx';
import AttendanceModal from './userModals/attendanceModal.jsx';
import RentalModal from './userModals/RentalModal.jsx';
import NotesModal from './userModals/notesModal.jsx';
import CreateNotesModal from './userModals/createNotesModal.jsx';
import EditNotesModal from './userModals/editNotesModal.jsx';
import DeleteNotesModal from './userModals/deleteNotesModal.jsx';
import BulkActionsModal from './userModals/bulkActionsModal.jsx';
import NoteFlagsModal from './userModals/noteFlagsModal.jsx';
import CreateNoteFlagsModal from './userModals/createNoteFlagsModal.jsx';
import EditNoteFlagsModal from './userModals/editNoteFlagsModal.jsx';
import DeleteNoteFlagsModal from './userModals/deleteNoteFlagsModal.jsx';
import UserFiltersModal from './userModals/userFiltersModal.jsx';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRentalToggle: false,
      userDuplicateToggle: false
    };
  }

  async userDeleteRequest(id) {
    await users
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  userToggleRender(user) {
    if (!this.state.userRentalToggle) {
      return '';
    }
    const userRentals = this.props.rentals.filter((obj) => obj.user === user.id);
    if (userRentals.length > 0) {
      for (const rental of userRentals) {
        if (rental.checkInTime === null) {
          return '';
        }
      }
    }
    return 'none';
  }

  getFormattedBirthday(dob) {
    const bday = new Date(dob);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${months[bday.getMonth()]} ${bday.getDate()}, ${bday.getFullYear()}`;
  }

  getUserFilterType() {
    if (this.state.userDuplicateToggle) {
      // Duplicate user filtering
      const duplicates = this.props.users.filter((user, index, self) => {
        const filterString = `${user.firstName} ${user.lastName} ${
          user.email
        } ${this.getFormattedBirthday(user.dateOfBirth)}`;
        return (
          self.findIndex(
            (t) =>
              t.firstName === user.firstName &&
              t.lastName === user.lastName &&
              t.dateOfBirth !== user.dateOfBirth
          ) >= 0 && filterString.toLowerCase().indexOf(this.props.userFilter.toLowerCase()) > -1
        );
      });
      return duplicates;
    }
    // Return default filtering
    return this.props.users.filter((user) => {
      const filterString = `${user.firstName} ${user.lastName} ${
        user.email
      } ${this.getFormattedBirthday(user.dateOfBirth)}`;
      return filterString.toLowerCase().indexOf(this.props.userFilter.toLowerCase()) > -1;
    });
  }

  renderUsers() {
    //renders the users table
    return (
      <>
        {this.getUserFilterType()
          .filter((user) => {
            const filterString = `${user.firstName} ${user.lastName} ${
              user.email
            } ${this.getFormattedBirthday(user.dateOfBirth)}`;
            return filterString.toLowerCase().indexOf(this.props.userFilter.toLowerCase()) > -1;
          })
          .map((user) => (
            <tr
              key={this.props.users.indexOf(user)}
              style={{ display: `${this.userToggleRender(user)}` }}
            >
              <td className="text-center" style={{ width: '23%' }}>
                {`${user.firstName} ${user.lastName}`}
              </td>
              <td className="text-center" style={{ width: '30%' }}>
                {user.email}
              </td>
              <td className="text-center " style={{ width: '17%' }}>
                {this.getFormattedBirthday(user.dateOfBirth)}
              </td>
              <td className="text-center permission-super-admin" style={{ width: '9%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(user.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center " style={{ width: '9%' }}>
                <div
                  data-target="#notesModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(user.id);
                  }}
                >
                  Notes
                </div>
              </td>
              <td className="text-center " style={{ width: '9%' }}>
                <div
                  data-target="#rentalModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(user.id);
                  }}
                >
                  Rentals
                </div>
              </td>
              <td className="text-center " style={{ width: '9%' }}>
                <div
                  data-target="#attendanceModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(user.id);
                  }}
                >
                  Attendance
                </div>
              </td>
              <td className="text-center permission-super-admin" style={{ width: '9%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(user.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getUserName() {
    const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);

    return user ? user.firstName + ' ' + user.lastName : '';
  }

  render() {
    return (
      <div className="users-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Users</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.userFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateUserFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add User
            </div>
            <div
              data-target="#bulkActionsModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Bulk Actions
            </div>
            <div
              data-target="#noteFlagsModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Flags
            </div>
            <div
              data-target="#userFiltersModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Filters
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '23%' }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: '30%' }}>
                    Email
                  </th>
                  <th className="text-center" style={{ width: '17%' }}>
                    Birthday
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Edit
                  </th>
                  <th className="text-center " style={{ width: '9%' }}>
                    Notes
                  </th>
                  <th className="text-center " style={{ width: '9%' }}>
                    Rentals
                  </th>
                  <th className="text-center " style={{ width: '9%' }}>
                    Attendance
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderUsers()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />
        <EditModal />

        <AttendanceModal />

        <RentalModal />

        <BulkActionsModal />

        <NotesModal />
        <CreateNotesModal />
        <EditNotesModal />
        <DeleteNotesModal />

        <NoteFlagsModal />
        <CreateNoteFlagsModal />
        <EditNoteFlagsModal />
        <DeleteNoteFlagsModal />

        <UserFiltersModal
          rentalFilter={this.state.userRentalToggle}
          duplicateFilter={this.state.userDuplicateToggle}
          setFilters={(e) => this.setState(e)}
        />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedUser(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getUserName()}&apos;</strong> will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.userDeleteRequest(this.props.selectedUser);
                    this.props.updateSelectedUser(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedUser(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  userFilter: state.admin.users.userFilter,
  selectedUser: state.admin.users.selectedUser,
  employeeToken: state.auth.employeeToken,
  rentals: state.admin.admin.rentals
});

export default connect(mapStateToProps, {
  updateUserFilter,
  updateSelectedUser
})(Users);
