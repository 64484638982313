import React from 'react';
import './systemUsers.css';
import { connect } from 'react-redux';
import {
  updateSystemUsersFilter,
  updateSelectedSystemUser
} from '../../../actions/adminActions/systemUserActions';
import { systemAuth } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './systemUserModals/editModal.jsx';
import AddModal from './systemUserModals/addModal.jsx';
import ResetPasswordModal from './systemUserModals/resetPasswordModal.jsx';

class SystemUsers extends React.Component {
  async systemUserDeleteRequest(id) {
    await systemAuth
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  getLocation(locationId) {
    const location = this.props.systemLocations.find(
      (location) => location.id === locationId
    ).location;
    return `${location.charAt(0).toUpperCase()}${location.slice(1)}`;
  }

  renderSystemUsers() {
    //renders the SystemUsers table
    return (
      <>
        {this.props.systemUsers
          .filter((systemUser) => {
            const filterString = `${systemUser.username} ${this.getLocation(systemUser.location)}`;
            return (
              filterString.toLowerCase().indexOf(this.props.systemUserFilter.toLowerCase()) > -1
            );
          })
          .map((systemUser) => (
            <tr key={this.props.systemUsers.indexOf(systemUser)}>
              <td className="text-center" style={{ width: '35%' }}>
                {systemUser.username}
              </td>
              <td className="text-center" style={{ width: '30%' }}>
                {this.getLocation(systemUser.location)}
              </td>
              <td className="text-center permission-super-admin " style={{ width: '10%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedSystemUser(systemUser.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center permission-super-admin " style={{ width: '15%' }}>
                <div
                  data-target="#passwordModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedSystemUser(systemUser.id);
                  }}
                >
                  Reset
                </div>
              </td>
              <td className="text-center permission-super-admin " style={{ width: '10%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  style={systemUser.id === this.props.sysId ? { display: 'none' } : {}}
                  onClick={() => {
                    this.props.updateSelectedSystemUser(systemUser.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getSystemUserName() {
    const user = this.props.systemUsers.find((obj) => obj.id === this.props.selectedSystemUser);
    return user ? user.username : '';
  }

  render() {
    return (
      <div className="system-users-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>System Logins</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.systemUser}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateSystemUsersFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add System Login
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '30%' }}>
                    Username
                  </th>
                  <th className="text-center" style={{ width: '30%' }}>
                    Location
                  </th>
                  <th className="text-center permission-super-admin" style={{ width: '10%' }}>
                    Edit
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '20%' }}>
                    Reset Password
                  </th>
                  <th className="text-center permission-super-admin" style={{ width: '10%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderSystemUsers()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <ResetPasswordModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedSystemUser(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                &apos;<strong>{this.getSystemUserName()}</strong>&apos; login will be deleted
                forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.systemUserDeleteRequest(this.props.selectedSystemUser);
                    this.props.updateSelectedSystemUser(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedSystemUser(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sysId: state.auth.sysId,
  systemUsers: state.admin.admin.systemUsers,
  systemUserFilter: state.admin.systemUsers.systemUserFilter,
  selectedSystemUser: state.admin.systemUsers.selectedSystemUser,
  systemLocations: state.admin.admin.systemLocations,
  employeeToken: state.auth.employeeToken,
  empPermission: state.auth.empPermission
});

export default connect(mapStateToProps, {
  updateSystemUsersFilter,
  updateSelectedSystemUser
})(SystemUsers);
