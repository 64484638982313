const INITIAL_STATE = {
  cityFilter: '',
  selectedCity: 0
};

export const citiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_CITIES_PAGE_FILTER_STRING':
      return {
        ...state,
        cityFilter: action.payload
      };
    case 'UPDATE_ADMIN_CITIES_PAGE_SELECTED_CITY':
      return {
        ...state,
        selectedCity: action.payload
      };
    default:
      return state;
  }
};
