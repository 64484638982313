const INITIAL_STATE = {
  showButtons: 0
};

export const activitiesHomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_ACTIVITIES_BUTTONS':
      return {
        ...state,
        showButtons: action.payload
      };
    default:
      return state;
  }
};
