import React from 'react';
import { Link } from 'react-router-dom';
import './partnerActivities.css';
import { connect } from 'react-redux';
import {
  updatePartnerActivityFilter,
  updateSelectedPartnerActivity
} from '../../../actions/adminActions/partnerActivitiesActions.js';
import { activityPartners } from '../../../apis/backend.js'; //make sure to include this for each page
import EditPartnerActivityModal from './partnerActivitiesModals/editPartnerActivityModal.jsx';
import AddPartnerActivityModal from './partnerActivitiesModals/addPartnerActivityModal.jsx';

class PartnerActivities extends React.Component {
  async partnerActivityDeleteRequest(id) {
    await activityPartners
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  formatDate(activityDate) {
    if (!activityDate) return '';
    const date = new Date(activityDate);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return `${date.toDateString()}, ${date.toLocaleTimeString(undefined, options)}`;
  }

  renderpartneractivities() {
    //renders the partners table
    return (
      <>
        {this.props.partnerActivities
          .filter((partnerActivity) => {
            const filterString = this.getActivityName(partnerActivity.activity);
            return (
              partnerActivity.partner == this.props.selectedPartner &&
              filterString.toLowerCase().indexOf(this.props.partnerActivityFilter.toLowerCase()) >
                -1
            );
          })
          .map((partnerActivity) => (
            <tr key={this.props.partnerActivities.indexOf(partnerActivity)}>
              <td className="text-center" style={{ width: '24%' }}>
                {this.getActivityName(partnerActivity.activity)}
              </td>
              <td className="text-center" style={{ width: '23%' }}>
                {this.formatDate(partnerActivity.startTime)}
              </td>
              <td className="text-center " style={{ width: '23%' }}>
                {this.formatDate(partnerActivity.endTime)}
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedPartnerActivity(partnerActivity.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center permission-super-admin " style={{ width: '9%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedPartnerActivity(partnerActivity.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getPartnerName() {
    const partner = this.props.partners.find((obj) => obj.id === this.props.selectedPartner);
    return partner ? partner.name : '';
  }

  getActivityName(id) {
    const activity = this.props.activities.find((obj) => obj.id === id);
    return activity ? activity.name : '';
  }

  getSelectedActivityName() {
    const pp = this.props.partnerActivities.find(
      (obj) => obj.id === this.props.selectedPartnerActivity
    );
    const activity = this.props.activities.find((obj) => obj.id === pp?.activity);
    return activity ? activity.name : '';
  }

  render() {
    return (
      <div className="partner-activities-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>
              <Link
                to="/admin/partners"
                className="btn btn-lg btn-dark"
                style={{ marginRight: '10px' }}
              >
                Back
              </Link>
              {this.getPartnerName()} Activities
            </div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.partnerActivityFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updatePartnerActivityFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark permission-super-admin"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add Partner Activity
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '24%' }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: '23%' }}>
                    Start Date
                  </th>
                  <th className="text-center" style={{ width: '23%' }}>
                    End Date
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Edit
                  </th>
                  <th className="text-center permission-super-admin " style={{ width: '9%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderpartneractivities()}</tbody>
            </table>
          </div>
        </div>

        <AddPartnerActivityModal />
        <EditPartnerActivityModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedPartnerActivity(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getSelectedActivityName()}&apos;</strong>
                will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.partnerActivityDeleteRequest(this.props.selectedPartnerActivity);
                    this.props.updateSelectedPartnerActivity(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedPartnerActivity(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: state.admin.admin.activities,
  partners: state.admin.admin.partners,
  partnerActivities: state.admin.admin.partnerActivities,
  partnerActivityFilter: state.admin.partnerActivities.partnerActivityFilter,
  selectedPartner: state.admin.partners.selectedPartner,
  selectedPartnerActivity: state.admin.partnerActivities.selectedPartnerActivity,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updatePartnerActivityFilter,
  updateSelectedPartnerActivity
})(PartnerActivities);
