import { activities } from '../../apis/backend';

export const populateActivitiesFeed = (feedItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_FEED_ITEMS',
    // payload: feedItems.filter((item) => item.location === 'activities')
    payload: feedItems
  });
};

export const populateActivitiesNews = (newsItems) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_NEWS_ITEMS',
    payload: newsItems
  });
};

export const updateActivitiesFeedItems = (feedItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_FEED_ITEMS',
    payload: [feedItem, ...getState().activities.activities.feedItems]
  });
};

export const updateActivitiesNewsItems = (newsItem) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_NEWS_ITEMS',
    payload: [newsItem, ...getState().activities.activities.newsItems]
  });
};

export const populateActivitiesUsers = (users) => (dispatch) => {
  dispatch({ type: 'POPULATE_ACTIVITIES_USERS', payload: users });
};

export const createActivitiesUser = (user) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_USERS',
    payload: [user, ...getState().activities.activities.users]
  });
};

export const updateActivitiesUser = (updatedUsers) => async (dispatch, getState) => {
  const users = [...getState().activities.activities.users];
  updatedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users[users.indexOf(oldUser)] = user;
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_USERS',
    payload: users
  });
};

export const deleteActivitiesUser = (deletedUsers) => async (dispatch, getState) => {
  const users = [...getState().activities.activities.users];
  deletedUsers.forEach((user) => {
    const oldUser = users.find((obj) => obj.id === user.id);
    users.splice(users.indexOf(oldUser), 1);
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_USERS',
    payload: users
  });
};

export const populateActivitiesInventory = (inventory) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_INVENTORY_ITEMS',
    payload: inventory
  });
};

export const createActivitiesInventory = (inventory) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_INVENTORY_ITEMS',
    payload: [inventory, ...getState().activities.activities.inventory]
  });
};

export const updateActivitiesInventory = (updatedInventory) => async (dispatch, getState) => {
  const inventorys = [...getState().activities.activities.inventory];
  const oldInventory = inventorys.find((obj) => obj.id === updatedInventory.id);
  inventorys[inventorys.indexOf(oldInventory)] = updatedInventory;
  dispatch({
    type: 'POPULATE_ACTIVITIES_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const deleteActivitiesInventory = (deletedInventorys) => async (dispatch, getState) => {
  const inventorys = [...getState().activities.activities.inventory];
  deletedInventorys.forEach((inventory) => {
    const oldInventory = inventorys.find((obj) => obj.id === inventory.id);
    inventorys.splice(inventorys.indexOf(oldInventory), 1);
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_INVENTORY_ITEMS',
    payload: inventorys
  });
};

export const clearActivitiesPage = () => (dispatch, getState) => {
  const { ticketAttendanceInterval, cashAttendanceInterval } = getState().activities.activities;
  clearInterval(ticketAttendanceInterval);
  clearInterval(cashAttendanceInterval);
  dispatch({ type: 'CLEAR_ACTIVITIES_PAGE_DATA' });
};

export const initializeActivities = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_ACTIVITIES_PAGE' });
};

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({
    type: 'CHANGE_ACTIVITIES_ACTIVE_TAB',
    payload: index
  });
};

export const incrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_ACTIVITIES_NEWS_UNREAD_TALLY',
    payload: getState().activities.activities.newsUnreadTally + 1
  });
};

export const decrementNewsUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'DECREMENT_ACTIVITIES_NEWS_UNREAD_TALLY',
    payload: getState().activities.activities.newsUnreadTally - 1
  });
};

export const incrementActivityUnreadTally = () => (dispatch, getState) => {
  dispatch({
    type: 'INCREMENT_ACTIVITIES_ACTIVITY_UNREAD_TALLY',
    payload: getState().activities.activities.activityUnreadTally + 1
  });
};

export const clearUnreadTally = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ACTIVITIES_UNREAD_TALLY',
    payload: 0
  });
};

export const getAllActivities = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_ACTIVITIES',
    payload: activities
  });
};

export const getAllCities = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_CITIES',
    payload: activities
  });
};

export const getAllSites = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_SITES',
    payload: activities
  });
};

export const getAllDetails = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_DETAILS',
    payload: activities
  });
};

export const getAllPartners = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_PARTNERS',
    payload: activities
  });
};

export const getAllProviders = (activities) => (dispatch) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_PROVIDERS',
    payload: activities
  });
};

export const setCurrentActivity = (activity) => (dispatch) => {
  dispatch({
    type: 'GET_CURRENT_ACTIVITY',
    payload: activity
  });
};

export const createActivity = (activity) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_ACTIVITIES',
    payload: [activity, ...getState().activities.activities.activities].sort(
      (a, b) => a.startTime < b.startTime
    )
  });
};

export const updateActivity = (updatedActivities) => async (dispatch, getState) => {
  const activities = [...getState().activities.activities.activities];
  updatedActivities.forEach((activity) => {
    const oldActivity = activities.find((obj) => obj.id === activity.id);
    activities[activities.indexOf(oldActivity)] = activity;
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_ACTIVITIES',
    payload: activities
  });
};

export const deleteActivity = (deletedActivities) => async (dispatch, getState) => {
  const activities = [...getState().activities.activities.activities];
  deletedActivities.forEach((activity) => {
    const oldActivity = activities.find((obj) => obj.id === activity.id);
    activities.splice(activities.indexOf(oldActivity), 1);
    if (
      oldActivity.id === getState().activities.activities.selectedActivityId &&
      activities.length > 0
    ) {
      dispatch({
        type: 'GET_CURRENT_ACTIVITY',
        payload: activities[0].id
      });
    }
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_PAGE_ACTIVITIES',
    payload: activities
  });
};

export const feedItemTimerExpired = (feedItem) => async (dispatch, getState) => {
  const activitiesList = [...getState().activities.activities.activities];
  const activity =
    activitiesList[
      activitiesList.indexOf(
        activitiesList.find((obj) => obj.id === getState().activities.activities.selectedActivityId)
      )
    ];
  const data = {};
  if (feedItem.id === 'tempCash') {
    data.cashAttendance = activity.cashAttendance + feedItem.updateValue;
  } else {
    data.ticketAttendance = activity.ticketAttendance + feedItem.updateValue;
  }
  await activities
    .patch(`/${activity.id}`, data, {
      headers: { authorization: getState().auth.employeeToken }
    })
    .catch((response) => {
      console.log(response);
    });
};

export const removeExistingActivitiesFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_ACTIVITIES_PAGE_CASH_INTERVAL';
    interval = await getState().activities.activities.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_ACTIVITIES_PAGE_TICKET_INTERVAL';
    interval = await getState().activities.activities.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  const feedItems = [...getState().activities.activities.feedItems];
  const feedItem = feedItems.find((obj) => obj.id === updatedFeedItem.id);
  if (feedItem) {
    feedItems.splice(feedItems.indexOf(feedItem), 1);
  }
  dispatch({ type: 'POPULATE_ACTIVITIES_FEED_ITEMS', payload: feedItems });
  dispatch({ type: actionType, payload: null });
};

export const addToExistingActivitiesFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_ACTIVITIES_PAGE_CASH_INTERVAL';
    interval = await getState().activities.activities.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_ACTIVITIES_PAGE_TICKET_INTERVAL';
    interval = await getState().activities.activities.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  dispatch({
    type: 'POPULATE_ACTIVITIES_FEED_ITEMS',
    payload: [updatedFeedItem, ...getState().activities.activities.feedItems]
  });
  const tempInterval = setInterval(() => {
    dispatch(feedItemTimerExpired(updatedFeedItem));
    dispatch(removeExistingActivitiesFeedItem(updatedFeedItem));
  }, 5000);
  dispatch({ type: actionType, payload: tempInterval });
};

export const updateExistingActivitiesFeedItem = (updatedFeedItem) => async (dispatch, getState) => {
  let actionType;
  let interval;
  if (updatedFeedItem.id === 'tempCash') {
    actionType = 'UPDATE_ACTIVITIES_PAGE_CASH_INTERVAL';
    interval = await getState().activities.activities.cashAttendanceInterval;
  } else {
    actionType = 'UPDATE_ACTIVITIES_PAGE_TICKET_INTERVAL';
    interval = await getState().activities.activities.ticketAttendanceInterval;
  }
  if (interval) {
    clearInterval(interval);
  }
  const feedItems = [...getState().activities.activities.feedItems];
  const feedItem = feedItems.find((obj) => obj.id === updatedFeedItem.id);
  if (feedItem) {
    feedItems[feedItems.indexOf(feedItem)] = updatedFeedItem;
  }
  dispatch({ type: 'POPULATE_ACTIVITIES_FEED_ITEMS', payload: feedItems });
  const tempInterval = setInterval(() => {
    dispatch(feedItemTimerExpired(updatedFeedItem));
    dispatch(removeExistingActivitiesFeedItem(updatedFeedItem));
  }, 5000);
  dispatch({ type: actionType, payload: tempInterval });
};

export const updateActivitiesAttendance = (attendance) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_PAGE_USERS_ATTENDANCE',
    payload: attendance
  });
};

export const populateActivitiesUserNotes = (notes) => (dispatch) => {
  dispatch({ type: 'POPULATE_ACTIVITIES_USER_NOTES', payload: notes });
};

export const createActivitiesUserNote = (note) => async (dispatch, getState) => {
  dispatch({
    type: 'POPULATE_ACTIVITIES_USER_NOTES',
    payload: [note, ...getState().activities.activities.userNotes]
  });
};

export const updateActivitiesUserNote = (updatedNotes) => async (dispatch, getState) => {
  const notes = [...getState().activities.activities.userNotes];
  updatedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes[notes.indexOf(oldNote)] = note;
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_USER_NOTES',
    payload: notes
  });
};

export const deleteActivitiesUserNote = (deletedNotes) => async (dispatch, getState) => {
  const notes = [...getState().activities.activities.userNotes];
  deletedNotes.forEach((note) => {
    const oldNote = notes.find((obj) => obj.id === note.id);
    notes.splice(notes.indexOf(oldNote), 1);
  });
  dispatch({
    type: 'POPULATE_ACTIVITIES_USER_NOTES',
    payload: notes
  });
};
