import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { activityPartners } from '../../../../apis/backend';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      partner: '',
      activity: '',
      startTime: '',
      startDate: '',
      endTime: '',
      endDate: ''
    };
  }

  clearState() {
    this.setState({
      id: '',
      partner: '',
      activity: '',
      startTime: '',
      startDate: '',
      endTime: '',
      endDate: ''
    });
  }

  async partnerActivityRequest() {
    try {
      await activityPartners
        .post(
          '/',
          {
            // "field in the db that you are adding: data to be added"
            // look in the backend/api/models to see which fields are required
            id: this.state.id,
            partner: this.props.selectedPartner,
            activity: this.state.activity,
            startTime: this.state.startDate + 'T' + this.state.startTime,
            endTime: this.state.endDate + 'T' + this.state.endTime
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Partner Activity</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Partner Activity Name
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.activity}
                  onChange={(e) => this.setState({ activity: e.target.value })}
                >
                  <option value="" disabled>
                    Select Activity
                  </option>
                  {this.props.activities.map((activity) => (
                    <option value={activity.id} key={activity.id}>
                      {activity.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Start Date
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.startDate}
                  onChange={(e) => this.setState({ startDate: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Start Time
                  </span>
                </div>
                <input
                  type="time"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.startTime}
                  onChange={(e) => this.setState({ startTime: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    End Date
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.endDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    End Time
                  </span>
                </div>
                <input
                  type="time"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.endTime}
                  onChange={(e) => this.setState({ endTime: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.partnerActivityRequest();
                }}
                disabled={!this.state.activity != ''}
              >
                Add Partner Activity
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: state.admin.admin.activities,
  selectedPartner: state.admin.partners.selectedPartner,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(AddModal);
