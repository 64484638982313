import React from 'react';
import './home.css';
import FeedItem from '../../utils/feeditem/feedItem.jsx';
import { connect } from 'react-redux';
import Logo from '../../../images/events.png';
import FeedView from '../../utils/feedView/feedView.jsx';
import Page from '../../utils/page/page';
import { lock } from '../../../actions/authActions';
import {
  changeActiveTab,
  clearUnreadTally,
  updateActivitiesFeedItems,
  populateActivitiesFeed,
  updateExistingActivitiesFeedItem,
  removeExistingActivitiesFeedItem,
  addToExistingActivitiesFeedItem
} from '../../../actions/activitiesPageActions/activitiesActions.js';
import { updateShowButtons } from '../../../actions/activitiesPageActions/homeActions.js';

class HomePage extends React.Component {
  async changeCashAttendance(value) {
    let feedItem = this.props.feedItems.find((obj) => obj.id === 'tempCash');
    if (!feedItem) {
      feedItem = {
        id: 'tempCash',
        status: 'yellow',
        user: 'Updating Cash Attendance',
        userId: null,
        messages: ['Cash Attendance decreased: -1'],
        updateValue: -1
      };
      if (value > 0) {
        feedItem.messages = ['Cash Attendance increased: 1'];
        feedItem.updateValue = 1;
      }
      this.props.addToExistingActivitiesFeedItem(feedItem);
    } else {
      feedItem.updateValue += value;
      if (feedItem.updateValue > 0) {
        feedItem.messages = [`Attendance increased: ${feedItem.updateValue}`];
        this.props.updateExistingActivitiesFeedItem(feedItem);
      } else if (feedItem.updateValue < 0) {
        feedItem.messages = [`Attendance decreased: ${feedItem.updateValue}`];
        this.props.updateExistingActivitiesFeedItem(feedItem);
      } else {
        this.props.removeExistingActivitiesFeedItem(feedItem);
      }
    }
  }

  async changeTicketAttendance(value) {
    let feedItem = this.props.feedItems.find((obj) => obj.id === 'tempTicket');
    if (!feedItem) {
      feedItem = {
        id: 'tempTicket',
        status: 'yellow',
        user: 'Updating Ticket Attendance',
        userId: null,
        messages: ['Ticket Attendance decreased: -1'],
        updateValue: -1
      };
      if (value > 0) {
        feedItem.messages = ['Ticket Attendance increased: 1'];
        feedItem.updateValue = 1;
      }
      this.props.addToExistingActivitiesFeedItem(feedItem);
    } else {
      feedItem.updateValue += value;
      if (feedItem.updateValue > 0) {
        feedItem.messages = [`Ticket Attendance increased: ${feedItem.updateValue}`];
        this.props.updateExistingActivitiesFeedItem(feedItem);
      } else if (feedItem.updateValue < 0) {
        feedItem.messages = [`Ticket Attendance decreased: ${feedItem.updateValue}`];
        this.props.updateExistingActivitiesFeedItem(feedItem);
      } else {
        this.props.removeExistingActivitiesFeedItem(feedItem);
      }
    }
  }

  calculateFontSize() {
    const maxWidth = 400;

    let fontSize = 30;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Get the activity name
    const activityName = this.getActivityName();

    do {
      context.font = `${fontSize}px 'Oswald', sans-serif`;
      const textWidth = context.measureText(activityName).width;

      if (textWidth < maxWidth) {
        return fontSize;
      }

      fontSize--;
    } while (fontSize > 0);

    return 30;
  }

  hasAttendance(activity, totalAttendance) {
    if (activity.activitySetting !== 'skatepark') {
      return (
        <div className="activityText">
          Total Attendance: {isNaN(totalAttendance) ? 0 : totalAttendance}
        </div>
      );
    }
  }

  getActivityName() {
    var fullName = this.props.selectActivity.activityName;

    if (this.props.selectActivity.activityDetails.length > 0) {
      const detailNames = this.props.selectActivity.activityDetails.map(
        (detail) => detail.activityDetail
      );
      if (detailNames.length === 1) {
        fullName = fullName + ' - ' + detailNames.join('');
      } else {
        fullName = fullName + ' - ' + detailNames.join(', ');
      }
    }

    if (this.props.selectActivity.activityProvidersSelected !== 0) {
      fullName = fullName + ' (' + this.props.selectActivity.activityProviders + ')';
    }
    fullName =
      fullName +
      ', ' +
      this.props.selectActivity.activityCity +
      ' - ' +
      this.props.selectActivity.activitySite;

    return fullName;
  }

  render() {
    let feedItems;
    let newsItems;

    // Dynamically displays feed items
    if (this.props.feedItems.length === 0) {
      feedItems = <h1>No items to display</h1>;
    } else {
      feedItems = this.props.feedItems.map((log) => (
        <FeedItem
          title={log.user}
          status={log.status}
          messages={log.messages}
          userId={log.userId}
          id={log.id}
          key={log.id}
          history={this.props.history}
          type="activities"
        />
      ));
    }

    // Dynamically displays news items
    if (this.props.newsItems.length === 0) {
      newsItems = <h1>No items to display</h1>;
    } else {
      newsItems = this.props.newsItems.map((news) => (
        <FeedItem
          title={news.title}
          status={news.read ? 'read' : 'unread'}
          messages={[news.message]}
          id={news.id}
          key={news.id}
          history={this.props.history}
          type="news"
        />
      ));
    }

    let scannerButtonText = 'Assign Scanner';
    if (this.props.scannerName.length > 0) {
      scannerButtonText = scannerButtonText + `: ${this.props.scannerName}`;
    }

    const skateparkButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/activities/home/register')
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/activities/rfid')
      },
      {
        text: 'Manual Check-In',
        onClick: () => this.props.history.push('/activities/home/checkin')
      },
      {
        text: 'User Notes',
        onClick: () => this.props.history.push('/activities/notescheckin')
      },
      // {
      //   text: 'TODO: Purchase',
      //   onClick: () => this.props.history.push('/activities/purchasecheckin')
      // },
      {
        text: 'Rentals',
        onClick: () => this.props.history.push('/activities/rentalscheckin')
      },
      {
        text: 'Inventory',
        onClick: () => this.props.history.push('/activities/inventory')
      },
      {
        text: 'Select Activity',
        onClick: () => this.props.history.push('/activities')
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock()
      }
    ];

    const activityButtons = [
      {
        text: 'Register User',
        onClick: () => this.props.history.push('/activities/home/register')
      },
      {
        text: 'Register RFID',
        onClick: () => this.props.history.push('/activities/rfid')
      },
      {
        text: scannerButtonText,
        onClick: () => this.props.history.push('/activities/scanner')
      },
      {
        text: 'Manual Check-In',
        onClick: () => this.props.history.push('/activities/home/checkin')
      },
      {
        text: 'User Notes',
        onClick: () => this.props.history.push('/activities/notescheckin')
      },
      {
        text: 'Select Activity',
        onClick: () => this.props.history.push('/activities')
      },
      {
        text: 'Lock',
        onClick: () => this.props.lock()
      }
    ];

    // const menuButtons = activity.activitySetting === 'skatepark' ? skateparkButtons : activityButtons;
    const menuButtons = activityButtons;

    const attendanceButtonTypes = [
      {
        text: 'Cash Attendance',
        leftButtonText: <strong>&minus;</strong>,
        leftClick: async () => {
          await this.changeCashAttendance(-1);
        },
        rightButtonText: <strong>&#43;</strong>,
        rightClick: async () => {
          await this.changeCashAttendance(1);
        }
      },
      {
        text: 'Ticket attendance',
        leftButtonText: <strong>&minus;</strong>,
        leftClick: async () => {
          await this.changeTicketAttendance(-1);
        },
        rightButtonText: <strong>&#43;</strong>,
        rightClick: async () => {
          await this.changeTicketAttendance(1);
        }
      }
    ];

    // const attendanceButtonList =
    //   activity.activitySetting === 'skatepark' ? [] : attendanceButtonTypes;

    return (
      <Page>
        <div className="activitiesPageColumn">
          <div className="activitiesButtonColumnContain">
            <div className="activitiesButtonColumnImageContain">
              <img src={Logo} alt="logo" style={{ maxWidth: '80%' }} />
            </div>
            {menuButtons.map((button) => (
              <button
                className="activitiesButtonColumnButton"
                onClick={() => button.onClick()}
                key={button.text}
              >
                {button.text}
              </button>
            ))}
          </div>
        </div>
        <div className="activitiesPageColumn">
          <div
            className={`${
              this.props.selectActivity.activityName.includes('kate')
                ? 'skateAttendanceText'
                : 'attendanceText'
            }`}
          >
            <div className="activityText" style={{ fontSize: `${this.calculateFontSize()}px` }}>
              {this.getActivityName()}
            </div>
            {/* {this.hasAttendance(activity, totalAttendance)} */}
          </div>
          <div
            className={`${
              this.props.selectActivity.activityName.includes('kate')
                ? 'skateFeedContainer'
                : 'feedContainer'
            }`}
          >
            <FeedView
              activity={feedItems}
              updates={newsItems}
              activeTab={this.props.activeTab}
              tabClick={(index) => {
                this.props.changeActiveTab(index);
                this.props.clearUnreadTally();
                this.props.updateShowButtons(0);
              }}
              tally={{
                newsUnreadTally: this.props.newsUnreadTally,
                activityUnreadTally: this.props.activityUnreadTally
              }}
            ></FeedView>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  feedItems: state.activities.activities.feedItems,
  newsItems: state.activities.activities.newsItems,
  activeTab: state.activities.activities.activeTab,
  newsUnreadTally: state.activities.activities.newsUnreadTally,
  activityUnreadTally: state.activities.activities.activityUnreadTally,
  selectActivity: state.activities.selectActivity,
  scannerName: state.activities.scanner.name,
  ticketAttendanceInterval: state.activities.ticketAttendanceInterval
});

export default connect(mapStateToProps, {
  changeActiveTab,
  clearUnreadTally,
  lock,
  updateShowButtons,
  updateActivitiesFeedItems,
  populateActivitiesFeed,
  updateExistingActivitiesFeedItem,
  removeExistingActivitiesFeedItem,
  addToExistingActivitiesFeedItem
})(HomePage);
