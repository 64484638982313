const INITIAL_STATE = {
  pin: [],
  incorrectAnimation: false
};

export const keypadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIALIZE_KEYPAD':
      return {
        ...state,
        pin: [],
        incorrectAnimation: false
      };
    case 'ADD_TO_PIN':
      // if (state.pin.length === 4) {
      //     return {
      //         ...state,
      //         pin: []
      //     };
      // } else {
      return {
        ...state,
        pin: [...state.pin, action.payload]
      };
    //}
    case 'REMOVE_FROM_PIN':
      state.pin.pop();
      return {
        ...state,
        pin: [...state.pin]
      };
    case 'CLEAR_PIN':
      return {
        ...state,
        pin: []
      };
    case 'PIN_INCORRECT_ON':
      return {
        ...state,
        incorrectAnimation: true
      };
    case 'PIN_INCORRECT_OFF':
      return {
        ...state,
        incorrectAnimation: false
      };
    default:
      return state;
  }
};
