export const clearInventoryPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_ACTIVITIES_INVENTORY_PAGE' });
};

export const updateSelectedInventory = (selected) => (dispatch, getState) => {
  const { selectedInventory } = getState().activities.inventory;
  if (selectedInventory === selected) {
    dispatch({
      type: 'ACTIVITIES_INVENTORY_PAGE_SELECTED_INVENTORY',
      payload: 0
    });
  } else {
    dispatch({
      type: 'ACTIVITIES_INVENTORY_PAGE_SELECTED_INVENTORY',
      payload: selected
    });
  }
};

export const updateInventoryFilter = (filter) => (dispatch) => {
  dispatch({
    type: 'ACTIVITIES_INVENTORY_PAGE_UPDATE_FILTER_STRING',
    payload: filter
  });
};

export const updateEditSelectedInventory = (selectedInventory) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_INVENTORY_EDIT_PAGE_SELECTED_INVENTORY',
    payload: selectedInventory
  });
};

export const clearEditPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_ACTIVITIES_INVENTORY_EDIT_PAGE' });
};

export const updateInventoryComment = (comment) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ACTIVITIES_INVENTORY_EDIT_PAGE_COMMENT',
    payload: comment
  });
};

export const updateInventoryStatus = (status) => (dispatch) => {
  let stat = 'broken';
  if (status.toLowerCase() === 'ready') {
    stat = 'ready';
  } else if (status.toLowerCase() === 'warning') {
    stat = 'warning';
  }
  dispatch({
    type: 'UPDATE_ACTIVITIES_INVENTORY_EDIT_PAGE_STATUS',
    payload: stat
  });
};
