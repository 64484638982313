const INITIAL_STATE = {
  systemUserFilter: '',
  selectedSystemUser: 0
};

export const systemUsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_SYSTEM_USER_PAGE_FILTER_STRING':
      return {
        ...state,
        systemUserFilter: action.payload
      };
    case 'UPDATE_ADMIN_SYSTEM_USER_PAGE_SELECTED_SYSTEM_USER':
      return {
        ...state,
        selectedSystemUser: action.payload
      };
    default:
      return state;
  }
};
