const INITIAL_STATE = {
  providerFilter: '',
  selectedProvider: 0
};

export const providersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_PROVIDERS_PAGE_FILTER_STRING':
      return {
        ...state,
        providerFilter: action.payload
      };
    case 'UPDATE_ADMIN_PROVIDERS_PAGE_SELECTED_PROVIDER':
      return {
        ...state,
        selectedProvider: action.payload
      };
    default:
      return state;
  }
};
