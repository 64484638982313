const INITIAL_STATE = {
  activityDetailFilter: '',
  selectedActivityDetail: 0
};

export const activityDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_ACTIVITY_DETAILS_PAGE_FILTER_STRING':
      return {
        ...state,
        activityDetailFilter: action.payload
      };
    case 'UPDATE_ADMIN_ACTIVITY_DETAILS_PAGE_SELECTED_ACTIVITY_DETAIL':
      return {
        ...state,
        selectedActivityDetail: action.payload
      };
    default:
      return state;
  }
};
