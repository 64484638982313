const INITIAL_STATE = {
  feedItems: [],
  feedItemsLoaded: false,
  users: [],
  usersLoaded: false,
  loaded: false
};

export const coffeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POPULATE_COFFEE_FEED_ITEMS':
      return {
        ...state,
        feedItems: action.payload,
        feedItemsLoaded: true
      };
    case 'POPULATE_COFFEE_USERS':
      return {
        ...state,
        users: action.payload,
        usersLoaded: true
      };
    case 'CLEAR_COFFEE_PAGE_DATA':
      return INITIAL_STATE;
    case 'INITIALIZE_COFFEE_PAGE':
      return {
        ...state,
        loaded: true
      };
    default:
      return state;
  }
};
