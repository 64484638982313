export const updateActivityName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_NAME',
    payload: name
  });
};

export const updateActivitySelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_SELECTED',
    payload: getState().activities.selectActivity.activitySelected === id ? 0 : id
  });
};

export const updateProgram = (name) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_PROGRAM_NAME',
    payload: name
  });
};

export const updateProgramSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_PROGRAM_SELECTED',
    payload: getState().activities.selectActivity.activityProgramSelected === id ? 0 : id
  });
};

export const updateActivityCity = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_CITY',
    payload: name
  });
};

export const updateActivityCitySelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_CITY_SELECTED',
    payload: getState().activities.selectActivity.activityCitySelected === id ? 0 : id
  });
};

export const updateActivitySite = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_SITE',
    payload: name
  });
};

export const updateActivitySiteSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_SITE_SELECTED',
    payload: getState().activities.selectActivity.activitySiteSelected === id ? 0 : id
  });
};

export const updateActivityDetails = (details) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_DETAILS',
    payload: details
  });
};

export const updateActivityDetailsSelected = (detailIds) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_DETAILS_SELECTED',
    payload: detailIds
  });
};

export const updateActivityPartner = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_PARTNERS',
    payload: name
  });
};

export const updateActivityPartnerSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_PARTNERS_SELECTED',
    payload: getState().activities.selectActivity.activityPartnersSelected === id ? 0 : id
  });
};

export const updateActivityProvider = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_PROVIDERS',
    payload: name
  });
};

export const updateActivityProviderSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_ACTIVITIES_PROVIDERS_SELECTED',
    payload: getState().activities.selectActivity.activityProvidersSelected === id ? 0 : id
  });
};

export const clearSelection = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CURRENT_ACTIVITIES_SELECTED'
  });
};
