export const updateSkatePunchFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SKATEPUNCHES_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedSkatePunch = (skatePunch) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SKATEPUNCHES_PAGE_SELECTED_SKATEPUNCHES',
    payload: skatePunch
  });
};
