export const updateSiteFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_SITES_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedSite = (Site) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_SITES_PAGE_SELECTED_SITE', payload: Site });
};
