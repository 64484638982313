const INITIAL_STATE = {
  feedItems: [],
  feedItemsLoaded: false,
  newsItems: [],
  newsItemsLoaded: false,
  users: [],
  usersLoaded: false,
  inventory: [],
  inventoryLoaded: false,
  activities: [],
  activitiesLoaded: false,
  cities: [],
  citiesLoaded: false,
  sites: [],
  sitesLoaded: false,
  details: [],
  detailsLoaded: false,
  partners: [],
  partnersLoaded: false,
  providers: [],
  providersLoaded: false,
  loaded: false,
  activeTab: 0,
  newsUnreadTally: 0,
  activityUnreadTally: 0,
  ticketAttendanceInterval: null,
  cashAttendanceInterval: null,
  attendance: [],
  userNotes: [],
  userNotesLoaded: false
};

export const activitiesPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POPULATE_ACTIVITIES_FEED_ITEMS':
      return {
        ...state,
        feedItems: action.payload,
        feedItemsLoaded: true
      };
    case 'POPULATE_ACTIVITIES_NEWS_ITEMS':
      return {
        ...state,
        newsItems: action.payload,
        newsItemsLoaded: true,
        newsUnreadTally: action.payload.filter((item) => item.read === false).length
      };
    case 'POPULATE_ACTIVITIES_USERS':
      return {
        ...state,
        users: action.payload,
        usersLoaded: true
      };
    case 'POPULATE_ACTIVITIES_INVENTORY_ITEMS':
      return {
        ...state,
        inventory: action.payload,
        inventoryLoaded: true
      };
    case 'CLEAR_ACTIVITIES_PAGE_DATA':
      return INITIAL_STATE;
    case 'INITIALIZE_ACTIVITIES_PAGE':
      return {
        ...state,
        loaded: true
      };
    case 'CHANGE_ACTIVITIES_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload
      };
    case 'INCREMENT_ACTIVITIES_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload
      };
    case 'DECREMENT_ACTIVITIES_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload
      };
    case 'INCREMENT_ACTIVITIES_ACTIVITY_UNREAD_TALLY':
      return {
        ...state,
        activityUnreadTally: action.payload
      };
    case 'CLEAR_ACTIVITIES_UNREAD_TALLY':
      return {
        ...state,
        // newsUnreadTally: 0,
        activityUnreadTally: 0
      };
    case 'POPULATE_ACTIVITIES_PAGE_ACTIVITIES':
      return {
        ...state,
        activities: action.payload,
        activitiesLoaded: true
      };
    case 'POPULATE_ACTIVITIES_PAGE_CITIES':
      return {
        ...state,
        cities: action.payload,
        citiesLoaded: true
      };
    case 'POPULATE_ACTIVITIES_PAGE_SITES':
      return {
        ...state,
        sites: action.payload,
        sitesLoaded: true
      };
    case 'POPULATE_ACTIVITIES_PAGE_DETAILS':
      return {
        ...state,
        details: action.payload,
        detailsLoaded: true
      };
    case 'POPULATE_ACTIVITIES_PAGE_PARTNERS':
      return {
        ...state,
        partners: action.payload,
        partnersLoaded: true
      };
    case 'POPULATE_ACTIVITIES_PAGE_PROVIDERS':
      return {
        ...state,
        providers: action.payload,
        providersLoaded: true
      };
    case 'UPDATE_ACTIVITIES_PAGE_TICKET_INTERVAL':
      return {
        ...state,
        ticketAttendanceInterval: action.payload
      };
    case 'UPDATE_ACTIVITIES_PAGE_CASH_INTERVAL':
      return {
        ...state,
        cashAttendanceInterval: action.payload
      };
    case 'UPDATE_ACTIVITIES_PAGE_USERS_ATTENDANCE':
      return {
        ...state,
        attendance: action.payload
      };
    case 'POPULATE_ACTIVITIES_USER_NOTES':
      return {
        ...state,
        userNotes: action.payload,
        userNotesLoaded: true
      };
    default:
      return state;
  }
};
