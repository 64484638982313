import React from 'react';
import './rfid-register.css';
import {
  updateName,
  updateSelected,
  clearSelection
} from '../../../actions/collectivePageActions/checkinActions';
import { connect } from 'react-redux';
import {
  updateRfidScans,
  clearRfidRegisterPage,
  updateRfidSelected
} from '../../../actions/collectivePageActions/rfidRegisterActions';
import Page from '../../utils/page/page.jsx';
import ScrollView from '../../utils/scrollView/scrollView.jsx';
import NotFound from '../../404/404';

import scan from '../../../images/rfid_icon.png';
import { users } from '../../../apis/backend';

class RFIDScan extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/rfid/subscribe', (resData) => {
        // console.log('Listening for unregistered RFID...');
      });
      this.props.io.on('rfidregister', (event) => {
        this.props.updateRfidScans(event);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentWillUnmount() {
    try {
      this.props.io.off('rfidregister');
      this.props.clearRfidRegisterPage();
    } catch (err) {
      console.log(err);
    }
  }

  renderCheckInButtons() {
    return {
      left: {
        title: 'Cancel',
        onClick: () => this.props.history.push('/collective')
      },
      right: {
        title: 'Continue',
        onClick: async () => {
          await users
            .patch(
              `/${this.props.match.params.id}`,
              { rfid: this.props.rfidScans[this.props.selected].rfid },
              { headers: { authorization: this.props.employeeToken } }
            )
            .then(() => this.props.history.push('/collective'))
            .catch((err) => console.log(err));
        },
        disabled: this.props.selected === -1
      }
    };
  }

  getUserName() {
    const user = this.props.users.find((obj) => obj.id === parseInt(this.props.match.params.id));
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return '';
  }

  renderRfidOption() {
    if (this.props.rfidScans.length === 0) {
      return (
        <div className="scan-container">
          <div className="scan-now-text">
            <p>SCAN NOW TO REGISTER:</p>
            <p>{this.getUserName()}</p>
          </div>

          <div className="rfid-scan-icon">
            <img src={scan} alt="scan"></img>
          </div>

          <div className="scan-status-text">
            <p>WAITING FOR SCAN...</p>
          </div>
        </div>
      );
    }
    return undefined;
  }

  renderOptions() {
    if (this.props.rfidScans.length === 0) {
      return undefined;
    }
    const options = {
      columns: [{ width: 70 }, { width: 30 }],
      titles: ['RFID', 'Time'],
      entries: [],
      selectEvent: this.props.updateRfidSelected,
      selected: this.props.selected
    };
    this.props.rfidScans.forEach((scan) => {
      options.entries.push({
        id: this.props.rfidScans.indexOf(scan),
        columns: [scan.rfid, scan.date.toUTCString()]
      });
    });
    return options;
  }

  render() {
    if (!this.props.users.map((user) => user.id).includes(parseInt(this.props.match.params.id))) {
      return <NotFound />;
    }
    return (
      <Page>
        <ScrollView
          options={this.renderOptions()}
          buttons={this.renderCheckInButtons()}
          // searchBar={{
          //     filterString: this.props.name,
          //     onChange: this.props.updateName
          // }}
        >
          {this.renderRfidOption()}
        </ScrollView>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.collective.checkin.name,
  users: state.collective.collective.users,
  selected: state.collective.rfidRegister.selected,
  io: state.application.io,
  rfidScans: state.collective.rfidRegister.rfidScans,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateName,
  updateSelected,
  clearSelection,
  updateRfidScans,
  clearRfidRegisterPage,
  updateRfidSelected
})(RFIDScan);
