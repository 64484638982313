export const updateName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_EVENTS_NAME',
    payload: name
  });
};

export const updateSelected = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_CURRENT_EVENTS_SELECTED',
    payload: getState().events.selectEvent.selected === id ? 0 : id
  });
};

export const clearSelection = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_CURRENT_EVENTS_SELECTED'
  });
};

export const updateFilter = (filter) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CURRENT_EVENTS_FILTER',
    payload: filter
  });
};
