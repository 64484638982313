export const updateCityFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_CITIES_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedCity = (City) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_CITIES_PAGE_SELECTED_CITY', payload: City });
};
