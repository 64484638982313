export const clearScannerRegisterPage = () => (dispatch) => {
  dispatch({ type: 'CLEAR_ACTIVITIES_REGISTER_SCANNER_PAGE' });
};

export const updateScannerSelected = (selected) => (dispatch, getState) => {
  dispatch({ type: 'UPDATE_ACTIVITIES_REGISTER_SCANNER_SELECTED', payload: selected });
};

export const updateScannerEndDate = (date) => (dispatch) => {
  dispatch({ type: 'UPDATE_ACTIVITIES_REGISTER_SCANNER_END_DATE', payload: date });
};

export const updateScannerFilter = (filter) => (dispatch) => {
  dispatch({ type: 'UPDATE_ACTIVITIES_REGISTER_SCANNER_FILTER', payload: filter });
};
