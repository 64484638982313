export const updateProgramFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PROGRAMS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedProgram = (Program) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_PROGRAMS_PAGE_SELECTED_PROGRAM', payload: Program });
};
