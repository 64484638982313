const INITIAL_STATE = {
  extraInventoryItemInfoVisible: false
};

export const collectiveInventoryEditPopupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_COLLECTIVE_INVENTORY_EDIT_MODAL_ACTIVE':
      return {
        ...state,
        extraInventoryItemInfoVisible: action.payload
      };
    case 'CLEAR_COLLECTIVE_INVENTORY_EDIT_MODAL':
      return {
        ...state,
        extraInventoryItemInfoVisible: false
      };
    default:
      return state;
  }
};
