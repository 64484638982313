import React, { Component } from 'react';
import './../../Modal.css';
import { noteflags } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedFlag } from '../../../../actions/adminActions/usersActions';

class DeleteNoteFlagsModal extends Component {
  async flagDeleteRequest(id) {
    await noteflags
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  render() {
    const flag = this.props.noteFlags.find((obj) => obj.id === this.props.selectedFlag);
    return (
      <div className="modal fade" id="deleteNoteFlag">
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Flags</h5>
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
              The <strong style={{ color: `${flag?.color}` }}>{flag?.name}</strong> flag and all
              associated user notes and logs will be deleted forever!!
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.flagDeleteRequest(this.props.selectedFlag);
                  this.props.updateSelectedFlag(0);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noteFlags: state.admin.admin.noteFlags,
  selectedFlag: state.admin.users.selectedFlag,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedFlag })(DeleteNoteFlagsModal);
