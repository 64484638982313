const INITIAL_STATE = {
  accessFilter: '',
  selectedAccess: 0
};

export const accessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_ACCESS_PAGE_FILTER_STRING':
      return {
        ...state,
        accessFilter: action.payload
      };
    case 'UPDATE_ADMIN_ACCESS_PAGE_SELECTED_ACCESS':
      return {
        ...state,
        selectedAccess: action.payload
      };
    default:
      return state;
  }
};
