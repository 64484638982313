const INITIAL_STATE = {
  activityFilter: '',
  selectedActivity: 0
};

export const activitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_ACTIVITIES_PAGE_FILTER_STRING':
      return {
        ...state,
        activityFilter: action.payload
      };
    case 'UPDATE_ADMIN_ACTIVITIES_PAGE_SELECTED_ACTIVITY':
      return {
        ...state,
        selectedActivity: action.payload
      };
    default:
      return state;
  }
};
