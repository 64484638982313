import React from 'react';
import './skatepark.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './home/home';
import Register from './register/register';
import Checkin from './checkin/checkin';
import Purchase from './purchase/purchase';
import Inventory from './inventory/inventory';
import Edit from './inventory/edit/edit';
import Rentals from './rentals/rentals';
import Notes from './notes/notes';
import Loading from '../loading/loading';
import NotFound from '../404/404';
import RFIDRegister from '../skatepark/rfid-register/rfid-register';
import RFIDScan from '../skatepark/rfid-register/rfid-scan';
import Login from '../../components/login/login.jsx';
import {
  populateSkateparkFeed,
  populateSkateparkNews,
  updateSkateparkFeedItems,
  populateSkateparkUsers,
  createSkateparkUser,
  updateSkateparkUser,
  deleteSkateparkUser,
  populateSkateparkInventory,
  createSkateparkInventory,
  updateSkateparkInventory,
  deleteSkateparkInventory,
  clearSkateparkPage,
  initializeSkatepark,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  decrementNewsUnreadTally,
  updateSkateparkAttendance,
  updateSkateparkNewsItems,
  populateSkateparkUserNotes,
  createSkateparkUserNote,
  updateSkateparkUserNote,
  deleteSkateparkUserNote
} from '../../actions/skateparkPageActions/skateparkActions';
import {
  updateSkateparkCheckinModalName,
  updateSkateparkCheckinModalId,
  updateSkateparkCheckinModalVisibility,
  clearSkateparkCheckinModal
} from '../../actions/skateparkPageActions/skateparkPopupActions';
import { attendancelog } from '../../apis/backend';
import notificationAudio from '../../audio/notification.mp3';

class Skatepark extends React.Component {
  async componentDidMount() {
    try {
      await this.props.io.get('/activitylog/recent', (resData) => {
        this.props.populateSkateparkFeed(resData);
      });
      await this.props.io.get('/newslog/recent', (resData) => {
        this.props.populateSkateparkNews(resData);
      });
      await this.props.io.get('/users', (resData) => {
        this.props.populateSkateparkUsers(resData);
      });
      await this.props.io.get('/inventory/report', (resData) => {
        this.props.populateSkateparkInventory(resData);
      });
      await this.props.io.get('/users/checkedIn', (resData) => {
        this.props.updateSkateparkAttendance(resData);
      });

      this.props.io.on(`attendanceupdate`, (event) => {
        this.props.updateSkateparkAttendance([event, ...this.props.attendance]);
      });

      this.props.io.on('usercreate', (event) => {
        this.props.createSkateparkUser(event);
      });
      this.props.io.on('userupdate', (event) => {
        this.props.updateSkateparkUser(event);
      });
      this.props.io.on('userdelete', (event) => {
        this.props.deleteSkateparkUser(event);
      });
      this.props.io.on('inventorycreate', (event) => {
        this.props.createSkateparkInventory(event);
      });
      this.props.io.on('inventoryupdate', (event) => {
        this.props.updateSkateparkInventory(event);
      });
      this.props.io.on('inventorydelete', (event) => {
        this.props.deleteSkateparkInventory(event);
      });
      this.props.io.on('activitylogcreate', (event) => {
        this.props.updateSkateparkFeedItems(event);
        if (this.props.activeTab === 1) {
          this.props.incrementActivityUnreadTally();
          // this.playSound(notificationAudio, 0.5);
        }
      });
      this.props.io.on('checkinafterpurchase', (event) => {
        if (event.clearModal) {
          this.props.clearSkateparkCheckinModal();
        } else if (event.name && event.userId) {
          this.props.updateSkateparkCheckinModalName(event.name);
          this.props.updateSkateparkCheckinModalId(event.userId);
          setTimeout(() => {
            this.props.updateSkateparkCheckinModalVisibility(true);
          }, 1000);
        }
      });
      this.props.io.on('newslogcreate', (event) => {
        this.props.updateSkateparkNewsItems(event);
        this.props.incrementNewsUnreadTally();
        // this.playSound(notificationAudio, 0.5);
      });
      this.props.io.on('newslogupdate', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateSkateparkNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogdelete', async (event) => {
        await this.props.io.get('/newslog/recent', (resData) => {
          const prevNews = this.props.newsItems;
          this.props.populateSkateparkNews(resData);
          if (prevNews !== this.props.newsItems) {
            // this.playSound(notificationAudio, 0.5);
          }
        });
      });
      this.props.io.on('newslogread', async (event) => {
        this.props.decrementNewsUnreadTally();
        await this.props.io.get('/newslog/recent', (resData) => {
          this.props.populateSkateparkNews(resData);
        });
      });

      await this.props.io.get('/usernotes', (resData) => {
        this.props.populateSkateparkUserNotes(resData);
      });
      this.props.io.on('usernotescreate', (event) => {
        this.props.createSkateparkUserNote(event);
      });
      this.props.io.on('usernotesupdate', (event) => {
        this.props.updateSkateparkUserNote(event);
      });
      this.props.io.on('usernotesdelete', (event) => {
        this.props.deleteSkateparkUserNote(event);
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.loaded &&
      this.props.usersLoaded &&
      this.props.inventoryLoaded &&
      this.props.feedItemsLoaded &&
      this.props.newsItemsLoaded &&
      this.props.userNotesLoaded
    ) {
      this.props.initializeSkatepark();
    }
  }

  componentWillUnmount() {
    try {
      this.props.io.off('activitylogcreate');
      this.props.io.off('checkinafterpurchase');
      this.props.io.off('newslogcreate');
      this.props.io.off('newslogupdate');
      this.props.io.off('newslogdelete');
      this.props.io.off('newslogread');
      this.props.io.off('inventorydelete');
      this.props.io.off('inventoryupdate');
      this.props.io.off('inventorycreate');
      this.props.io.off('userdelete');
      this.props.io.off('userupdate');
      this.props.io.off('usercreate');
      this.props.io.off('usernotescreate');
      this.props.io.off('usernotesupdate');
      this.props.io.off('usernotesdelete');
      // this.props.clearSkateparkPage();
    } catch (err) {
      console.log(err);
    }
  }

  async attendanceRequest(id) {
    await attendancelog
      .post(
        '/user',
        {
          id,
          location: this.props.locationId
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((err) => console.log(err));
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  render() {
    if (!this.props.loaded) {
      return <Loading />;
    }
    return (
      <Router>
        <Switch>
          <Route path="/skatepark/register" component={Register} />
          <Route
            path="/skatepark/checkin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Check-In',
                  clickEvent: (e) => this.attendanceRequest(e),
                  redirect: '/skatepark'
                }}
              ></Checkin>
            )}
          />
          <Route
            path="/skatepark/purchasecheckin"
            render={(props) => (
              <Checkin
                {...props}
                button={{
                  title: 'Continue',
                  redirectId: '/skatepark/purchase'
                }}
              ></Checkin>
            )}
          />
          <Route path="/skatepark/purchase/:id" component={Purchase} />
          <Route
            path="/skatepark/rentalscheckin"
            render={(props) => (
              <Checkin
                {...props}
                userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/skatepark/rentals'
                }}
              ></Checkin>
            )}
          />
          <Route
            path="/skatepark/notescheckin"
            render={(props) => (
              <Checkin
                {...props}
                // userfilter={this.props.attendance}
                button={{
                  title: 'Continue',
                  redirectId: '/skatepark/notes'
                }}
              ></Checkin>
            )}
          />
          <Route path="/skatepark/rentals/:id" component={Rentals} />
          <Route path="/skatepark/inventory/edit/:id" component={Edit} />
          <Route path="/skatepark/notes/:userId" component={Notes} />
          <Route path="/skatepark/inventory" component={Inventory} />
          <Route exact path="/skatepark/rfid" component={RFIDRegister} />
          <Route exact path="/skatepark/rfid/scan/:id" component={RFIDScan} />
          <Route exact path="/skatepark" component={HomePage} />
          <Route exact path="/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io,
  connected: state.application.connected,
  loaded: state.skatepark.skatepark.loaded,
  feedItemsLoaded: state.skatepark.skatepark.feedItemsLoaded,
  newsItemsLoaded: state.skatepark.skatepark.newsItemsLoaded,
  usersLoaded: state.skatepark.skatepark.usersLoaded,
  inventoryLoaded: state.skatepark.skatepark.inventoryLoaded,
  userNotesLoaded: state.skatepark.skatepark.userNotesLoaded,
  activeTab: state.skatepark.skatepark.activeTab,
  locationId: state.auth.sysLocationId,
  attendance: state.skatepark.skatepark.attendance,
  employeeToken: state.auth.employeeToken,
  newsItems: state.skatepark.skatepark.newsItems
});

export default connect(mapStateToProps, {
  populateSkateparkFeed,
  populateSkateparkNews,
  updateSkateparkFeedItems,
  populateSkateparkUsers,
  createSkateparkUser,
  updateSkateparkUser,
  deleteSkateparkUser,
  populateSkateparkInventory,
  createSkateparkInventory,
  updateSkateparkInventory,
  deleteSkateparkInventory,
  clearSkateparkPage,
  initializeSkatepark,
  incrementNewsUnreadTally,
  incrementActivityUnreadTally,
  decrementNewsUnreadTally,
  updateSkateparkAttendance,
  updateSkateparkNewsItems,
  updateSkateparkCheckinModalName,
  updateSkateparkCheckinModalId,
  updateSkateparkCheckinModalVisibility,
  clearSkateparkCheckinModal,
  populateSkateparkUserNotes,
  createSkateparkUserNote,
  updateSkateparkUserNote,
  deleteSkateparkUserNote
})(Skatepark);
