export const updateActivityDetailFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_ACTIVITY_DETAILS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedActivityDetail = (ActivityDetail) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_ACTIVITY_DETAILS_PAGE_SELECTED_ACTIVITY_DETAIL',
    payload: ActivityDetail
  });
};
