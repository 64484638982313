const INITIAL_STATE = {
  rfidScans: [],
  selected: -1
};

export const eventsRegisterRFIDReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_EVENTS_REGISTER_RFID_PAGE': {
      return INITIAL_STATE;
    }
    case 'EVENTS_UPDATE_REGISTER_RFID_SCANS':
      return {
        ...state,
        rfidScans: action.payload
      };
    case 'UPDATE_EVENTS_REGISTER_RFID_SELECTED':
      return {
        ...state,
        selected: action.payload
      };
    default:
      return state;
  }
};
