import React from 'react';
import './skatePunches.css';
import { connect } from 'react-redux';
import {
  updateSkatePunchFilter,
  updateSelectedSkatePunch
} from '../../../actions/adminActions/skatePunchActions';
import { skatePunches } from '../../../apis/backend'; //make sure to include this for each page
import EditModal from './skatePunchModals/editModal.jsx';
import AddModal from './skatePunchModals/addModal.jsx';

class SkatePunches extends React.Component {
  async skatePunchDeleteRequest(id) {
    await skatePunches
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  renderskatePunches() {
    return (
      <>
        {this.props.skatePunches
          .filter((skatePunch) => {
            const filterString = `${skatePunch.name} ${skatePunch.punches} ${
              '$' + skatePunch.price
            }`;
            return (
              filterString.toLowerCase().indexOf(this.props.skatePunchFilter.toLowerCase()) > -1
            );
          })
          .map((skatePunch) => (
            <tr key={this.props.skatePunches.indexOf(skatePunch)}>
              <td className="text-center" style={{ width: '20%' }}>
                {skatePunch.name}
              </td>
              <td className="text-center" style={{ width: '30%' }}>
                {skatePunch.punches}
              </td>
              <td className="text-center " style={{ width: '20%' }}>
                {'$' + skatePunch.price.toFixed(2)}
              </td>
              <td className="text-center " style={{ width: '9%' }}>
                <div
                  data-target="#exampleModal"
                  className="btn btn-sm btn-dark"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedSkatePunch(skatePunch.id);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className="text-center " style={{ width: '9%' }}>
                <div
                  className="btn btn-sm btn-dark"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => {
                    this.props.updateSelectedSkatePunch(skatePunch.id);
                  }}
                >
                  Remove
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  getSkatePunchName() {
    const skatePunch = this.props.skatePunches.find(
      (obj) => obj.id === this.props.selectedSkatePunch
    );

    return skatePunch ? skatePunch.name : '';
  }

  render() {
    return (
      <div className="skatePunches-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Skate Punches</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.skatePunchFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateSkatePunchFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add Skate Punch
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '20%' }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: '30%' }}>
                    Punches
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Price
                  </th>
                  <th className="text-center " style={{ width: '9%' }}>
                    Edit
                  </th>
                  <th className="text-center " style={{ width: '9%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderskatePunches()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedSkatePunch(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getSkatePunchName()}&apos;</strong> will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.skatePunchDeleteRequest(this.props.selectedSkatePunch);
                    this.props.updateSelectedSkatePunch(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedSkatePunch(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  skatePunches: state.admin.admin.skatePunches,
  skatePunchFilter: state.admin.skatePunches.skatePunchFilter,
  selectedSkatePunch: state.admin.skatePunches.selectedSkatePunch,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateSkatePunchFilter,
  updateSelectedSkatePunch
})(SkatePunches);
