import React from 'react';
import './home.css';
import { connect } from 'react-redux';

class Home extends React.Component {
  getLocation(locationId) {
    const location = this.props.systemLocations[locationId - 1].location;
    return `${location.charAt(0).toUpperCase()}${location.slice(1)}`;
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return `${date.toDateString()}, ${date.toLocaleTimeString(undefined, options)}`;
  }

  getUser(userID) {
    const user = this.props.users.find((objcectt) => objcectt.id === userID);
    return `${user.firstName} ${user.lastName}`;
  }

  renderAttendance = (item) => {
    //renders the users table
    return (
      <>
        {this.props.attendanceLogRecent.map((item) => (
          <tr key={this.props.attendanceLogRecent.indexOf(item)}>
            <td className="text-center" style={{ width: '30%' }}>
              {this.getUser(item.user)}
            </td>
            <td className="text-center" style={{ width: '30%' }}>
              {this.getLocation(item.location)}
            </td>
            <td className="text-center " style={{ width: '20%' }}>
              {this.formatDate(item.checkedInAt)}
            </td>
          </tr>
        ))}
      </>
    );
  };

  render() {
    return (
      <div className="admin-home-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-5"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>
              Today&apos;s Attendance
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center" style={{ width: '20%' }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Location
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderAttendance()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attendanceLogRecent: state.admin.admin.attendanceLogRecent,
  users: state.admin.admin.users,
  systemLocations: state.admin.admin.systemLocations
});

export default connect(mapStateToProps, {})(Home);
