import React from 'react';
import './memberships.css';
import {
  updateMembershipFilter,
  updateSelectedMembership
} from '../../../actions/adminActions/membershipsActions';
import { connect } from 'react-redux';
import { memberships } from '../../../apis/backend';
import EditModal from './membershipModals/EditModal.jsx';
import AddModal from './membershipModals/AddModal.jsx';
import DiscountModal from './membershipModals/DiscountModal';
import UpdateModal from './membershipModals/UpdateDiscount';

class Memberships extends React.Component {
  async deleteRequest() {
    await memberships
      .delete(`/${this.props.selectedMembership}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .catch((response) => {
        console.log(response);
      });
  }
  renderMemberships() {
    //renders the memberships table
    return (
      <>
        {this.props.memberships
          .filter((membership) => {
            const filterString = `${membership.accessName} ${membership.price} ${
              Math.ceil(membership.duration / (24 * 60 * 60 * 1000)) + ' day(s)'
            }`;
            return (
              filterString.toLowerCase().indexOf(this.props.membershipFilter.toLowerCase()) > -1
            );
          })
          .map((membership) => {
            const allAccess = this.props.memberships.find(
              (obj) => obj.accessName === 'All Access Pass'
            );
            const styles =
              allAccess.accessName === membership.accessName ? { display: 'none' } : {};
            return (
              <tr key={this.props.memberships.indexOf(membership)}>
                <td className="text-center" style={{ width: '30%' }}>
                  {`${membership.accessName}`}
                </td>
                <td className="text-center " style={{ width: '20%' }}>
                  {'$' + membership.price.toFixed(2)}
                </td>
                <td className="text-center" style={{ width: '20%' }}>
                  {Math.ceil(membership.duration / (24 * 60 * 60 * 1000)) + ' day(s)'}
                </td>
                <td className="text-center " style={{ width: '10%' }}>
                  <div
                    data-target="#exampleModal"
                    className="btn btn-sm btn-dark"
                    data-toggle="modal"
                    onClick={() => {
                      this.props.updateSelectedMembership(membership.id);
                    }}
                  >
                    Edit
                  </div>
                </td>

                <td className="text-center " style={{ width: '10%' }}>
                  <div
                    data-target="#discountModal"
                    className="btn btn-sm btn-dark"
                    data-toggle="modal"
                    onClick={() => {
                      this.props.updateSelectedMembership(membership.id);
                    }}
                  >
                    Discounts
                  </div>
                </td>

                <td className="text-center " style={{ width: '10%' }}>
                  <div
                    className="btn btn-sm btn-dark"
                    style={styles}
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => {
                      this.props.updateSelectedMembership(membership.id);
                    }}
                  >
                    Remove
                  </div>
                </td>
              </tr>
            );
          })}
      </>
    );
  }

  getMembershipName() {
    const membership = this.props.memberships.find(
      (obj) => obj.id === this.props.selectedMembership
    );

    return membership ? membership.accessName : '';
  }

  render() {
    return (
      <div className="memberships-page">
        <div className="container">
          <div
            className="form-inline md-form form-sm mt-4"
            style={{ fontSize: '36px', color: '#fff' }}
          >
            <div style={{ margin: 'auto', height: '48px', width: '100%' }}>Memberships</div>
          </div>
          <div className="form-inline md-form form-sm mt-3">
            <input
              value={this.props.membershipFilter}
              className="form-control form-control-lg w-45"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                this.props.updateMembershipFilter(e.target.value);
              }}
            />
            <div
              data-target="#addModal"
              className="btn btn-lg btn-dark"
              data-toggle="modal"
              style={{ marginLeft: '10px' }}
            >
              Add Membership
            </div>
          </div>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-striped  table-bordered table-hover bg-white mt-1 mr-3">
              <thead className="bg-dark thead-dark">
                <tr>
                  <th className="text-center " style={{ width: '30%' }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Price
                  </th>
                  <th className="text-center" style={{ width: '20%' }}>
                    Duration
                  </th>
                  <th className="text-center" style={{ width: '10%' }}>
                    Edit
                  </th>
                  <th className="text-center" style={{ width: '10%' }}>
                    Discount
                  </th>
                  <th className="text-center" style={{ width: '10%' }}>
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderMemberships()}</tbody>
            </table>
          </div>
        </div>

        <AddModal />

        <EditModal />

        <DiscountModal />

        <UpdateModal />

        <div className="modal fade" id="deleteModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: '80%', margin: '0 auto' }}>
              <div className="modal-header">
                <h5 className="modal-title">Are you Sure?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.updateSelectedMembership(0);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: 'center', fontSize: '18px' }}>
                <strong>&apos;{this.getMembershipName()}&apos;</strong> will be deleted forever!!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.deleteRequest();
                    this.props.updateSelectedMembership(0);
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.updateSelectedMembership(0);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  memberships: state.admin.admin.memberships,
  selectedMembership: state.admin.memberships.selectedMembership,
  membershipFilter: state.admin.memberships.membershipFilter,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateMembershipFilter,
  updateSelectedMembership
})(Memberships);
