import React from 'react';
import { connect } from 'react-redux';
import './modal.css';

class ExtraInventoryItemInfo extends React.Component {
  render() {
    return (
      <>
        <div className={this.props.visible ? 'popup-container' : 'popup-container hide-content'}>
          <div id="content-spacer">
            <div className="popup-content">
              <div className="modal-text">Additional Information</div>
              <hr style={{ marginBottom: '0' }} />
              <table style={{ margin: '0 auto', width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', textAlign: 'right' }}>Make:</td>
                    <td style={{ width: '50%', textAlign: 'left' }}>{this.props.make}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%', textAlign: 'right' }}>Model:</td>
                    <td style={{ width: '50%', textAlign: 'left' }}>{this.props.itemModel}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%', textAlign: 'right' }}>Serial #:</td>
                    <td style={{ width: '50%', textAlign: 'left' }}>{this.props.serialNumber}</td>
                  </tr>
                </tbody>
              </table>
              <div className="popup-buttons">
                <button
                  className="popup-button"
                  onClick={() => {
                    this.props.clearModal();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationId: state.auth.sysLocationId,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(ExtraInventoryItemInfo);
