export const login = (auth) => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_LOG_IN', payload: auth });
};

export const logout = () => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_LOG_OUT' });
};

export const unlock = (auth) => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_UNLOCK', payload: auth });
};

export const lock = () => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_LOCK' });
};

export const updateSysUser = (id, location, locationId, expires) => (dispatch) => {
  dispatch({
    type: 'AUTHENTICATION_UPDATE_SYSTEM_USER',
    payload: { id, location, locationId, expires }
  });
};

export const updateEmpUser = (user) => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_UPDATE_EMPLOYEE_USER', payload: user });
};

export const updateAuth = (auth) => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_LOAD_FROM_LOCALSTORAGE', payload: auth });
};

export const updateUuid = (uuid) => (dispatch) => {
  dispatch({ type: 'AUTHENTICATION_UPDATE_UUID', payload: uuid });
};
