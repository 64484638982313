import React, { Component } from 'react';
import './../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedUser } from '../../../../actions/adminActions/usersActions';
import { rentals } from '../../../../apis/backend.js';

class RentalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rentals: []
    };
  }

  clearState() {
    this.setState({
      rentals: []
    });
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (prevProps.selectedUser !== this.props.selectedUser && this.props.selectedUser !== 0) {
      this.getRentalByUserId();
    }
    if (prevProps.selectedUser !== this.props.selectedUser && this.props.selectedUser === 0) {
      this.setState({ rentals: [] });
    }
  }

  formatDate(eventDate) {
    if (eventDate) {
      const date = new Date(eventDate);
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      if (date.toDateString() !== 'Invalid Date') {
        return `${date.toDateString()}, ${date.toLocaleTimeString(undefined, options)}`;
      } else {
        return 'Not Returned';
      }
    } else {
      return 'Not Returned';
    }
  }

  getItemNumber(inventoryID) {
    const itemNumber = this.props.inventory.find(
      (objcectt) => objcectt.id === inventoryID
    ).itemNumber;
    return itemNumber;
  }

  getItemType(inventoryID) {
    const itemID = this.props.inventory.find((objcectt) => objcectt.id === inventoryID).type;
    return itemID;
  }

  renderRentals() {
    return (
      <>
        {this.state.rentals
          .sort((a, b) => {
            if (a.checkInTime && !b.checkInTime) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((rental) => {
            return (
              <tr key={rental.id}>
                <td className="text-center">{this.getItemNumber(rental.inventory)}</td>
                <td className="text-center">{this.getItemType(rental.inventory)}</td>
                <td className="text-center">{this.formatDate(rental.checkOutTime)}</td>
                <td className="text-center">{this.formatDate(rental.checkInTime)}</td>
              </tr>
            );
          })}
      </>
    );
  }

  async getRentalByUserId() {
    await rentals
      .get(`/findbyuser/${this.props.selectedUser}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .then((res) => {
        this.setState({ rentals: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div id="rentalModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxwidth: '800px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Rental History</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedUser(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table
                className="table table-striped table-bordered table-hover bg-white mr-3"
                id="tab_logic"
              >
                <thead className=" bg-dark thead-dark">
                  <tr>
                    <th>Number</th>
                    <th>Type</th>
                    <th>Check Out Time</th>
                    <th>Check In Time</th>
                  </tr>
                </thead>
                <tbody>{this.renderRentals()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedUser: state.admin.users.selectedUser,
  inventory: state.admin.admin.inventory,
  inventoryTypes: state.admin.admin.inventoryTypes,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedUser })(RentalModal);
