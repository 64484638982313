export const updateUserFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_USERS_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedUser = (user) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_USERS_PAGE_SELECTED_USER', payload: user });
};

export const updateSelectedNote = (note) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_USERS_NOTE_SELECTED_USER', payload: note });
};

export const updateSelectedFlag = (flag) => (dispatch) => {
  dispatch({ type: 'UPDATE_ADMIN_NOTE_FLAGS_SELECTED_FLAG', payload: flag });
};
