const INITIAL_STATE = {
  userName: '',
  checkinUserId: 0,
  checkinVisible: false,
  userNote: '',
  noteUserId: 0,
  noteVisible: false
};

export const skateparkPopupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_SKATEPARK_CHECKIN_MODAL_NAME':
      return {
        ...state,
        userName: action.payload
      };
    case 'UPDATE_SKATEPARK_CHECKIN_MODAL_USER_ID':
      return {
        ...state,
        checkinUserId: action.payload
      };
    case 'UPDATE_SKATEPARK_CHECKIN_MODAL_ACTIVE':
      return {
        ...state,
        checkinVisible: action.payload
      };
    case 'CLEAR_SKATEPARK_CHECKIN_MODAL':
      return {
        ...state,
        userName: '',
        checkinUserId: 0,
        checkinVisible: false
      };
    case 'UPDATE_SKATEPARK_NOTE_MODAL_NOTE':
      return {
        ...state,
        userNote: action.payload
      };
    case 'UPDATE_SKATEPARK_NOTE_MODAL_USER_ID':
      return {
        ...state,
        noteUserId: action.payload
      };
    case 'UPDATE_SKATEPARK_NOTE_MODAL_ACTIVE':
      return {
        ...state,
        noteVisible: action.payload
      };
    case 'CLEAR_SKATEPARK_NOTE_MODAL':
      return {
        ...state,
        userNote: '',
        noteUserId: 0,
        noteVisible: false
      };
    default:
      return state;
  }
};
