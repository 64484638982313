const INITIAL_STATE = {
  name: '',
  searchFilter: '',
  assignmentEndDate: null
};

export const scannerRegisterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_ACTIVITIES_REGISTER_SCANNER_PAGE': {
      return INITIAL_STATE;
    }
    case 'UPDATE_ACTIVITIES_REGISTER_SCANNER_SELECTED':
      return {
        ...state,
        name: action.payload
      };
    case 'UPDATE_ACTIVITIES_REGISTER_SCANNER_END_DATE':
      return {
        ...state,
        assignmentEndDate: action.payload
      };
    case 'UPDATE_ACTIVITIES_REGISTER_SCANNER_FILTER':
      return {
        ...state,
        searchFilter: action.payload
      };
    default:
      return state;
  }
};
