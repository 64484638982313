export const updateEventsCheckinModalName = (name) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EVENTS_CHECKIN_MODAL_NAME',
    payload: name
  });
};

export const updateEventsCheckinModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_EVENTS_CHECKIN_MODAL_USER_ID',
    payload: getState().events.checkin.selected === id ? 0 : id
  });
};

export const updateEventsCheckinModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EVENTS_CHECKIN_MODAL_ACTIVE',
    payload: value
  });
};

export const clearEventsCheckinModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_EVENTS_CHECKIN_MODAL'
  });
};

export const updateEventsNoteModalNote = (note) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EVENTS_NOTE_MODAL_NOTE',
    payload: note
  });
};

export const updateEventsNoteModalId = (id) => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_EVENTS_NOTE_MODAL_USER_ID',
    payload: getState().events.checkin.selected === id ? 0 : id
  });
};

export const updateEventsNoteModalVisibility = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EVENTS_NOTE_MODAL_ACTIVE',
    payload: value
  });
};

export const clearEventsNoteModal = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_EVENTS_NOTE_MODAL'
  });
};
