import React from 'react';
import './home.css';
import FeedItem from '../../utils/feeditem/feedItem.jsx';
import { connect } from 'react-redux';
import Logo from '../../../images/coffee.png';
import SingleFeedView from '../../utils/singleFeedView/singleFeedView';
import Page from '../../utils/page/page';
import { lock } from '../../../actions/authActions';
import { updateShowButtons } from '../../../actions/coffeePageActions/homeActions.js';

class HomePage extends React.Component {
  render() {
    let feedItems;

    // Dynamically displays feed items
    if (this.props.feedItems.length === 0) {
      feedItems = <h1>No items to display</h1>;
    } else {
      feedItems = this.props.feedItems.map((log) => (
        <FeedItem
          title={log.user}
          status={log.status}
          messages={log.messages}
          userId={log.userId}
          id={log.id}
          key={log.id}
          history={this.props.history}
          type="coffee"
        />
      ));
    }

    return (
      <Page>
        <div style={{ height: '18%', textAlign: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              height: '100%',
              verticalAlign: 'middle'
            }}
          ></span>
          <img style={{ height: '85%', verticalAlign: 'middle' }} src={Logo} alt="The Bay Logo" />
        </div>
        <div style={{ height: '70%' }}>
          <SingleFeedView activity={feedItems}></SingleFeedView>
        </div>
        <div className="buttonContainer">
          <button className="button" onClick={() => this.props.history.push('/coffee/checkin')}>
            Manual Check-in
          </button>
          <button className="button" onClick={() => this.props.lock()}>
            Lock
          </button>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  feedItems: state.coffee.coffee.feedItems
});

export default connect(mapStateToProps, {
  lock,
  updateShowButtons
})(HomePage);
