const INITIAL_STATE = {
  eventFilter: '',
  selectedEvent: 0
};

export const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_EVENTS_PAGE_FILTER_STRING':
      return {
        ...state,
        eventFilter: action.payload
      };
    case 'UPDATE_ADMIN_EVENTS_PAGE_SELECTED_EVENT':
      return {
        ...state,
        selectedEvent: action.payload
      };
    default:
      return state;
  }
};
