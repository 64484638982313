export const updateAllowanceFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_MEAL_SWIPES_ALLOWANCE_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedAllowance = (mealSwipesAllowance) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_MEAL_SWIPES_ALLOWANCE_PAGE_SELECTED_ACCESS',
    payload: mealSwipesAllowance
  });
};
