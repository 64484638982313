export const updatePurchaseLogFilter = (filterString) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PURCHASE_LOG_PAGE_FILTER_STRING',
    payload: filterString
  });
};

export const updateSelectedPurchaseLog = (log) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ADMIN_PURCHASE_LOG_PAGE_SELECTED_PURCHASE_LOG',
    payload: log
  });
};
