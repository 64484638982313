const INITIAL_STATE = {
  rentalOptions: [],
  cart: [],
  filter: '',
  loaded: false
};

export const activitiesRentalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'INITIALIZE_ACTIVITIES_RENTALS_PAGE': {
      return {
        ...state,
        rentalOptions: action.payload,
        loaded: true
      };
    }
    case 'ACTIVITIES_RENTALS_PAGE_UPDATE_CART':
      return {
        ...state,
        cart: action.payload
      };
    case 'ACTIVITIES_RENTALS_PAGE_UPDATE_FILTER_STRING':
      return {
        ...state,
        filter: action.payload
      };
    case 'CLEAR_ACTIVITIES_RENTALS_PAGE':
      return INITIAL_STATE;
    default:
      return state;
  }
};
