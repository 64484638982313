const INITIAL_STATE = {
  feedItems: [],
  feedItemsLoaded: false,
  newsItems: [],
  newsItemsLoaded: false,
  users: [],
  usersLoaded: false,
  inventory: [],
  inventoryLoaded: false,
  loaded: false,
  activeTab: 0,
  newsUnreadTally: 0,
  activityUnreadTally: 0,
  attendance: [],
  userNotes: [],
  userNotesLoaded: false
};

export const skateparkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'POPULATE_SKATEPARK_FEED_ITEMS':
      return {
        ...state,
        feedItems: action.payload,
        feedItemsLoaded: true
      };
    case 'POPULATE_SKATEPARK_NEWS_ITEMS':
      return {
        ...state,
        newsItems: action.payload,
        newsItemsLoaded: true,
        newsUnreadTally: action.payload.filter((item) => item.read === false).length
      };
    case 'POPULATE_SKATEPARK_USERS':
      return {
        ...state,
        users: action.payload,
        usersLoaded: true
      };
    case 'POPULATE_SKATEPARK_INVENTORY_ITEMS':
      return {
        ...state,
        inventory: action.payload,
        inventoryLoaded: true
      };
    case 'CLEAR_SKATEPARK_PAGE_DATA':
      return INITIAL_STATE;
    case 'INITIALIZE_SKATEPARK_PAGE':
      return {
        ...state,
        loaded: true
      };
    case 'CHANGE_SKATEPARK_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload
      };
    case 'INCREMENT_SKATEPARK_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload
      };
    case 'DECREMENT_SKATEPARK_NEWS_UNREAD_TALLY':
      return {
        ...state,
        newsUnreadTally: action.payload
      };
    case 'INCREMENT_SKATEPARK_ACTIVITY_UNREAD_TALLY':
      return {
        ...state,
        activityUnreadTally: action.payload
      };
    case 'CLEAR_SKATEPARK_UNREAD_TALLY':
      return {
        ...state,
        // newsUnreadTally: 0,
        activityUnreadTally: 0
      };
    case 'UPDATE_SKATEPARK_PAGE_USERS_ATTENDANCE':
      return {
        ...state,
        attendance: action.payload
      };
    case 'POPULATE_SKATEPARK_USER_NOTES':
      return {
        ...state,
        userNotes: action.payload,
        userNotesLoaded: true
      };
    default:
      return state;
  }
};
