import { types } from '../actionTypes';
export const initializeAdminPage = () => (dispatch) => {
  dispatch({ type: types.adminPageInitialization });
};

export const clearAdminPage = () => (dispatch) => {
  dispatch({ type: types.clearAdminPage });
};

export const populateAdminObjects = (objs, type) => (dispatch) => {
  if (type === types.populateAdminUsers) {
    objs.map((item) => {
      item.bulkActionToggle = false;
    });
  }
  dispatch({ type, payload: objs });
};

export const createAdminObject = (obj, type, entry) => async (dispatch, getState) => {
  if (type === types.populateAdminUsers) {
    obj.bulkActionToggle = false;
  }
  dispatch({
    type,
    payload: [obj, ...getState().admin.admin[entry]]
  });
};

export const updateAdminObject = (obj, type, entry) => async (dispatch, getState) => {
  const objectArray = [...getState().admin.admin[entry]];
  obj.forEach((object) => {
    const oldObject = objectArray.find((objectt) => objectt.id === object.id);
    objectArray[objectArray.indexOf(oldObject)] = object;
  });
  dispatch({
    type,
    payload: objectArray
  });
};

export const deleteAdminObject = (obj, type, entry) => async (dispatch, getState) => {
  const objectArray = [...getState().admin.admin[entry]];
  obj.forEach((object) => {
    const oldObject = objectArray.find((objcectt) => objcectt.id === object.id);
    objectArray.splice(objectArray.indexOf(oldObject), 1);
  });
  dispatch({
    type,
    payload: objectArray
  });
};
