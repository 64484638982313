import React, { Component } from 'react';
import './../../Modal.css';
import { inventoryType } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedInventoryType } from '../../../../actions/adminActions/adminInventoryTypeActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      price: '',
      location: ''
    };
  }

  clearState() {
    this.setState({
      name: '',
      price: '',
      location: ''
    });
  }

  async inventoryTypeRequest() {
    await inventoryType
      .patch(
        `/${this.props.selectedInventoryType}`,
        {
          name: this.state.name,
          price: isNaN(this.state.price) ? 0 : this.state.price,
          location: this.state.location
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .then(this.clearState())
      .catch((response) => {
        console.log(response);
        alert('Sorry, an error occurred. Please check your entries.');
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedInventoryType !== this.props.selectedInventoryType &&
      this.props.selectedInventoryType !== 0
    ) {
      const inventoryType = this.props.inventoryTypes.find(
        (obj) => obj.id === this.props.selectedInventoryType
      );
      if (inventoryType) {
        this.setState({
          name: inventoryType.name,
          price: inventoryType.price,
          location: inventoryType.location
        });
      }
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Inventory Type</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedInventoryType(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.name}
                  placeholder="Inventory Type Name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Price
                  </span>
                </div>
                <input
                  type="number"
                  step={0.01}
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="0"
                  value={this.state.price}
                  placeholder="0.00"
                  onChange={(e) =>
                    this.setState({
                      price: e.target.value
                    })
                  }
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Location
                  </span>
                </div>
                <select
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.location}
                  onChange={(e) => {
                    this.setState({ location: e.target.value });
                  }}
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {this.props.systemLocations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.location}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.inventoryTypeRequest();
                  this.props.updateSelectedInventoryType(0);
                }}
                disabled={
                  !(
                    this.state.name !== '' &&
                    this.state.price !== '' &&
                    this.state.location !== '' &&
                    this.state.price >= 0
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inventoryTypes: state.admin.admin.inventoryTypes,
  systemLocations: state.admin.admin.systemLocations,
  selectedInventoryType: state.admin.inventoryTypes.selectedInventoryType,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedInventoryType })(EditModal);
